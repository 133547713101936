import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import google_logo from '../assets/google.svg'
import * as quark from '../quark'
import Logo from './Logo'
import { globalUpdate } from '../Aphelion'
import firebase from 'firebase/app'
import 'firebase/auth'

export class ConfirmEmail extends React.Component {
    state = {
        error: null,
        loading: false,
    }

    render() {
        // see https://firebase.google.com/docs/auth/web/email-link-auth
        return (
            <div className="email-login">
                <h1>
                    <Logo />
                </h1>
                <p>Just one more step until you can access Hypernote.</p>
                <p>
                    For security reasons we need you to enter the email address that recieved the
                    login link email.
                </p>

                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        this.setState({ loading: true })
                        let email = (e.target as any).email.value

                        firebase
                            .auth()
                            .signInWithEmailLink(email, quark.store.confirmEmailAddress)
                            .then(() => {
                                // console.log('signed in i guess')
                                delete localStorage.email
                                location.search = ''
                            })
                            .catch((error) => {
                                // this.setState({ loading: false })
                                // Some error occurred, you can inspect the code: error.code
                                // Common errors could be invalid email and invalid or expired OTPs.
                                console.log('error', error)
                                this.setState({ error: error, loading: false })
                            })
                    }}
                >
                    <input
                        type="email"
                        disabled={this.state.loading}
                        name="email"
                        defaultValue={localStorage.email}
                        placeholder="Enter your email address..."
                    />

                    <button type="submit">Continue with Email</button>

                    {this.state.error && (
                        <p style={{ color: 'red' }}>{this.state.error.toString()}</p>
                    )}

                    <p>
                        <a href="/home">&larr; Cancel login</a>
                    </p>
                </form>
            </div>
        )
    }
}

export class LoginButton extends React.Component {
    state = {
        useEmail: false,
        tokenSent: false,
        email: '',
        error: null,
    }

    render() {
        return (
            <div className="login-widget">
                <div>
                    <div className="google-button" onClick={(e) => quark.store.signIn()}>
                        <img src={google_logo} />
                        <div>Continue with Google</div>
                    </div>

                    {!this.state.useEmail ? (
                        <div>
                            or{' '}
                            <a
                                href="javascript:void(0)"
                                onClick={(e) => {
                                    e.preventDefault()
                                    this.setState({ useEmail: true })
                                }}
                            >
                                continue with email
                            </a>
                        </div>
                    ) : (
                        <div className="email-login">
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault()
                                    var actionCodeSettings = {
                                        url: location.href,
                                        handleCodeInApp: true,
                                    }

                                    localStorage.email = this.state.email

                                    firebase
                                        .auth()
                                        .sendSignInLinkToEmail(this.state.email, actionCodeSettings)
                                        .then(function() {
                                            // The link was successfully sent. Inform the user. Save the email
                                            // locally so you don't need to ask the user for it again if they open
                                            // the link on the same device.
                                            console.log('link was sent')
                                        })
                                        .catch(function(error) {
                                            // Some error occurred, you can inspect the code: error.code
                                            console.log(error)

                                            this.setState({ error: error })
                                        })

                                    this.setState({ tokenSent: true })
                                }}
                            >
                                <label htmlFor="email">EMAIL</label>
                                <input
                                    type="email"
                                    id="email"
                                    value={this.state.email}
                                    onChange={(e) => {
                                        this.setState({
                                            email: e.target.value,
                                            tokenSent: false,
                                        })
                                    }}
                                    placeholder="Enter your email address..."
                                />

                                {this.state.error && (
                                    <p style={{ color: 'red' }}>{this.state.error.toString()}</p>
                                )}

                                {!this.state.tokenSent ? (
                                    <button type="submit">Continue with Email</button>
                                ) : (
                                    <div className="link-sent">
                                        ✓ We just sent you a temporary login link. Please check your
                                        inbox.
                                    </div>
                                )}
                            </form>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export function LoginPage() {
    if (quark.store.userInfo) {
        setTimeout(() => {
            quark.set_root_text('')
            globalUpdate()
        }, 10)
    }
    return (
        <div className="node-wrapper">
            <div className="node-self">
                <div className="node">
                    <center>
                        <LoginButton />
                    </center>
                </div>
            </div>
        </div>
    )
}
