import _ from 'lodash'
import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/storage'
import { globalUpdate } from '../../Aphelion'
import { trigger, watch, apply, get } from '../core'
import * as quark from '../index'
import LogRocket from 'logrocket'
import Dexie from 'dexie'

firebase.initializeApp(CONFIG.firebaseConfig)
if (CONFIG.enableLogRocket) LogRocket.init('41crym/hypernote')

if (location.hostname === 'localhost') {
    // firebase.functions().useFunctionsEmulator('http://localhost:5001')
}

class HypernoteDB extends Dexie {
    entities: Dexie.Table<Entity, string> // second arg is type of primary key

    constructor() {
        super('hypernote01')
        this.version(1).stores({
            entities: 'id',
        })
        this.entities = this.table('entities')
        console.time('IndexedDB Initialization')
        this.open()
            .then(() => {
                console.timeEnd('IndexedDB Initialization')
            })
            .catch(function(e) {
                console.error('Open failed: ' + e.stack)
            })
    }
}

const localDB = new HypernoteDB()
const database = firebase.database()
let notebookRef, workspaceRef, aclRef

global.entities = {}

export const storage = firebase.storage().ref()
export const updateAccount = firebase.functions().httpsCallable('updateAccount')
export const createNotebook = firebase.functions().httpsCallable('createNotebook')
export const dataFetcher = firebase.functions().httpsCallable('dataFetcher')
export const connectGoogleCalendar = firebase.functions().httpsCallable('connectGoogleCalendar')

export var authError
export var confirmEmailAddress
export var authStatusAvailable = false
export var userInfo
export var userProfile = null

export var dataLoaded
export var canEdit
export var canUpdateACL

export var path
export var dataLoadingError
export var accessList = null

export function signIn() {
    var provider = new firebase.auth.GoogleAuthProvider()
    firebase.auth().signInWithRedirect(provider)
}

export function signOut() {
    firebase
        .auth()
        .signOut()
        .then(
            function() {
                console.log('Signed Out')
                delete localStorage.redirectToApp
                delete localStorage.cachedUserInfo
                delete localStorage.cachedProfileInfo
                location.href = '/home'
            },
            function(error) {
                console.error('Sign Out Error', error)
            }
        )
}

var saveCallback
export function save(buffer) {
    if (_.isEqual(buffer, {})) return

    // console.log('saving...')
    if (saveCallback) saveCallback(buffer)
}

export function getOwners() {
    let owners = []
    for (let key in accessList) {
        if (key === 'PUBLIC') continue
        if (accessList[key] >= 3) {
            owners.push(key)
        }
    }
    return owners
}

export function updateAccessLevel(email, level) {
    let id = email === 'PUBLIC' ? 'PUBLIC' : email.toLowerCase().replace(/\./g, ',')
    aclRef.child(id).set(level)
}

export function getProfile() {
    return (userProfile && userProfile.profile) || {}
}

export function updateProfile(data) {
    let userDataRef = database.ref('users/' + userInfo.email.toLowerCase().replace(/\./g, ','))

    userDataRef.child('profile').update(data)
}

export function setProfile(data) {
    let userDataRef = database.ref('users/' + userInfo.email.toLowerCase().replace(/\./g, ','))

    userDataRef.child('profile').set(data)
}

function tryParseJSON(str) {
    try {
        return JSON.parse(str)
    } catch (err) {}
}

export function init() {
    let ref = location.pathname.match(/\/([^\/]+)/)
    if (ref) path = ref[1]

    let cachedUserInfo = tryParseJSON(localStorage.cachedUserInfo)
    let cachedUserProfile = tryParseJSON(localStorage.cachedProfileInfo)

    if (cachedUserInfo && cachedUserProfile) {
        userInfo = cachedUserInfo
        userProfile = cachedUserProfile
        authStatusAvailable = true
    }

    firebase
        .auth()
        .getRedirectResult()
        .then(function(result) {
            // if (result.credential) localStorage.accessToken = result.credential.accessToken
            console.log('got redirect good', result)
            authError = null
        })
        .catch(function(error) {
            console.log('got redirect bad', error)
            authError = error
        })

    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
        confirmEmailAddress = window.location.href
        // location.replace('hypernote:' + window.location.href)
        history.replaceState({}, '', location.pathname)
    }

    firebase.auth().onAuthStateChanged(function(user) {
        // console.log('auth state changed')
        authStatusAvailable = true
        userInfo = user
        localStorage.cachedUserInfo = JSON.stringify(user)

        if (user) {
            // let notebookRef = database.ref('notebooks/' + path)
            console.log('got uid', user.uid)

            localStorage.redirectToApp = true

            updateAccount({
                nbid: path,
                ua: navigator.userAgent,
            })
                .then((result) => {
                    console.log('called update account', result)
                })
                .catch((err) => {
                    console.warn('unable to update account', err)
                })

            let userDataRef = database.ref('users/' + user.email.toLowerCase().replace(/\./g, ','))
            userDataRef.on(
                'value',
                (snap) => {
                    userProfile = snap.val()
                    localStorage.cachedProfileInfo = JSON.stringify(userProfile)
                    console.log('got user data', userProfile)
                    globalUpdate()
                },
                (err) => {
                    console.error(err)
                    globalUpdate()
                }
            )

            if (CONFIG.enableLogRocket) {
                LogRocket.identify(user.uid, {
                    name: user.displayName,
                    email: user.email,
                })
            }
        }

        globalUpdate()
    })

    if (!path || path == 'home') {
        console.log('no specific notebook, aborting data fetch')
        return
    }

    notebookRef = database.ref('notebooks/' + path)
    workspaceRef = notebookRef.child('data')
    aclRef = notebookRef.child('acl')

    aclRef.on(
        'value',
        (snap) => {
            accessList = normalizeAccessList(snap.val())

            if (userInfo) {
                canEdit = accessList[userInfo.email.toLowerCase()] >= 2 || accessList['PUBLIC'] >= 2
                canUpdateACL = accessList[userInfo.email.toLowerCase()] >= 3
            } else {
                canEdit = accessList['PUBLIC'] >= 2
            }

            globalUpdate()
        },
        (err) => {
            console.error(err)
            dataLoadingError = err
            globalUpdate()
        }
    )

    // ref.on('child_removed', function(oldChildSnapshot) {
    //   ...
    // });
    workspaceRef.on(
        'value',
        (snap) => {
            // console.log('got value', snap.val() || {})
            let new_snapshot = snap.val() || {}

            let delta = {}
            for (let key in new_snapshot) {
                if (
                    !_.isEqual(new_snapshot[key], get(key)) &&
                    !(get(key) && get(key).v > new_snapshot[key].v)
                ) {
                    delta[key] = new_snapshot[key]
                    // console.log(key, '->', new_snapshot[key])
                }
            }
            console.log('new dleta', delta)
            apply(delta)

            // localDB.entities.bulkPut()

            if (!dataLoaded) {
                dataLoaded = true
                quark.popState()
            }
            globalUpdate()
        },
        (err) => {
            console.error(err)
            dataLoadingError = err
            globalUpdate()
        }
    )

    saveCallback = function(buffer) {
        console.log(buffer)
        setTimeout(() => workspaceRef.update(buffer), 10)
    }
}

function normalizeAccessList(rawAccessList) {
    let fixedAccessList = {
        PUBLIC: 0,
    }

    for (let key in rawAccessList) {
        fixedAccessList[key.replace(/\,/g, '.')] = rawAccessList[key]
    }
    return fixedAccessList
}

export function reset() {
    workspaceRef.set({}, (done) => {
        location.reload(true)
    })
}

// this component can't be hot reloaded
if (module.hot) module.hot.decline()
