import _ from 'lodash'
import React from 'react'
import TextEditor from '../editor/LazyTextEditor'
import Spinner from './Spinner'

import * as predicate from '../quark/predicates/predicate'
import * as quark from '../quark'

const fetching = {}
const resolved = {}
const LOADING = {}
// function fetchSuspense(url){
//     if(url in resolved) return resolved[url];
//     if(!(url in fetching)){
//         fetching[url] = cors(url)
//             .then(k => k.json())
//         .then(data => {
//             resolved[url] = data
//         }).catch(err => {
//             resolved[url] = null
//         })
//     }
//     throw fetching[url]
// }

function suspend(key, fetcher) {
    if (key in resolved) return resolved[key]
    if (!(key in fetching)) {
        fetching[key] = fetcher()
            .then((data) => {
                resolved[key] = data
            })
            .catch((err) => {
                resolved[key] = null
            })
    }
    throw fetching[key]
}

function useLoader() {
    let [version, setVersion] = React.useState(0)
    return function load(key, fetcher) {
        if (key in resolved) return resolved[key]
        if (!(key in fetching)) {
            fetching[key] = fetcher()
                .then((data) => {
                    resolved[key] = data
                    setVersion((k) => k + 1)
                })
                .catch((err) => {
                    resolved[key] = null
                    setVersion((k) => k + 1)
                })
        }
        return LOADING
    }
}

export default function AutoimportWrapper(props) {
    return (
        <React.Suspense fallback={null}>
            <Autoimport {...props} />
        </React.Suspense>
    )
}

function Autoimport({ line, path }: { line: Entity; path: Entity[] }) {
    // return null
    if (line.noAutoimport) return null

    const load = useLoader()

    let [page, setPage] = React.useState(0)

    let topic = line.text
    let res = load(topic, () =>
        cors(
            `https://www.wikidata.org/w/api.php?action=wbsearchentities&search=${topic}&language=en&format=json`
        )
    )

    if (res === LOADING) return null
    if (!res) return null
    if (res.search.length === 0) return null

    // console.log(res)

    let id = res.search[page].id
    let data = load(id, () => getData(id))

    if ((!data || data === LOADING) && page === 0) return null

    let parent = line
    let children = quark.enlist(line)

    let existingPredicates = children.filter((k) => k.type === 'cluster').map((k) => k.predicate)

    let matchingLines = Object.entries(data).filter(
        ([p, values]) =>
            !existingPredicates.some((k) =>
                predicate.predicate_is_equal(k, p.replace(/:|=/g, '').trim())
            )
    )
    // console.log(data);

    if (matchingLines.length === 0 && page === 0) return null

    console.log(matchingLines)

    function formatRelation(predicate, values) {
        console.log(predicate, values)
        if (values.some((k) => typeof k === 'object' && k.type == 'string')) {
            return predicate + '= ' + values[0].value
        }
        if (values.some((k) => typeof k === 'object' && k.type == 'quantity')) {
            if (values[0].value.unit === 'http://www.wikidata.org/entity/Q4917') {
                return (
                    predicate +
                    '= ' +
                    parseInt(values[0].value.amount).toLocaleString(undefined, {
                        style: 'currency',
                        currency: 'USD',
                    })
                )
            }
            return predicate + '= ' + parseInt(values[0].value.amount).toLocaleString()
        }

        return (
            predicate +
            ': ' +
            values
                .filter((k) => typeof k !== 'object')
                .map((k) => k.replace(/\,/g, ''))
                .join(', ')
        )
    }

    return (
        <div className="external-data">
            <button
                className="dismiss"
                onClick={(e) => {
                    quark.core.update(line.id, {
                        noAutoimport: true,
                    })
                    quark.checkpoint()
                }}
            >
                &times;
            </button>
            <div className="possible-additions">
                {matchingLines.length === 0 &&
                    (data === LOADING ? (
                        <div>
                            <Spinner message=" " />
                        </div>
                    ) : (
                        <div>
                            <i>(no data...)</i>
                        </div>
                    ))}
                {matchingLines.map(([predicate, values]) => (
                    <div className="node-self" key={predicate}>
                        <div className="node-bullet" />
                        <div className="node">
                            <TextEditor
                                forceLazy
                                line={{}}
                                path={path}
                                text={formatRelation(predicate, values)}
                            />
                        </div>
                    </div>
                ))}
            </div>
            <div className="add-stuff">
                <div className="button-wrap">
                    <button
                        onClick={(e) => {
                            quark.core.update(line.id, {
                                noAutoimport: true,
                            })

                            let ref = children.length > 0 ? children[children.length - 1].id : null

                            matchingLines.forEach(([predicate, values]) => {
                                let text_line = formatRelation(predicate, values)
                                let refEl = quark.get(ref)
                                if (refEl && refEl.type == 'text' && refEl.text === '') {
                                    var child = ref
                                } else {
                                    var child = quark.create_child(parent, ref)
                                }
                                quark.set_text(parent, quark.get(child), text_line)
                                ref = child
                            })

                            quark.checkpoint()
                        }}
                    >
                        + Import External Data
                    </button>
                </div>
                <div className="controls">
                    <div className="spacer" />
                    <div>
                        <select
                            value={page}
                            onChange={(e) => {
                                setPage(+(e.target as HTMLSelectElement).value)
                            }}
                        >
                            {res.search.map((k, i) => (
                                <option value={i} key={k.id}>
                                    {k.label}
                                    {k.description && ` (${k.description})`}
                                </option>
                            ))}
                        </select>
                        via <img src={require('../assets/wikidata.svg')} style={{ height: 15 }} />
                        <b>Wikidata</b>
                    </div>
                </div>
            </div>
        </div>
    )
}

async function cors(url: string, options = null) {
    let res = await quark.store.dataFetcher({ url: url })
    return res.data
    // return fetch('https://bypasscors.herokuapp.com/api/?url=' + encodeURIComponent(url)).then(k => k.json())
}

async function getData(id) {
    // let res = await cors(`https://www.wikidata.org/w/api.php?action=wbsearchentities&search=${topic}&language=en&format=json`)
    // let {search} = await res.json()
    // if(!search.length) return

    // const {id} = search[0]
    const data = await getRecord(id)
    const { claims } = data.entities[id]

    const importantPropertyIds = Object.keys(claims).filter((k) => properties[k])

    const foreignIds = importantPropertyIds
        .filter((k) => claims[k].some((subclaim) => subclaim.mainsnak.datatype === 'wikibase-item'))
        .map((k) => claims[k].map((subclaim) => subclaim.mainsnak.datavalue.value.id))
        .flat()

    const chunks = _.chunk(foreignIds, 50).map(async (chunk) => {
        const ids = chunk.join('|')
        const res = await cors(
            `https://www.wikidata.org/w/api.php?action=wbgetentities&languages=en&props=labels&format=json&ids=${ids}`
        )
        return res.entities
    })

    const labels = await Promise.all(chunks)
    const entities = Object.assign({}, ...labels)

    return _.fromPairs(
        importantPropertyIds.map((id) => [
            properties[id],
            claims[id].map((x) => {
                let main = x.mainsnak

                if (main.datatype !== 'wikibase-item') return main.datavalue

                const { labels } = entities[main.datavalue.value.id]

                if (!labels.en) return []

                return labels.en.value
            }),
        ])
    )
}

async function getRecord(id) {
    return await cors('https://www.wikidata.org/wiki/Special:EntityData/' + id, {
        headers: {
            Accept: 'application/json',
        },
    })
}

let properties = {
    P106: 'Occupation',
    P26: 'Spouse',
    P22: 'Father',
    P19: 'Birthplace',
    P184: 'Doctoral advisor',
    P2142: 'box office',
    P112: 'Founders',
    P50: 'Author',
    P108: 'Employer',
    P69: 'Alma mater',
    P856: 'Website',
    P169: 'CEO',
    P2226: 'Market Cap',

    // P569: "Birthday=",

    // P1019: "web feed URL",
    // P101: "field of work",
    // P101: "field of work",
    // P1025: "SUDOC editions",
    // P1025: "SUDOC editions",
    // P1033: "GHS signal word",
    // P1036: "Dewey Decimal Classification",
    // P1057: "chromosome",
    // P105: "taxon rank",
    // P1060: "pathogen transmission process",
    // P1084: "EUL editions",
    // P1088: "Mohs' hardness",
    // P1104: "number of pages",
    // P1109: "refractive index",
    // P110: "illustrator",
    // P1117: "pKa",
    // P1135: "nomenclatural status",
    // P1143: "BN",
    // P1144: "Library of Congress Control Number",
    // P1149: "Library of Congress Classification",
    // P1160: "ISO 4 abbreviation",
    // P117: "chemical structure",
    // P1182: "LIBRIS editions",
    // P1189: "Chinese Library Classification",
    // P1190: "Universal Decimal Classification",
    // P1193: "prevalence",
    // P1207: "NUKAT",
    // P1213: "NLC authorities",
    // P1225: "US National Archives Identifier",
    // P1230: "JSTOR journal code",
    // P123: "publisher",
    // P123: "publisher",
    // P123: "publisher",
    // P123: "publisher",
    // P123: "publisher",
    // P127: "owned by",
    // P1284: "Munzinger IBA",
    // P1292: "DNB editions",
    // P1300: "bibcode",
    // P1325: "external data available at",
    // P1343: "described by source",
    // P1348: "AlgaeBase URL",
    // P1353: "original spelling",
    // P1366: "replaced by",
    // P136: "genre",
    // P136: "genre",
    // P1403: "original combination",
    // P1416: "affiliation",
    // P141: "IUCN conservation status",
    // P1420: "taxon synonym",
    // P1421: "GRIN URL",
    // P1433: "published in",
    // P1433: "published in",
    // P1476: "title",
    // P1476: "title",
    // P1476: "title",
    // P1476: "title",
    // P1476: "title",
    // P1476: "title",
    // P1476: "title",
    // P1476: "title",
    // P1531: "parent of this hybrid, breed, or cultivar",
    // P1552: "has quality",
    // P1552: "has quality",
    // P1552: "has quality",
    // P1559: "name in native language",
    // P155: "follows",
    // P1575: "RISS catalog",
    // P1578: "Gmelin number",
    // P1579: "Reaxys registry number",
    // P1582: "natural product of taxon",
    // P159: "Headquarters",
    // P1603: "number of cases",
    // P1604: "biosafety level",
    // P1605: "has natural reservoir",
    // P1606: "natural reservoir of",
    // P1630: "formatter URL",
    // P1662: "DOI prefix",
    // P1672: "this taxon is source of",
    // P1680: "subtitle",
    // P1680: "subtitle",
    // P1680: "subtitle",
    // P1680: "subtitle",
    // P1683: "quote",
    // P1690: "ICD-10-PCS",
    // P1691: "operations and procedures key",
    // P1692: "ICD-9-CM",
    // P1693: "Terminologia Embryologica",
    // P1694: "Terminologia Histologica",
    // P171: "parent taxon",
    // P179: "part of the series",
    // P1815: "RSL scanned books identifier",
    // P181: "taxon range map image",
    // P183: "endemic to",
    // P1843: "taxon common name",
    // P1853: "blood type",
    // P18: "image",
    // P1909: "side effect",
    // P1930: "DSM-5 classification",
    // P1966: "Biblioteca Nacional de Chile catalogue number",
    // P1973: "RSL editions",
    // P1995: "health specialty",
    // P2002: "Twitter username",
    // P2017: "isomeric SMILES",
    // P2017: "isomeric SMILES",
    // P2054: "density",
    // P2055: "electrical conductivity",
    // P2056: "heat capacity",
    // P2066: "fusion enthalpy",
    // P2075: "speed of sound",
    // P2093: "author name string",
    // P2101: "melting point",
    // P2102: "boiling point",
    // P2107: "decomposition point",
    // P2113: "sublimation temperature",
    // P2116: "enthalpy of vaporization",
    // P2117: "combustion enthalpy",
    // P2118: "kinematic viscosity",
    // P2119: "vapor pressure",
    // P2128: "flash point",
    // P2129: "IDLH",
    // P212: "ISBN-13",
    // P212: "ISBN-13",
    // P212: "ISBN-13",
    // P213: "ISNI",
    // P213: "ISNI",
    // P2156: "pseudo crystal habit",
    // P2175: "medical condition treated",
    // P2176: "drug used for treatment",
    // P2176: "drug used for treatment",
    // P2176: "drug used for treatment",
    // P2177: "solubility",
    // P217: "inventory number",
    // P2199: "autoignition temperature",
    // P2201: "electric dipole moment",
    // P2202: "lower flammable limit",
    // P2203: "upper flammable limit",
    // P2240: "median lethal dose",
    // P225: "taxon name",
    // P2275: "World Health Organisation International Nonproprietary Name",
    // P2293: "genetic association",
    // P2293: "genetic association",
    // P2300: "minimal lethal dose",
    // P231: "CAS Registry Number",
    // P231: "CAS Registry Number",
    // P232: "EC number",
    // P233: "canonical SMILES",
    // P233: "canonical SMILES",
    // P234: "InChI",
    // P234: "InChI",
    // P235: "InChIKey",
    // P235: "InChIKey",
    // P236: "ISSN",
    // P236: "ISSN",
    // P236: "ISSN",
    // P2404: "time-weighted average exposure limit",
    // P2405: "ceiling exposure limit",
    // P2406: "maximum peak exposure limit",
    // P2407: "short-term exposure limit",
    // P2433: "gender of a scientific name of a genus",
    // P243: "OCLC control number",
    // P248: "stated in",
    // P2507: "corrigendum / erratum",
    // P2542: "acceptable daily intake",
    // P2565: "global-warming potential",
    // P267: "ATC code",
    // P2687: "NDL JPNO",
    // P2710: "minimal lethal concentration",
    // P2712: "median lethal concentration",
    // P2717: "no-observed-adverse-effect level",
    // P2718: "lowest-observed-adverse-effect level",
    // P2743: "this zoological name is coordinate with",
    // P274: "chemical formula",
    // P274: "chemical formula",
    // P275: "license",
    // P275: "license",
    // P275: "license",
    // P2761: "Research Papers in Economics Series handle",
    // P279: "subclass of",
    // P2841: "age of onset",
    // P2844: "incidence",
    // P2854: "disease burden",
    // P2859: "X-SAMPA Code",
    // P2860: "cites",
    // P2888: "exact match",
    // P2888: "exact match",
    // P2892: "UMLS CUI",
    // P291: "place of publication",
    // P291: "place of publication",
    // P2951: "Cultural heritage database in Austria ObjektID",
    // P2989: "has grammatical case",
    // P2993: "partition coefficient water/octanol",
    // P3013: "surface tension",
    // P304: "page",
    // P3070: "dynamic viscosity",
    // P3071: "standard molar entropy",
    // P3073: "CosIng number",
    // P3078: "standard enthalpy of formation",
    // P3098: "ClinicalTrials.gov Identifier",
    // P3103: "has tense",
    // P3161: "has grammatical mood",
    // P31: "instance of",
    // P31: "instance of",
    // P31: "instance of",
    // P31: "instance of",
    // P31: "instance of",
    // P3487: "maximal incubation period in humans",
    // P3488: "minimal incubation period in humans",
    // P356: "DOI",
    // P356: "DOI",
    // P361: "part of",
    // P361: "part of",
    // P361: "part of",
    // P373: "Commons category",
    // P373: "Commons category",
    // P373: "Commons category",
    // P373: "Commons category",
    // P373: "Commons category",
    // P3878: "Soundex",
    // P3879: "Cologne phonetics",
    // P3931: "copyright holder",
    // P3931: "copyright holder",
    // P3939: "ESTC citation number",
    // P393: "edition number",
    // P3982: "TA98 Latin term",
    // P4016: "SlideShare username",
    // P4016: "SlideShare username",
    // P4032: "reviewed by",
    // P405: "taxon author",
    // P407: "language of work or name",
    // P407: "language of work or name",
    // P407: "language of work or name",
    // P407: "language of work or name",
    // P407: "language of work or name",
    // P407: "language of work or name",
    // P407: "language of work or name",
    // P407: "language of work or name",
    // P4101: "dissertation submitted to",
    // P4109: "URN-NBN",
    // P4229: "ICD-10-CM",
    // P4250: "defined daily dose",
    // P427: "taxonomic type",
    // P428: "botanist author abbreviation",
    // P433: "issue",
    // P4354: "search formatter URL",
    // P437: "distribution format",
    // P443: "pronunciation audio",
    // P4510: "describes a project that uses",
    // P4510: "describes a project that uses",
    // P478: "volume",
    // P478: "volume",
    // P493: "ICD-9",
    // P493: "ICD-9",
    // P494: "ICD-10",
    // P494: "ICD-10",
    // P4952: "safety classification and labelling",
    // P502: "HURDAT identifier",
    // P503: "ISO standard",
    // P5040: "GHS hazard pictogram",
    // P5041: "GHS hazard statement",
    // P5042: "GHS precautionary statement",
    // P50: "author",
    // P50: "author",
    // P50: "author",
    // P50: "author",
    // P5109: "has grammatical gender",
    // P5110: "has grammatical person",
    // P5137: "item for this sense",
    // P5185: "grammatical gender",
    // P5186: "conjugation class",
    // P5187: "word stem",
    // P5188: "Sandbox-Lexeme",
    // P5189: "Sandbox-Form",
    // P5191: "derived from",
    // P5192: "Wikidata property example for lexemes",
    // P5193: "Wikidata property example for forms",
    // P5194: "officialized by",
    // P5205: "stroke count",
    // P5206: "has conjugation class",
    // P5237: "pronunciation variety",
    // P5238: "combines",
    // P523: "temporal range start",
    // P524: "temporal range end",
    // P5276: "Slavic phonetic alphabet",
    // P5279: "hyphenation",
    // P527: "has part",
    // P528: "catalog code",
    // P5304: "type locality",
    // P5323: "attested in",
    // P5326: "publication in which this taxon name was established",
    // P5326: "publication in which this taxon name was established",
    // P5401: "auxiliary verb",
    // P5402: "homograph lexeme",
    // P5425: "Han character in this lexeme",
    // P5426: "Japanese pitch accent type",
    // P5446: "reference value",
    // P5526: "valency",
    // P5548: "derived from form",
    // P556: "crystal system",
    // P557: "DiseasesDB",
    // P563: "ICD-O",
    // P565: "crystal habit",
    // P566: "basionym",
    // P5713: "requires grammatical feature",
    // P571: "inception",
    // P574: "year of taxon name publication",
    // P576: "dissolved, abolished or demolished",
    // P577: "Publication Date=",
    // P577: "publication date",
    // P577: "publication date",
    // P5824: "is retracted by",
    // P5830: "demonstrates form",
    // P5831: "usage example",
    // P5886: "mode of derivation",
    // P589: "point group",
    // P5911: "inflection class",
    // P5913: "has inflection class",
    // P5920: "root",
    // P5923: "creates lexeme type",
    // P5972: "translation",
    // P5973: "synonym",
    // P5974: "antonym",
    // P5975: "troponym of",
    // P5976: "false friend",
    // P5977: "Wikidata property example for senses",
    // P5978: "classifier",
    // P5979: "Sandbox-Sense",
    // P5980: "derived from sense",
    // P6072: "demonstrates sense",
    // P6084: "location of sense usage",
    // P6191: "language style",
    // P629: "edition or translation of",
    // P636: "route of administration",
    // P649: "NRHP reference number",
    // P652: "UNII",
    // P652: "UNII",
    // P653: "PubMed Health",
    // P655: "translator",
    // P655: "translator",
    // P6571: "collective noun for animals",
    // P657: "RTECS number",
    // P662: "PubChem CID",
    // P662: "PubChem CID",
    // P663: "DSM-IV classification",
    // P6721: "K10plus editions",
    // P672: "MeSH Code",
    // P673: "eMedicine",
    // P674: "characters",
    // P678: "incertae sedis",
    // P679: "ZVG number",
    // P690: "space group",
    // P694: "replaced synonym",
    // P695: "UN number",
    // P697: "ex taxon author",
    // P700: "Kemler code",
    // P703: "found in taxon",
    // P703: "found in taxon",
    // P703: "found in taxon",
    // P747: "has edition",
    // P769: "significant drug interaction",
    // P780: "symptoms",
    // P791: "International Standard Identifier for Libraries",
    // P800: "notable work",
    // P813: "retrieved",
    // P815: "ITIS TSN",
    // P819: "ADS bibcode",
    // P828: "has cause",
    // P835: "author citation",
    // P835: "author citation",
    // P850: "WoRMS-ID for taxa",
    // P854: "reference URL",
    // P859: "sponsor",
    // P859: "sponsor",
    // P872: "printed by",
    // P873: "phase point",
    // P874: "UN class",
    // P875: "UN code classification",
    // P876: "UN packaging group",
    // P877: "NFPA Special",
    // P898: "IPA transcription",
    // P921: "main subject",
    // P921: "main subject",
    // P921: "main subject",
    // P921: "main subject",
    // P921: "main subject",
    // P921: "main subject",
    // P921: "main subject",
    // P923: "medical examinations",
    // P924: "possible treatment",
    // P925: "presynaptic connection",
    // P926: "postsynaptic connection",
    // P927: "anatomical location",
    // P928: "activating neurotransmitter",
    // P932: "PMCID",
    // P932: "PMCID",
    // P944: "Code of nomenclature",
    // P953: "full work available at",
    // P953: "full work available at",
    // P953: "full work available at",
    // P953: "full work available at",
    // P953: "full work available at",
    // P953: "full work available at",
    // P957: "ISBN-10",
    // P957: "ISBN-10",
    // P968: "e-mail address",
    // P970: "neurological function",
    // P973: "described at URL",
    // P98: "editor",
    // P98: "editor",
    // P98: "editor",
    // P98: "editor",
    // P98: "editor",
    // P993: "NFPA Health",
    // P994: "NFPA Fire",
    // P995: "NFPA Instability",
    // P996: "scanned file on Wikimedia Commons",
    // P1001: "applies to jurisdiction",
    // P1001: "applies to jurisdiction",
    // P1003: "NLR",
    // P1016: "asteroid taxonomy",
    // P1018: "language regulatory body",
    // P101: "field of work",
    // P1021: "KldB-2010",
    // P1022: "CNO-11",
    // P1023: "SBC-2010",
    // P1024: "SBFI",
    // P1026: "doctoral thesis",
    // P102: "member of political party",
    // P1030: "light characteristic of lighthouse",
    // P1031: "legal citation of this text",
    // P1037: "director/manager",
    // P1037: "director/manager",
    // P1038: "relative",
    // P103: "native language",
    // P1040: "film editor",
    // P1043: "IDEO",
    // P1046: "discovery method",
    // P1050: "medical condition",
    // P1052: "CPP-2010",
    // P1056: "product or material produced",
    // P1057: "chromosome",
    // P1064: "track gauge",
    // P1065: "archive URL",
    // P1066: "student of",
    // P1066: "student of",
    // P1067: "Thailand central administrative unit code",
    // P1069: "DISCO",
    // P1075: "rector",
    // P1077: "KOATUU identifier",
    // P1079: "launch contractor",
    // P1082: "population",

    // P1090: "redshift",
    // P1098: "number of speakers",
    // P109: "signature",
    // P1101: "floors above ground",
    // P1103: "number of platform tracks",
    // P1114: "quantity",
    // P1116: "ELSTAT geographical code",
    // P1125: "Gini coefficient",
    // P1128: "employees",

    // P1133: "DGO4 identifier",
    // P1139: "floors below ground",
    // P1158: "location of landing",
    // P1164: "cardinality of the group",
    // P1171: "approximation algorithm",
    // P1181: "numeric value",
    // P1191: "date of first performance",
    // P1191: "date of first performance",
    // P1196: "manner of death",
    // P1198: "unemployment rate",
    // P1199: "mode of inheritance",
    // P119: "place of burial",
    // P1201: "space tug",
    // P1202: "carries scientific instrument",
    // P1215: "apparent magnitude",
    // P1216: "National Heritage List for England number",
    // P1227: "astronomical filter",
    // P122: "basic form of government",
    // P1232: "Linguist list code",
    // P1236: "Parsons code",
    // P123: "publisher",
    // P123: "publisher",
    // P1243: "International Standard Recording Code",
    // P1251: "ABS ASCL 2011 code",
    // P1252: "AUSTLANG code",
    // P1260: "Swedish Open Cultural Heritage URI",
    // P1279: "inflation rate",
    // P127: "owned by",
    // P1280: "CONOR",
    // P128: "regulates",
    // P1290: "godparent",
    // P1301: "number of elevators",
    // P1302: "primary destinations",
    // P1303: "instrument",
    // P1303: "instrument",
    // P1304: "central bank",
    // P1308: "officeholder",
    // P1312: "has facet polytope",
    // P1313: "office held by head of government",
    // P1313: "office held by head of government",
    // P1314: "number of spans",
    // P1315: "NLA Trove",
    // P1317: "floruit",
    // P1318: "proved by",
    // P131: "located in the administrative territorial entity",
    // P131: "located in the administrative territorial entity",
    // P1322: "dual to",
    // P1332: "coordinates of northernmost point",
    // P1333: "coordinates of southernmost point",
    // P1334: "coordinates of easternmost point",
    // P1335: "coordinates of westernmost point",
    // P1340: "eye color",
    // P1343: "described by source",
    // P1343: "described by source",
    // P1344: "participant of",
    // P1344: "participant of",
    // P1349: "ploidy",
    // P1352: "ranking",
    // P1352: "ranking",
    // P135: "movement",
    // P1365: "replaces",
    // P1366: "replaced by",
    // P1369: "Iranian National Heritage registration number",
    // P136: "genre",
    // P136: "genre",
    // P136: "genre",
    // P136: "genre",
    // P136: "genre",
    // P1382: "partially coincident with",
    // P1383: "contains settlement",
    // P1388: "German regional key",
    // P138: "named after",
    // P138: "named after",
    // P138: "named after",
    // P138: "named after",
    // P1393: "proxy",
    // P1394: "Glottolog code",
    // P1396: "Linguasphere code",
    // P1398: "structure replaces",
    // P140: "religion",
    // P1411: "nominated for",
    // P1411: "nominated for",
    // P1411: "nominated for",
    // P1416: "affiliation",
    // P1416: "affiliation",
    // P1420: "taxon synonym",
    // P1427: "start point",
    // P1431: "executive producer",
    // P1433: "published in",
    // P1435: "heritage designation",
    // P1437: "plea",
    // P143: "imported from Wikimedia project",
    // P1442: "image of grave",
    // P1448: "official name",
    // P1448: "official name",
    // P1449: "nickname",
    // P144: "based on",
    // P1451: "motto text",
    // P1454: "legal form",
    // P1455: "list of works",
    // P1456: "list of monuments",
    // P1457: "absolute magnitude",
    // P1464: "category for people born here",
    // P1465: "category for people who died here",
    // P1466: "WALS lect code",
    // P1467: "WALS genus code",
    // P1468: "WALS family code",
    // P1476: "title",
    // P1476: "title",
    // P1477: "birth name",
    // P1477: "birth name",
    // P14: "traffic sign",
    // P150: "contains administrative territorial entity",
    // P1529: "Gertrude identifier",
    // P1533: "family name identical to this given name",
    // P1538: "number of households",
    // P1539: "female population",
    // P1540: "male population",
    // P1544: "Federal Register Document Number",
    // P1545: "series ordinal",
    // P1546: "motto",
    // P1549: "demonym",
    // P154: "logo image",
    // P154: "logo image",
    // P1559: "name in native language",
    // P1559: "name in native language",
    // P155: "follows",
    // P155: "follows",
    // P1560: "given name version for other gender",
    // P1568: "domain",
    // P156: "followed by",
    // P156: "followed by",
    // P1571: "codomain",
    // P1576: "lifestyle",
    // P157: "killed by",
    // P1585: "Brazilian municipality code",
    // P1588: "village code of Indonesia",
    // P1589: "lowest point",
    // P158: "seal image",
    // P1591: "defendant",
    // P1592: "prosecutor",
    // P1593: "defender",
    // P1594: "judge",
    // P1595: "charge",
    // P1596: "penalty",
    // P159: "headquarters location",
    // P159: "headquarters location",
    // P1600: "Inventari del Patrimoni Arquitectònic de Catalunya code",
    // P161: "cast member",
    // P1620: "plaintiff",
    // P1621: "detail map",
    // P1625: "has melody",
    // P1627: "Ethnologue.com language code",
    // P162: "producer",
    // P162: "producer",
    // P1635: "religious name",
    // P1635: "religious name",
    // P1638: "codename",
    // P1638: "codename",
    // P163: "flag",
    // P163: "flag",
    // P1653: "TERYT municipality code",
    // P1657: "MPAA film rating",
    // P166: "award received",
    // P166: "award received",
    // P166: "award received",
    // P166: "award received",
    // P166: "award received",
    // P1671: "route number",
    // P1678: "has vertex figure",
    // P167: "structure replaced by",
    // P1683: "quote",
    // P1686: "for work",
    // P1686: "for work",
    // P1690: "ICD-10-PCS",
    // P1691: "operations and procedures key",
    // P1692: "ICD-9-CM",
    // P1693: "Terminologia Embryologica",
    // P1694: "Terminologia Histologica",

    // P169: "chief executive officer",
    // P16: "highway system",
    // P1705: "native label",
    // P172: "ethnic group",
    // P1734: "oath of office date",
    // P175: "performer",
    // P1769: "denkXweb identifier",
    // P176: "manufacturer",
    // P1770: "Romania LMI code",
    // P1775: "follower of",
    // P177: "crosses",
    // P1780: "school of",
    // P1782: "courtesy name",
    // P1785: "temple name",
    // P1786: "posthumous name",
    // P1787: "art-name",
    // P1789: "chief operating officer",
    // P1798: "ISO 639-5 code",
    // P179: "part of the series",
    // P179: "part of the series",
    // P17: "country",
    // P17: "country",
    // P17: "country",
    // P17: "country",
    // P1809: "choreographer",
    // P1810: "named as",
    // P1810: "named as",
    // P1810: "named as",
    // P1811: "list of episodes",
    // P1813: "short name",
    // P1813: "short name",
    // P1814: "name in kana",
    // P1824: "road number",
    // P1827: "ISWC",
    // P1828: "IPI name number",
    // P1843: "taxon common name",
    // P1848: "protected areas INPN Code",

    // P1851: "input set",
    // P1853: "blood type",
    // P185: "doctoral student",
    // P1872: "minimum number of players",
    // P1873: "maximum number of players",
    // P1881: "list of characters",
    // P1884: "hair color",
    // P1890: "BNC",
    // P1891: "signatory",
    // P18: "image",
    // P1906: "office held by head of state",
    // P1906: "office held by head of state",
    // P190: "twinned administrative body",
    // P190: "twinned administrative body",
    // P1911: "increased expression in",
    // P1912: "deletion association with",
    // P1913: "gene duplication association with",
    // P1914: "gene insertion association with",
    // P1915: "gene inversion association with",
    // P1916: "gene substitution association with",
    // P1917: "posttranslational modification association with",
    // P1918: "altered regulation leads to",
    // P193: "main building contractor",
    // P1943: "location map",
    // P1944: "relief location map",
    // P194: "legislative body",
    // P194: "legislative body",
    // P1950: "second family name in Spanish name",
    // P1950: "second family name in Spanish name",
    // P196: "minor planet group",
    // P197: "adjacent station",
    // P1981: "FSK film rating",
    // P1999: "UNESCO language status",
    // P199: "business division",

    // P2002: "Twitter username",
    // P200: "lake inflows",
    // P201: "lake outflow",
    // P2031: "work period",
    // P2032: "work period",
    // P2043: "length",
    // P2044: "elevation above sea level",
    // P2045: "orbital inclination",
    // P2046: "area",
    // P2047: "duration",
    // P2048: "height",
    // P2048: "height",
    // P2053: "watershed area",
    // P2058: "depositor",
    // P205: "basin country",
    // P2060: "luminosity",
    // P2067: "mass",
    // P206: "located in or next to body of water",
    // P208: "executive body",
    // P2093: "author name string",
    // P2097: "term length of office",
    // P2098: "substitute/deputy/replacement of office/officeholder",
    // P209: "highest judicial authority",
    // P209: "highest judicial authority",
    // P20: "place of death",
    // P210: "party chief representative",
    // P2124: "member count",
    // P2130: "cost",
    // P2131: "nominal GDP",
    // P2132: "nominal GDP per capita",
    // P2133: "total debt",
    // P2135: "total exports",
    // P2136: "total imports",
    // P2139: "total revenue",
    // P213: "ISNI",
    // P2140: "foreign direct investment net outflow",
    // P2141: "foreign direct investment net inflow",

    // P2143: "genome size",
    // P2146: "orbital period",
    // P2148: "distance from river mouth",
    // P215: "spectral class",
    // P2161: "Guthrie code",
    // P2184: "history of topic",
    // P2184: "history of topic",
    // P218: "ISO 639-1 code",
    // P2198: "average gradient",
    // P219: "ISO 639-2 code",
    // P21: "sex or gender",
    // P2208: "average shot length",
    // P220: "ISO 639-3 code",
    // P2218: "net worth",
    // P221: "ISO 639-6 code",
    // P2225: "discharge",

    // P2238: "official symbol",
    // P223: "galaxy morphological type",
    // P2243: "apoapsis",
    // P2244: "periapsis",
    // P225: "taxon name",
    // P2285: "periapsis date",
    // P2286: "arterial supply",
    // P2288: "lymphatic drainage",
    // P2289: "venous drainage",
    // P2291: "charted in",
    // P2291: "charted in",
    // P2293: "genetic association",
    // P2294: "balance of trade",
    // P2295: "net profit",
    // P2296: "money supply",
    // P2299: "PPP GDP per capita",
    // P2329: "antagonist muscle",
    // P2341: "indigenous to",
    // P2358: "Roman praenomen",
    // P2358: "Roman praenomen",
    // P2359: "Roman nomen gentilicium",
    // P2359: "Roman nomen gentilicium",
    // P2363: "NMHH film rating",
    // P2365: "Roman cognomen",
    // P2365: "Roman cognomen",
    // P2366: "Roman agnomen",
    // P2366: "Roman agnomen",
    // P237: "coat of arms",
    // P237: "coat of arms",
    // P2384: "statement describes",
    // P2388: "office held by head of the organization",
    // P2388: "office held by head of the organization",
    // P2389: "organization directed from the office or person",
    // P2393: "NCBI Locus tag",
    // P2403: "total assets",
    // P2408: "set in period",
    // P242: "locator map image",
    // P2441: "literal translation",
    // P248: "stated in",
    // P2504: "Norwegian municipality number",
    // P2505: "carries",
    // P2506: "INSEE canton code",
    // P2515: "costume designer",
    // P2520: "UNESCO Biosphere Reserve url",
    // P2534: "defining formula",
    // P2541: "operating area",
    // P2548: "strand orientation",
    // P2554: "production designer",
    // P2558: "autores.uy",
    // P2561: "name",
    // P2562: "married name",
    // P2562: "married name",
    // P2567: "amended by",
    // P2568: "repealed by",
    // P2583: "distance from Earth",
    // P2584: "Australian Wetlands Code",
    // P2585: "INSEE region code",
    // P2586: "INSEE department code",
    // P2587: "has phoneme",
    // P2590: "BPS language code",
    // P2595: "maximum gradient",
    // P25: "mother",
    // P2614: "World Heritage criteria",
    // P2614: "World Heritage criteria",
    // P2618: "inHerit Place Number",
    // P2629: "BBFC rating",
    // P2633: "geography of topic",
    // P2637: "RARS rating",
    // P263: "official residence",
    // P263: "official residence",
    // P264: "record label",
    // P2650: "interested in",
    // P2659: "topographic isolation",
    // P2660: "topographic prominence",
    // P2666: "Datahub page",
    // P2673: "next crossing upstream",
    // P2674: "next crossing downstream",
    // P2684: "Kijkwijzer rating",
    // P2704: "EIDR identifier",
    // P2715: "elected in",
    // P272: "production company",
    // P2747: "Filmiroda rating",
    // P2755: "exploitation visa number",
    // P2756: "EIRIN film rating",
    // P2758: "CNC film rating",
    // P275: "license",
    // P276: "location",
    // P276: "location",
    // P277: "programming language",
    // P2787: "longest span",
    // P2789: "connects with",
    // P2789: "connects with",
    // P278: "GOST 7.75–97 code",
    // P2793: "clearance",
    // P279: "subclass of",
    // P27: "country of citizenship",
    // P2812: "MathWorld identifier",
    // P281: "postal code",
    // P2820: "cardinality of this set",
    // P2828: "corporate officer",
    // P282: "writing system",
    // P2834: "individual tax rate",
    // P2835: "lowest income threshold",
    // P2836: "highest income threshold",
    // P2838: "professional name",
    // P2838: "professional name",
    // P2855: "VAT-rate",
    // P286: "head coach",
    // P2892: "UMLS CUI",
    // P2923: "focal height",
    // P2927: "water as percent of area",
    // P2929: "lighthouse range",
    // P2951: "Cultural heritage database in Austria ObjektID",
    // P2960: "archive date",
    // P296: "station code",
    // P2976: "patronym or matronym for this name",
    // P2989: "has grammatical case",
    // P3014: "laws applied",
    // P3018: "located in protected area",
    // P3019: "railway signalling system",
    // P3020: "residence time of water",
    // P3032: "adjacent building",
    // P3041: "luminous intensity",
    // P304: "page",
    // P305: "IETF language tag",
    // P306: "operating system",
    // P3075: "official religion",
    // P3086: "speed limit",
    // P3087: "fiscal/tax revenue",
    // P3092: "film crew member",
    // P3094: "develops from",
    // P30: "continent",
    // P3103: "has tense",
    // P3137: "parent peak",
    // P3148: "repeals",
    // P3150: "birthday",
    // P3156: "Australian Classification",
    // P3156: "Australian Classification",
    // P3161: "has grammatical mood",
    // P3179: "territory overlaps",
    // P3189: "innervated by",
    // P3190: "innervates",
    // P31: "instance of",
    // P31: "instance of",
    // P31: "instance of",
    // P31: "instance of",
    // P3212: "ISAN identifier",
    // P3213: "Indian census area code",
    // P3216: "ClassInd rating",
    // P3233: "PhilPeople profile",
    // P3235: "PhilPapers topic",
    // P3261: "anatomical branch of",
    // P3262: "has anatomical branch",
    // P3306: "ICAA rating",
    // P3310: "muscle action",
    // P3311: "plan view image",
    // P3320: "board member",
    // P3320: "board member",
    // P3331: "HGVS nomenclature",
    // P3354: "positive therapeutic predictor",
    // P3355: "negative therapeutic predictor",
    // P3356: "positive diagnostic predictor",
    // P3357: "negative diagnostic predictor",
    // P3358: "positive prognostic predictor",
    // P3359: "negative prognostic predictor",
    // P3362: "operating income",
    // P3373: "sibling",
    // P3402: "CNC film rating",
    // P3403: "coextensive with",
    // P3424: "Polish cultural heritage register number",
    // P3428: "INCAA film rating",
    // P3433: "biological variant of",
    // P3448: "stepparent",
    // P344: "director of photography",
    // P3452: "inferred from",
    // P3460: "colonel-in-chief",
    // P3490: "muscle origin",
    // P3491: "muscle insertion",
    // P3529: "median income",
    // P353: "HGNC gene symbol",
    // P353: "HGNC gene symbol",
    // P355: "subsidiary",
    // P3580: "SIPCA code",
    // P358: "discography",
    // P358: "discography",
    // P35: "head of state",
    // P3610: "fare zone",
    // P361: "part of",
    // P361: "part of",
    // P364: "original language of film or TV show",
    // P364: "original language of film or TV show",
    // P3650: "JMK film rating",
    // P367: "astronomic symbol image",
    // P36: "capital",
    // P3716: "social classification",
    // P3728: "New Zealand Heritage List number",
    // P3732: "PhilPapers record",
    // P373: "Commons category",
    // P374: "INSEE municipality code",
    // P375: "space launch vehicle",
    // P376: "located on astronomical location",
    // P3777: "antisense inhibitor of",
    // P377: "SCN",
    // P3788: "BNA",
    // P37: "official language",
    // P3818: "KMRB film rating",
    // P381: "PCP reference number",
    // P3823: "Ethnologue language status",
    // P3828: "wears",
    // P382: "CBS municipality code",
    // P3831: "object has role",
    // P3834: "RTC film rating",
    // P3837: "United States Public Law",
    // P3842: "located in present-day administrative territorial entity",
    // P3858: "route diagram",
    // P3865: "type of reference",
    // P3871: "tributary orientation",
    // P3896: "geoshape",
    // P38: "currency",
    // P3902: "had as last meal",
    // P3903: "column",
    // P3909: "last words",
    // P3922: "light sector",
    // P3931: "copyright holder",
    // P3938: "named by",
    // P395: "licence plate code",
    // P3968: "CETS number",
    // P3975: "secretary general",
    // P397: "parent astronomical body",
    // P3982: "TA98 Latin term",
    // P3982: "TA98 Latin term",
    // P3982: "TA98 Latin term",
    // P398: "child astronomical body",
    // P3994: "racon signal",
    // P399: "companion of",
    // P39: "position held",
    // P39: "position held",
    // P4001: "Latvian Protected Nature Territory URL",
    // P4006: "overrules",
    // P400: "platform",
    // P4010: "GDP",
    // P403: "mouth of the watercourse",
    // P404: "game mode",
    // P405: "taxon author",
    // P406: "soundtrack album",
    // P4075: "Czech Monument Catalogue Number",
    // P407: "language of work or name",
    // P407: "language of work or name",
    // P407: "language of work or name",
    // P407: "language of work or name",
    // P408: "software engine",
    // P40: "child",
    // P412: "voice type",
    // P412: "voice type",
    // P4132: "linguistic typology",
    // P413: "position played on team / speciality",
    // P417: "patron saint",
    // P418: "seal description",
    // P4196: "cytogenetic location",
    // P41: "flag image",
    // P41: "flag image",
    // P421: "located in time zone",
    // P421: "located in time zone",
    // P4231: "United Nations Treaty Series Registration Number",
    // P424: "Wikimedia language code",
    // P425: "field of this profession",
    // P4271: "rating",
    // P4275: "Japanese Database of National important cultural properties",
    // P4276: "Cinémathèque québécoise work identifier",
    // P428: "botanist author abbreviation",
    // P429: "dantai code",
    // P4335: "IDESCAT territorial code in Catalonia",
    // P433: "issue",
    // P4350: "salinity",
    // P4353: "nominated by",
    // P437: "distribution format",
    // P439: "German municipality key",
    // P4425: "mtDNA haplogroup",
    // P4426: "Y-DNA Haplogroup",
    // P442: "China administrative division code",
    // P4437: "FPB rating",
    // P451: "partner",
    // P452: "industry",
    // P4530: "Bangladesh administrative division code",
    // P453: "character role",
    // P4545: "sexually homologous with",
    // P4552: "mountain range",
    // P457: "foundational text",
    // P457: "foundational text",
    // P4602: "date of burial or cremation",
    // P4614: "drainage basin",
    // P462: "color",
    // P463: "member of",
    // P463: "member of",
    // P463: "member of",
    // P464: "NOR",
    // P4656: "Wikimedia import URL",
    // P4661: "reservoir created",
    // P467: "legislated by",
    // P4688: "geomorphological unit",
    // P469: "lakes on river",
    // P473: "local dialing code",
    // P476: "CELEX number",
    // P4777: "has boundary",
    // P478: "volume",
    // P4792: "dam",
    // P479: "input method",
    // P47: "shares border with",
    // P4806: "Gaming-History identifier",
    // P4812: "Statistical Service of Cyprus Geocode",
    // P4816: "Lemon 64 identifier",
    // P4839: "Wolfram Language entity code",
    // P483: "recorded at",
    // P4846: "Lemon Amiga identifier",
    // P4847: "CPC-Power identifier",
    // P4857: "AtariAge identifier",
    // P485: "archives at",
    // P485: "archives at",
    // P4863: "recognition sequence",
    // P4864: "cutting site of restriction enzyme",
    // P4866: "REBASE Enzyme Number",
    // P4873: "isoschizomer",
    // P4875: "neoschizomer",
    // P4882: "segmental innervation",
    // P4884: "court",
    // P488: "chairperson",
    // P4896: "3D model",
    // P489: "currency symbol description",
    // P490: "provisional designation",
    // P4913: "dialect of",
    // P4914: "produces cohesive end",
    // P4915: "isocaudomer",
    // P4916: "Ready64 identifier",
    // P4917: "Gamebase64 identifier",
    // P4919: "Plus/4 World identifier",
    // P491: "orbit diagram",
    // P495: "country of origin",
    // P4960: "Generation MSX identifier",
    // P4961: "Sega8bit.com identifier",
    // P4962: "Abandonia identifier",
    // P500: "exclave of",
    // P5017: "last update",
    // P501: "enclave within",
    // P5056: "patronym or matronym for this person",
    // P505: "general manager",
    // P509: "cause of death",
    // P50: "author",
    // P512: "academic degree",
    // P5150: "IGAC rating",
    // P5152: "KAVI rating",
    // P51: "audio",
    // P5201: "IMDA rating",
    // P522: "type of orbit",
    // P5230: "chromosome count",
    // P525: "Swedish municipality code",
    // P527: "has part",
    // P527: "has part",
    // P527: "has part",
    // P528: "catalog code",
    // P530: "diplomatic relation",
    // P531: "diplomatic mission sent",
    // P5389: "permanent resident of",
    // P5453: "Logicielsmoto identifier",
    // P551: "residence",
    // P552: "handedness",
    // P553: "website account on",
    // P554: "website username",
    // P5572: "expressed in",
    // P5578: "Indian census area code",
    // P5585: "SMS Power identifier",
    // P5590: "oric.org identifier",
    // P559: "terminus",
    // P562: "central bank/issuer",
    // P566: "basionym",

    // P570: "date of death",
    // P571: "inception",
    // P571: "inception",
    // P571: "inception",
    // P5743: "SR Number",
    // P575: "time of discovery or invention",
    // P576: "dissolved, abolished or demolished",
    // P576: "dissolved, abolished or demolished",
    // P576: "dissolved, abolished or demolished",
    // P577: "publication date",
    // P577: "publication date",
    // P577: "publication date",
    // P577: "publication date",
    // P57: "director",
    // P580: "start time",
    // P580: "start time",
    // P580: "start time",
    // P582: "end time",
    // P582: "end time",
    // P582: "end time",
    // P5838: "Nintendo GameID",
    // P585: "point in time",
    // P585: "point in time",
    // P58: "screenwriter",
    // P591: "EC enzyme number",
    // P5965: "LUBW Protected Area No",
    // P5970: "Medierådet rating",
    // P598: "commander of",
    // P59: "constellation",
    // P6068: "DCMOTO identifier",
    // P609: "terminus location",
    // P610: "highest point",
    // P6170: "System16 identifier",
    // P6193: "ratified by",
    // P619: "time of spacecraft launch",
    // P61: "discoverer or inventor",
    // P61: "discoverer or inventor",
    // P620: "time of spacecraft landing",
    // P621: "time of spacecraft orbit decay",
    // P6229: "Discord Store game SKU",
    // P622: "spacecraft docking/undocking date",
    // P625: "coordinate location",
    // P625: "coordinate location",
    // P631: "structural engineer",
    // P632: "cultural properties of Belarus reference number",
    // P634: "captain",
    // P644: "genomic start",
    // P6452: "CBFC rating",
    // P645: "genomic end",
    // P649: "NRHP reference number",
    // P6569: "taxa especially protected in area",
    // P656: "RefSeq",
    // P658: "tracklist",
    // P659: "genomic assembly",
    // P65: "site of astronomical discovery",
    // P6657: "CHVRS Classification",
    // P6658: "Classification RCQ",
    // P669: "located on street",
    // P66: "ancestral home",
    // P670: "street number",
    // P676: "lyrics by",
    // P676: "lyrics by",
    // P677: "ÚSOP code",
    // P680: "molecular function",
    // P682: "biological process",
    // P684: "ortholog",
    // P688: "encodes",
    // P692: "Gene Atlas Image",
    // P694: "replaced synonym",

    // P6: "head of government",
    // P6: "head of government",
    // P702: "encoded by",
    // P703: "found in taxon",
    // P703: "found in taxon",
    // P706: "located on terrain feature",
    // P707: "satellite bus",
    // P720: "asteroid spectral type",
    // P725: "voice actor",
    // P734: "family name",
    // P734: "family name",
    // P735: "given name",
    // P735: "given name",
    // P736: "cover art by",
    // P737: "influenced by",
    // P737: "influenced by",
    // P737: "influenced by",
    // P740: "location of formation",
    // P740: "location of formation",
    // P742: "pseudonym",
    // P742: "pseudonym",
    // P744: "asteroid family",
    // P746: "date of disappearance",
    // P747: "has edition",
    // P748: "appointed by",
    // P749: "parent organization",
    // P750: "distributor",
    // P750: "distributor",
    // P768: "electoral district",
    // P771: "Swiss municipality code",
    // P772: "INE municipality code",
    // P782: "LAU",
    // P792: "chapter",
    // P793: "significant event",
    // P795: "located on linear feature",
    // P800: "notable work",
    // P802: "student",
    // P802: "student",
    // P803: "professorship",
    // P806: "Italian cadastre code",
    // P808: "Bien de Interés Cultural  code",
    // P811: "academic minor",
    // P812: "academic major",
    // P813: "retrieved",
    // P814: "IUCN protected areas category",
    // P81: "connecting line",
    // P826: "tonality",
    // P833: "interchange station",
    // P834: "train depot",
    // P840: "narrative location",
    // P843: "SIRUTA code",
    // P84: "architect",
    // P852: "ESRB rating",
    // P853: "CERO rating",
    // P854: "reference URL",
    // P85: "anthem",
    // P85: "anthem",
    // P867: "ROME v3",
    // P86: "composer",
    // P86: "composer",
    // P86: "composer",
    // P870: "instrumentation",
    // P87: "librettist",
    // P881: "type of variable star",
    // P885: "origin of the watercourse",
    // P887: "based on heuristic",
    // P908: "PEGI rating",
    // P913: "notation",
    // P913: "notation",
    // P914: "USK rating",
    // P915: "filming location",
    // P916: "GSRR rating",
    // P918: "NOC/CNP",
    // P919: "SOC-2010",
    // P921: "main subject",
    // P925: "presynaptic connection",
    // P926: "postsynaptic connection",
    // P927: "anatomical location",
    // P928: "activating neurotransmitter",
    // P92: "main regulatory text",
    // P92: "main regulatory text",
    // P937: "work location",
    // P939: "KSH code",
    // P941: "inspired by",
    // P943: "programmer",
    // P944: "Code of nomenclature",
    // P946: "ISIN",
    // P94: "coat of arms image",
    // P94: "coat of arms image",
    // P94: "coat of arms image",
    // P952: "ISCO",
    // P958: "section, verse, paragraph, or clause",
    // P964: "Austrian municipality key",
    // P970: "neurological function",
    // P973: "described at URL",
    // P974: "tributary",
    // P988: "Philippine Standard Geographic Code",
    // P990: "audio recording of the subject's spoken voice",
}
