import * as quark from '../quark'
import { globalUpdate } from '../Aphelion'
import CodeMirror from 'codemirror'
import TextEditor from './TextEditor'

export default function createToken(id: string, editor?: TextEditor, toggleCollapse?: (e) => void) {
    var k = quark.get(id)
    var el = document.createElement('span')
    el.className = 'token'

    function ellipsis(str) {
        const MAXLEN = 15

        if (editor && editor.props.line.id == '__root__' && str.length > MAXLEN) {
            return str.slice(0, MAXLEN).trim() + '…'
        }
        return str
    }

    var primary_handler = (e) => {
        global.$$root$$.show_popover = false
        if (editor.props.line.id == '__root__' && quark.root().type == 'root') {
            // console.log('TODO: drop down menu that lets you rename and stuff', quark.root().ref, editor)
            editor.cm.execCommand('selectAll')
            // quark.core.update(quark.root().)
            global.$$root$$.show_popover = true
            globalUpdate()

            e.stopPropagation()
        } else {
            quark.milestone('NAVIGATE_TOKEN_CLICK')
            quark.set_text(undefined, quark.root(), quark.tok(id))
            globalUpdate()
        }
    }

    function span(text, className = 'primary', handler: (e) => void = null, mode = null) {
        var sp = document.createElement('span')
        if (mode) {
            CodeMirror.runMode(text, mode, sp)
        } else {
            sp.appendChild(document.createTextNode(text))
        }

        sp.className = className + ' cm-s-default'
        if (handler) {
            sp.onclick = handler
            sp.ontouchend = handler
        }
        el.appendChild(sp)
    }

    // non-top-level text node
    if (k && k.parent == '__root__' && k.type == 'text') {
        span(k.text, 'primary', primary_handler, 'edgemode')
    } else if (k && k.type == 'text') {
        // TODO: move path stuff to quark
        var path = quark.core.get_path(k).slice(0, -1)
        path.forEach((p) => {
            span(ellipsis(p.text || p.id), 'prefix', (e) => {
                quark.set_text(undefined, quark.root(), quark.tok(p.id))
                globalUpdate()
            })
            span(' \u203A ', 'splitter')
        })
        span(
            ellipsis(k.text),
            'subprimary',
            (e) => {
                quark.set_text(undefined, quark.root(), quark.tok(path[path.length - 1].id))
                global.dofocus = id
                globalUpdate()
            },
            'leafmode'
        )
    } else if (k && k.type == 'cluster') {
        if (k.predicate) {
            span(
                quark.clean_predicate(quark.invert_predicate(k.predicate)) + ': ',
                'subprimary',
                primary_handler,
                'leafmode'
            )
            el.appendChild(createToken(k.parent))
        } else {
            span(k.type + '[', 'prefix')
            el.appendChild(createToken(k.parent))
            quark.enlist(k).forEach((f) => {
                span(', ', 'splitter')
                el.appendChild(createToken(f.id))
            })
            span(']', 'prefix')
        }
    } else if (k && k.type == 'edge') {
        span(k.type + '[', 'prefix')
        if (k.ref) {
            el.appendChild(createToken(k.ref))
        } else {
            span(id, 'splitter')
        }
        // el.appendChild(createToken(k.ref))

        span(']', 'prefix')
    } else if (k && k.type != 'text') {
        span(k.type + '[', 'prefix')
        span(id, 'splitter')
        span(']', 'prefix')
    } else if (id.startsWith('TEXT-')) {
        span(id.slice(5), 'keyword', primary_handler)
    } else if (id.startsWith('TRUNC-')) {
        span('+' + id.split('+').length.toLocaleString('en') + ' more', 'keyword', toggleCollapse)
    } else {
        span(id, 'deprecated', primary_handler, 'edgemode')
    }
    // highlightNode(el)
    return el
}
