import _ from 'lodash'
import React from 'react'
import Navigable from './Navigable'
import TextEditor from '../editor/LazyTextEditor'
import Autoimport from './Autoimport'
import MacDownloadSVG from './MacDownload'
import { BareSpinner } from './Spinner'

import CodeMirror from 'codemirror'
import moment from 'moment'
import { incrementActiveCount, decrementActiveCount, collectActiveCount } from '../quark/collect'
import { LoginPage } from './Login'
import * as quark from '../quark'
import { globalUpdate } from '../Aphelion'

var draggedObject
export default class Lombax extends Navigable<
    {
        path: Entity[]
        default_expanded?: boolean
    },
    {
        expanded: boolean
        hover: boolean
        index?: number
    }
> {
    constructor(props) {
        super(props)
        var expanded = props.default_expanded && !this.loop()
        this.state = { hover: false, expanded: expanded }
    }
    setExpand(state) {
        var { path, line } = this.props
        if (!this.loop()) {
            this.setState({ expanded: state })
        }
    }
    bulletEnter(e) {
        this.setState({ hover: true })
    }
    bulletLeave(e) {
        this.setState({ hover: false })
    }

    isExpand() {
        return this.state.expanded || this.props.line.forceExpand
    }
    isHover() {
        return this.state.hover
    }

    toggleCollapse() {
        let isExpanded = this.isExpand()
        // console.log(this.props.line)
        if (!isExpanded && this.props.line.type === 'cluster') {
            quark.milestone('EXPAND_RELATIONSHIP')
        }
        this.setExpand(!isExpanded)
        this.setFocus(null)
    }
    loop() {
        var { path, line } = this.props
        return path.some((k) => quark.line_is_equal(k, line))
    }
    cursorOffset() {
        if (this.input()) return this.input().cursorOffset()
    }
    focusNext() {
        if (super.focusNext(this.cursorOffset())) this.blur()
    }
    focusPrev() {
        if (super.focusPrev(this.cursorOffset())) this.blur()
    }
    input(): TextEditor {
        return this.refs.input as any
    }
    focus(front = false, offset = null) {
        if (this.input()) this.input().focus(front, offset)
    }
    blur() {
        if (this.input()) this.input().blur()
    }

    navigateSelected() {
        var { path, line } = this.props

        if (path.length > 0) {
            let handled = true
            // console.log(line.type, token)
            let nextFocus = '__root__'
            if (line.type == 'text') {
                var cm = this.input().cm,
                    token = cm.getTokenAt(cm.getCursor())

                if (token.type === 'url') {
                    // setTimeout is to avoid refocusing our window afterwards
                    setTimeout(() => window.open(token.string), 0)
                } else if (token.type === 'hashlink') {
                    let cleanTitle = token.string.trim().replace(/^\[\[(.*)\]\]$/, '$1')
                    quark.set_root_text(quark.tok(quark.fid(cleanTitle)))
                } else if (token.type === 'hashtag') {
                    let cleanTitle = token.string
                        .trim()
                        .replace(/^#(.*)$/, '$1')
                        .replace(/_/, ' ')
                    quark.set_root_text(quark.tok(quark.fid(cleanTitle)))
                } else {
                    quark.set_root_text(quark.tok(line.id))
                }
            } else if (line.type == 'edge') {
                quark.set_root_text(quark.tok(line.ref))
            } else {
                var cm = this.input().cm,
                    token = cm.getTokenAt(cm.getCursor())

                if (token.string.match(/<ref-.*>/)) {
                    quark.set_root_text(token.string.match(/<ref-.*>/)[0])
                } else if (line.type == 'cluster') {
                    if (quark.enlist(line).length == 1) {
                        if (quark.get(quark.enlist(line)[0].ref).parent === '__root__') {
                            quark.set_root_text(quark.tok(quark.enlist(line)[0].ref))
                        } else {
                            nextFocus = quark.enlist(line)[0].ref
                            quark.set_root_text(
                                quark.tok(quark.get(quark.enlist(line)[0].ref).parent)
                            )
                        }
                    } else {
                        var nav_index = 0
                        try {
                            var text = cm.getValue(),
                                cur = cm.getCursor()

                            var nav_index =
                                text
                                    .slice(0, cur.ch)
                                    .split(':')[1]
                                    .split(',').length - 1
                        } catch (err) {
                            console.warn(err)
                        }
                        quark.set_root_text(quark.tok(quark.enlist(line)[nav_index].ref))
                    }
                } else if (token.type == 'entity') {
                    quark.set_root_text(quark.tok(quark.fid(token.string.trim())))
                } else {
                    console.warn('not handled', line, token)
                    handled = false
                }
            }

            if (handled) {
                quark.milestone('KEYBOARD_OPEN_LINK')
            }
            this.setFocus(nextFocus)
            globalUpdate()
        }
    }
    backspace(cm) {
        var { path, line } = this.props

        if (
            (quark.get_text(line) == '' ||
                (quark.get_text(line) == ':' &&
                    line.type == 'edge' &&
                    quark.enlist(quark.get(line.parent)).length > 1)) &&
            line.id != '__root__'
        ) {
            // backspace

            quark.remove_entity(line, false)
            quark.checkpoint()
            this.focusPrev()
            globalUpdate()
        } else if (
            cm.getCursor().ch == 0 &&
            cm.getSelection().length == 0 &&
            line.id != '__root__'
        ) {
            const prev = this.prev()
            const prevline = prev && prev.props.line
            if (!prevline || prevline.type !== 'text') return CodeMirror.Pass

            const text = quark.get_text(line)
            const prevtext = quark.get_text(prevline)

            quark.set_text(quark.get(prevline.parent), quark.get(prevline.id), prevtext + text)

            prev.input()
                .getCM()
                .setCursor(prevtext.length)

            this.setFocus(prevline.id, { ch: prevtext.length })
            this.blur()

            quark.remove_entity(line, false)
            quark.checkpoint()

            globalUpdate()
        } else return CodeMirror.Pass
    }
    down(cm) {
        if (cm.findPosV(cm.getCursor(), 1, 'line').hitSide) {
            this.focusNext()
        } else {
            return CodeMirror.Pass
        }
    }
    up(cm) {
        if (cm.findPosV(cm.getCursor(), -1, 'line').hitSide) {
            this.focusPrev()
        } else {
            return CodeMirror.Pass
        }
    }

    toggleDown() {
        if (!this.isExpand()) this.toggleCollapse()
        this.focusNext()
    }
    toggleUp() {
        if (this.isExpand()) this.toggleCollapse()
        let parent = (this.parent() as any) as this
        if (parent && parent.isExpand()) parent.toggleCollapse()
        this.focusPrev()
    }
    toggleStrike(cm) {
        cm.execCommand('toggleComment')
    }

    keyMap = {
        'Ctrl-Space': 'autocomplete',
        Enter: (cm) => this.handleEnter(false),
        'Shift-Enter': (cm) => this.handleEnter(true),
        Backspace: this.backspace.bind(this),
        Tab: (cm) => this.handleTab(false),
        'Shift-Tab': (cm) => this.handleTab(true),
        Down: this.down.bind(this),
        Up: this.up.bind(this),

        'Cmd-Enter': this.navigateSelected.bind(this),
        'Ctrl-Enter': this.navigateSelected.bind(this),

        'Cmd-Down': this.toggleDown.bind(this),
        'Ctrl-Down': this.toggleDown.bind(this),

        'Cmd-Up': this.toggleUp.bind(this),
        'Ctrl-Up': this.toggleUp.bind(this),

        'Cmd-/': this.toggleStrike.bind(this),
        'Ctrl-/': this.toggleStrike.bind(this),

        // 'Cmd-E': this.focusSearch.bind(this),
        // 'Ctrl-E': this.focusSearch.bind(this),

        // 'Cmd-D': this.makeScratchpad.bind(this),
        // 'Ctrl-D': this.makeScratchpad.bind(this),
    }
    handleEnter(shift) {
        var { path, line } = this.props
        const cm = this.input().getCM()

        if (!shift && line.type === 'text') {
            quark.milestone('CREATE_EMPTY_BULLET')
        }

        if (line.type === 'text' && path.length > 1 && line.text === '') {
            return this.handleTab(true)
        }

        const { ch } = cm.getCursor()

        if (shift || path.length == 0) {
            if (line.type == 'cluster' && line.predicate && quark.enlist(line).length == 1) {
                if (quark.enlist(line)[0].ref) {
                    this.setFocus(quark.create_child(quark.enlist(line)[0]))
                } else {
                    this.setFocus(line.list[0])
                }
                this.blur()
                quark.checkpoint()
                this.setExpand(true)
            } else {
                // quark.milestone('CREATE_EMPTY_BULLET')
                this.setFocus(quark.create_child(line))
                this.blur()
                quark.checkpoint()
                this.setExpand(true)
            }
        } else {
            if (this.input().cm.getCursor().ch == 0 && this.input().getText().length > 1) {
                var parent = path[path.length - 1]
                // if we're at the start of the line, pressing enter
                // actually inserts something before the current line
                // rather than after
                var ref = '^' + line.id
                this.setFocus(quark.create_child(parent, ref))
                this.blur()
                quark.checkpoint()
                globalUpdate()
                return
            }

            // enter anywhere in a cluster just makes it clusterey
            if (line.type == 'cluster') {
                var parent = path[path.length - 1]
                this.setFocus(quark.create_child(parent, line.id))
                this.blur()
                quark.checkpoint()
                globalUpdate()
                return
            }

            if (line.type === 'text' && line.text.length > 0 && ch < line.text.length && ch > 0) {
                const parent = path[path.length - 1]
                const new_id = quark.create_child(parent, line.id)
                quark.set_text(parent, quark.get(line.id), line.text.slice(0, ch))
                quark.set_text(parent, quark.get(new_id), line.text.slice(ch))
                this.setFocus(new_id, { ch: 0 })
                this.blur()
                quark.checkpoint()
                return CodeMirror.Pass
            }

            return CodeMirror.Pass

            // var parent = path[path.length - 1]
            // // if we're at the start of the line, pressing enter
            // // actually inserts something before the current line
            // // rather than after
            // var ref = (this.input().cm.getCursor().ch == 0 &&
            //            this.input().getText().length > 1 ? '^' : '') + line.id;
            // this.setFocus(quark.create_child(parent, ref))
            // this.blur()
            // quark.checkpoint()
        }

        globalUpdate()
    }
    handleTab(shift) {
        var { path, line } = this.props
        // if(line.type != 'text') return;
        // clusters and shit are dangerous
        var oldtext = quark.get_text(line)
        oldtext = quark.remove_refs(oldtext)

        if (shift) {
            quark.milestone('UNINDENT_BULLET')
            var parent = path[path.length - 1]
            var metaparent = path[path.length - 2]
            if (
                metaparent &&
                !this.loop() &&
                (['text', 'edge', 'root'].includes(metaparent.type) ||
                    (metaparent.type == 'cluster' && metaparent.predicate == ''))
            ) {
                quark.detach_entity(line, false)
                quark.insert_relative(metaparent, parent.id, line)
                quark.set_text(metaparent, quark.get(line.id), oldtext)

                // regenerate the parent
                // quark.set_text(parent, line, quark.get_text(parent))
                this.setFocus(line.id, {
                    ch: this.input()
                        .getCM()
                        .getCursor().ch,
                })
                console.warn('handled untab', metaparent, parent)

                quark.checkpoint()
            } else if (
                parent.type == 'edge' &&
                metaparent.type == 'cluster' &&
                metaparent.parent != '__root__' &&
                quark.enlist(metaparent).length == 1
            ) {
                var hyperparent = quark.get(metaparent.parent)

                quark.detach_entity(line, false)
                quark.insert_relative(hyperparent, metaparent.id, line)
                quark.set_text(hyperparent, quark.get(line.id), oldtext)

                this.setFocus(line.id)
                console.warn('quarkskip untab', hyperparent, parent)

                quark.checkpoint()
            } else if (
                line.type == 'text' &&
                parent.type == 'text' &&
                quark.enlist(line).length == 0 &&
                oldtext.trim() == ''
            ) {
                quark.remove_entity(line, false)
                var new_id = quark.create_child(metaparent)

                quark.set_text(metaparent, quark.get(new_id), oldtext)
                quark.checkpoint()
            } else {
                console.warn('unhandled untab', metaparent, parent)
                this.setFocus(null)
            }
        } else {
            var prev: this = this.prevSibling() as any
            var parent: Entity = prev && prev.props.line
            quark.milestone('INDENT_BULLET')
            if (
                prev &&
                !this.loop() &&
                (['text', 'edge', 'root'].includes(parent.type) ||
                    (parent.type == 'cluster' && parent.predicate == ''))
            ) {
                quark.detach_entity(line, false)
                quark.insert_relative(parent, null, line)
                quark.set_text(parent, quark.get(line.id), oldtext)
                this.setFocus(line.id, {
                    ch: this.input()
                        .getCM()
                        .getCursor().ch,
                })

                quark.checkpoint()
                prev.setExpand(true)
            } else if (
                prev &&
                line.type == 'text' &&
                oldtext.trim() == '' &&
                quark.enlist(line).length == 0
            ) {
                quark.remove_entity(line, false)
                var new_id = quark.create_child(parent)
                this.setFocus(new_id)
                quark.set_text(parent, quark.get(new_id), oldtext)
                quark.checkpoint()
                prev.setExpand(true)
            } else this.setFocus(null)
        }
        globalUpdate()
    }
    componentDidMount() {
        var { line } = this.props
        if (line.id == global.dofocus) this.focus(false, global.dofocusOffset)
        incrementActiveCount(line)
    }
    componentWillUnmount() {
        var { line } = this.props
        decrementActiveCount(line)
        collectActiveCount()
    }
    componentDidUpdate(prevProps) {
        var { line } = this.props
        if (line.id == global.dofocus) this.focus(false, global.dofocusOffset)

        decrementActiveCount(prevProps.line)
        incrementActiveCount(line)
        collectActiveCount()
    }
    setFocus(id, offset = null) {
        global.dofocus = id
        global.dofocusOffset = offset
    }
    onChange(text) {
        var { path, line } = this.props
        var parent = path[path.length - 1]
        var oldtext = quark.get_text(line)

        quark.suppressMilestones()

        if (text == oldtext) return

        if (text == '' && line.type == 'edge' && quark.EDGE_AUTOCOLON) text = ':'

        if (line.type === 'text' && text.length > 5) {
            let parts = text.split('=')
            let isField = parts.length == 2 && parts[0].length > 2 && parts[1].length > 4

            if (parts.length == 2 && parts[0].length > 2 && parts[1].length > 4) {
                quark.milestone('ADD_FIELD_TO_NOTE', true)
            }

            if (!quark.hasCompletedMilestone('ADD_TEXT_TO_AUTOGENERATED_NOTE')) {
                if (
                    quark
                        .get_children(parent)
                        .some((k) => k.type === 'cluster' && k.predicate[0] === '~')
                ) {
                    quark.milestone('ADD_TEXT_TO_AUTOGENERATED_NOTE', true)
                }
            }

            if (path.length >= 3 && path.some((k) => k.type === 'cluster') && isField) {
                quark.milestone('ADD_TEXT_TO_INLINE_NOTE', true)
            } else if (path.length >= 2 && path.some((k) => k.type === 'text')) {
                quark.milestone('ADD_TEXT_TO_INDENTED_BULLET', true)
            }

            quark.milestone('ADD_TEXT_TO_NOTE', true)
        } else if (line.type === 'cluster' && line.predicate && line.list.length > 0) {
            quark.milestone('ADD_RELATIONSHIP', true)

            if (!quark.hasCompletedMilestone('ADDITIONAL_NOTES_WITH_RELATIONSHIP')) {
                let notes_with_relationships = Object.values(global.entities).filter(
                    (k) =>
                        k.type == 'text' &&
                        k.parent == '__root__' &&
                        quark.enlist(k).some((j) => j.type === 'cluster')
                )
                if (notes_with_relationships.length >= 3) {
                    quark.milestone('ADDITIONAL_NOTES_WITH_RELATIONSHIP')
                }
            }
        } else if (line.type === 'search' && text.length > 2) {
            quark.milestone('TYPE_SEARCH', true)
        }

        var ref = line.id

        text.split('\n').forEach((text_line, i) => {
            if (i > 0) {
                if (line.id.startsWith('fake-')) return

                var child = quark.create_child(parent, ref)
                quark.set_text(parent, quark.get(child), text_line)

                ref = child
                quark.checkpoint()
                // console.log(path, child)
                return
            }

            quark.set_text(parent, line, text_line)
            if (quark.check_path(path.concat([line]))) {
                quark.checkpoint()
            } else {
                console.warn('path sustainability invariant violation')
                quark.stash()
            }
        })

        if (ref != line.id) {
            this.blur()
            this.setFocus(ref)
        } else {
            this.setFocus(null)
        }

        globalUpdate()
    }

    render_children(children) {
        var { line, path } = this.props
        console.assert(children)
        var default_expanded = children.length == 1 && children[0].type == 'edge'
        // cursor={this.props.cursor.select('children', k.id)}
        let childEls = children.map((k, i) => {
            return (
                <Lombax
                    ref={'child-' + i}
                    index={i}
                    parent={this}
                    default_expanded={default_expanded}
                    line={k}
                    path={path.concat([line])}
                    key={k.id}
                />
            )
        })

        // console.log('thing', line, children, path)
        let realLine = line.type === 'root' ? quark.get(line.ref) : line

        if (realLine && realLine.parent === '__root__') {
            childEls.push(<Autoimport path={path} line={realLine} key="autoimport" />)
        }

        return childEls
    }

    render_error(err) {
        return (
            <div className="node">
                <span style={{ background: 'red', color: 'white', fontFamily: 'monospace' }}>
                    {err.toString()}
                </span>
            </div>
        )
    }

    render_node() {
        var { line, path } = this.props
        try {
            var text = quark.get_text(line)
        } catch (err) {
            console.warn('Error in Lombax::render_node', line, err)
            return this.render_error(err)
        }

        return (
            <div
                className={
                    'node ' + 'node-' + line.type + ' ' + (this.isExpand() ? 'expanded' : '')
                }
                onClick={(e) => {
                    let target: HTMLElement = e.target as any
                    if (target.classList.contains('cm-hashlink')) {
                        e.preventDefault()
                        let cleanTitle = target.textContent.trim().replace(/^\[\[(.*)\]\]$/, '$1')
                        quark.set_root_text(quark.tok(quark.fid(cleanTitle)))
                        this.setFocus('__root__')
                        globalUpdate()
                    } else if (target.classList.contains('cm-hashtag')) {
                        e.preventDefault()
                        let cleanTitle = target.textContent
                            .trim()
                            .replace(/^#(.*)$/, '$1')
                            .replace(/_/, ' ')
                        quark.set_root_text(quark.tok(quark.fid(cleanTitle)))
                        this.setFocus('__root__')
                        globalUpdate()
                    } else if (target.classList.contains('cm-url')) {
                        e.preventDefault()
                        open(target.textContent)
                    }
                }}
            >
                <TextEditor
                    ref="input"
                    lazy={this.props.index > 1}
                    line={line}
                    path={path}
                    keyMap={this.keyMap}
                    onChange={this.onChange.bind(this)}
                    text={text}
                    toggleCollapse={() => this.toggleCollapse()}
                />
            </div>
        )

        // {(line.type == 'text' && !expanded && children.length > 0
        //   && children[0].type == 'text' ) ?
        //     <div className="node-notes">{
        //         children[0].text
        //     }</div> : null}
    }

    dragStart(e) {
        var { line, path } = this.props
        if (this.isHover()) {
            e.dataTransfer.effectAllowed = 'move'
            var img = new Image()
            img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs='
            e.dataTransfer.setDragImage(img, 10, 10)

            draggedObject = line.id
            try {
                e.dataTransfer.setData('text/html', null)
            } catch (ex) {
                e.dataTransfer.setData('text', '')
            }
            e.stopPropagation()
        } else {
            e.preventDefault()
        }
    }

    dragEnd(e) {
        draggedObject = null
        globalUpdate()
    }

    dragOver(e) {
        e.preventDefault()
        e.stopPropagation()

        var over = e.currentTarget
        // var relX = e.clientX - over.getBoundingClientRect().left;
        var relY = e.clientY - over.getBoundingClientRect().top
        var height = over.offsetHeight / 2
        var placement = relY > height

        var { line, path } = this.props
        var parent = path[path.length - 1]
        var id = e.dataTransfer.getData('text')

        if (parent && draggedObject && draggedObject != line.id) {
            quark.sort_children(parent, draggedObject, line.id, placement)
            if (quark.dirty()) {
                quark.checkpoint()
                globalUpdate()
            }
        }
    }

    canFocus() {
        var { line, path } = this.props
        return ['cluster', 'edge', 'text', 'parent'].includes(line.type) || line.id === '__root__'
    }

    render() {
        var { line, path } = this.props

        if (line.type == 'inline_image') {
            return <img src={line.url} style={{ marginLeft: 20 }} />
        } else if (line.type == 'academy') {
            return (
                <button
                    onClick={(e) => {
                        quark.open_academy()
                    }}
                    className="academy_button"
                >
                    🎓 Open Hypernote Academy
                </button>
            )
        } else if (line.type == 'checkbox') {
            // let isNativeDark = window.injectedState && window.injectedState.theme === 'Dark'

            return (
                <div className="node-wrapper">
                    <input
                        type="checkbox"
                        id="tugg"
                        className="node-bullet"
                        defaultChecked={!!localStorage.dark}
                        style={{
                            marginLeft: 4,
                            marginRight: 5,
                        }}
                        onChange={(e) => {
                            localStorage.dark = localStorage.dark ? '' : 'yes'
                            document.body.classList.toggle('dark-theme', !!localStorage.dark)
                        }}
                    />
                    <label htmlFor="tugg">
                        <b>Dark Mode</b>
                    </label>
                </div>
            )
        } else if (line.type == 'export') {
            return <ExportWidget />
        } else if (line.type == 'file') {
            return (
                <div className="node-wrapper">
                    <div className="node-self">
                        <div className="node-bullet" />
                        <div
                            className="attachment"
                            onClick={(e) => {
                                quark.store.storage
                                    .child(line.id)
                                    .getDownloadURL()
                                    .then((url) => (location.href = url))
                                    .catch((err) => {
                                        alert(err)
                                    })
                            }}
                        >
                            <div style={{ width: 40 }}>
                                {line.uploading ? (
                                    <BareSpinner size={15} borderTopColor="#CB5C5C" />
                                ) : (
                                    <svg
                                        width="20px"
                                        height="20px"
                                        viewBox="0 0 20 20"
                                        style={{
                                            transform: 'rotate(-45deg)',
                                            opacity: 0.3,
                                            height: 12,
                                        }}
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M15,3 L7,3 C3.13400675,3 0,6.13400675 0,10 C0,13.8659932 3.13400675,17 7,17 L15,17 L15,15 L9.44949137,15 L7,15 C4.23857625,15 2,12.7614237 2,10 C2,7.23857625 4.23857625,5 7,5 L9.44949137,5 L12.9998169,5 L15,5 C16.6568542,5 18,6.34314575 18,8 C18,9.65685425 16.6568542,11 15,11 L12.9998169,11 L8.41464715,11 L7,11 C6.44771525,11 6,10.5522847 6,10 C6,9.44771525 6.44771525,9 7,9 L8.41464715,9 L15,9 L15,7 L7,7 C5.34314575,7 4,8.34314575 4,10 C4,11.6568542 5.34314575,13 7,13 L15,13 C17.7614237,13 20,10.7614237 20,8 C20,5.23857625 17.7614237,3 15,3 Z"
                                            id="Combined-Shape"
                                        ></path>
                                    </svg>
                                )}
                            </div>
                            {line.name}
                        </div>
                    </div>
                </div>
            )
        } else if (line.type == 'error') {
            return (
                <div className="node-wrapper">
                    <div className="node-self">
                        <div className="node-bullet" />
                        {this.render_error('Error: ' + line.text)}
                    </div>
                </div>
            )
        } else if (line.type == 'import') {
            return <ImportWidget />
        } else if (line.type == 'raw') {
            return (
                <div className="node-wrapper">
                    <div className="node-self">
                        <div className="node-bullet" />
                        {line.text}
                    </div>
                </div>
            )
        } else if (line.type === 'acl-add') {
            return (
                <div className="node-wrapper">
                    <div className="node-self">
                        <div
                            className="glyphicon glyphicon-plus"
                            style={{
                                marginRight: 13,
                                marginLeft: -9,
                                marginTop: 4,
                            }}
                        />
                        <div className="node">
                            <ShareWidget />
                        </div>
                    </div>
                </div>
            )
        } else if (line.type === 'acl-line') {
            let isMe = quark.store.userInfo && quark.store.userInfo.email === line.email
            return (
                <div className="node-wrapper">
                    <div className="node-self">
                        <div className="node-bullet" />
                        <div className="node">
                            {line.email === 'PUBLIC' ? (
                                <i>Public Access</i>
                            ) : (
                                <SimpleHighlight text={line.email} />
                            )}
                            {isMe ? <i> (you)</i> : ''}{' '}
                            <select
                                value={Math.min(3, line.accessLevel)}
                                disabled={!quark.store.canUpdateACL}
                                onChange={(e) => {
                                    if (isMe) {
                                        if (
                                            !confirm(
                                                'Are you sure you want to change your own access level? You may permanently lose access to this notebook.'
                                            )
                                        ) {
                                            return
                                        }
                                    }
                                    quark.store.updateAccessLevel(
                                        line.email,
                                        parseInt(e.target.value)
                                    )
                                }}
                            >
                                <option value={0}>No Access</option>
                                <option value={1}>Can Read</option>
                                <option value={2}>Can Edit</option>
                                {line.email !== 'PUBLIC' && <option value={3}>Owner</option>}
                            </select>
                            {quark.store.canUpdateACL &&
                                line.email !== 'PUBLIC' &&
                                line.accessLevel === 0 && (
                                    <button
                                        style={{
                                            border: 0,
                                            cursor: 'pointer',
                                        }}
                                        onClick={(e) => {
                                            quark.store.updateAccessLevel(line.email, null)
                                        }}
                                    >
                                        &times;
                                    </button>
                                )}
                        </div>
                    </div>
                    {line.email === 'PUBLIC' && line.accessLevel > 0 && (
                        <div className="node-children">
                            <div className="node-wrapper">
                                <div className="node-self">
                                    <div
                                        className="glyphicon glyphicon-link"
                                        style={{
                                            marginRight: 13,
                                            marginLeft: -9,
                                            marginTop: 4,
                                        }}
                                    />
                                    <div className="node">
                                        <input
                                            style={{
                                                font: 'inherit',
                                                padding: '1px 5px',
                                                width: 300,
                                            }}
                                            readOnly
                                            value={
                                                location.protocol +
                                                '//' +
                                                location.host +
                                                '/' +
                                                quark.store.path
                                            }
                                            onFocus={(e) => {
                                                e.target.select()
                                                // document.execCommand("copy");
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )
        } else if (line.type == 'settings-text') {
            return (
                <div className="node-wrapper">
                    <div className="node-self">
                        <div className="node-bullet" />
                        <div className="node">
                            <SimpleHighlight text={line.text} />
                        </div>
                    </div>
                    <div className="node-children">
                        {this.render_children(line.inlineChildren || [])}
                    </div>
                </div>
            )
        } else if (line.type == 'download-mac') {
            return <MacAppDownloadWidget />
        } else if (line.type == 'upgrade-body') {
            // return <PaymentForm />
        } else if (line.type === 'login-body') {
            return <LoginPage />
        }

        var children = []

        try {
            children = quark.get_children(line)
        } catch (err) {
            console.warn(err)
            children = [
                {
                    id: 'child-error',
                    type: 'error',
                    message: err.mesage,
                },
            ]
        }

        var is_root = line.id == '__root__',
            has_children = children.length > 0,
            expanded = is_root || this.isExpand(),
            hover = this.isHover(),
            loop = this.loop(),
            dragging = draggedObject == line.id,
            boring

        if (is_root && line.type === 'path' && children.length > 1) {
            quark.milestone('FIND_PATH')
        }

        // TODO: figure out a better visual style to convey boringness

        if (line.type == 'cluster' && children.length == 1) {
            boring =
                quark.is_empty(children[0].ref, line) ||
                path.some((k) => quark.line_is_equal(k, quark.get(children[0].ref)))
        } else if (line.type == 'edge') {
            boring = quark.is_empty(line.ref, line)
        }
        // classname helper
        function CN(...args) {
            return args.filter((x) => x).join(' ')
        }

        var connectDragSource = (k) =>
                React.cloneElement(k, {
                    draggable: true,
                    onDragStart: this.dragStart.bind(this),
                }),
            connectDropTarget = (k) =>
                React.cloneElement(k, {
                    onDragEnd: this.dragEnd.bind(this),
                    onDragOver: this.dragOver.bind(this),
                })

        if (is_root) {
            connectDropTarget = (k) => k
        }

        return connectDropTarget(
            <div
                className={CN('node-wrapper', is_root && 'current-root', dragging && 'is-dragging')}
            >
                <div className="node-self">
                    {connectDragSource(
                        <div
                            className={CN(
                                'node-bullet',
                                has_children && !boring && !expanded && 'parent',
                                !dragging &&
                                    !loop &&
                                    expanded &&
                                    has_children &&
                                    hover &&
                                    'collapse',
                                !dragging &&
                                    !loop &&
                                    !expanded &&
                                    has_children &&
                                    hover &&
                                    'expand',
                                loop && 'loop',
                                boring && 'boring'
                            )}
                            title={line.created ? moment(line.created).fromNow() : 'no date'}
                            onClick={this.toggleCollapse.bind(this)}
                            onMouseEnter={this.bulletEnter.bind(this)}
                            onMouseLeave={this.bulletLeave.bind(this)}
                        />
                    )}
                    {this.render_node()}
                </div>
                <div className="node-children">
                    {expanded ? this.render_children(children) : null}
                </div>
            </div>
        )
    }
}

class ShareWidget extends React.Component {
    state = {
        text: '',
    }
    render() {
        let isValid = /\S+@\S+\.\S+/.test(this.state.text)

        return (
            <form
                onSubmit={(e) => {
                    e.preventDefault()
                    if (isValid) {
                        quark.store.updateAccessLevel(this.state.text, 1)
                        this.setState({ text: '' })
                    }
                }}
            >
                <input
                    type="email"
                    value={this.state.text}
                    onChange={(e) => this.setState({ text: e.target.value })}
                    placeholder="Enter an email address"
                    style={{
                        padding: '4px 7px',
                        width: 200,
                        marginRight: 5,
                    }}
                />

                {isValid && <button type="submit">Share</button>}
            </form>
        )
    }
}

function MacAppDownloadWidget() {
    // const [timeLeft, setTimeLeft] = useState(5)
    // const [startedDownload, setStartedDownload] = useState(false)

    // useEffect(() => {
    //     if (timeLeft) setTimeout(() => setTimeLeft(timeLeft - 1), 1000)
    //     else if (!startedDownload) {
    //         setStartedDownload(true)
    //         console.log('downloading')
    //     }
    // })

    return (
        <>
            <div className="node-wrapper">
                <div className="node-self">
                    <div className="node-bullet" />
                    <a
                        href="/static/Hypernote 2019 08 08.zip"
                        download
                        className="node"
                        style={{
                            display: 'inline-flex',
                            color: 'inherit',
                            textDecoration: 'none',
                            border: '1px solid #ddd',
                            padding: 10,
                            borderRadius: 5,
                        }}
                    >
                        <MacDownloadSVG />
                        <div style={{ marginLeft: 10 }}>
                            <b>Hypernote.app</b>
                            <br />
                            Requires MacOS 10.12 or later.
                            <br />
                            <div style={{ color: '#2b4cff', textDecoration: 'underline' }}>
                                Download Now (7.1MB)
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <div className="node-wrapper">
                <div className="node-self">
                    <div className="node-bullet" />
                    <div className="node">Features</div>
                </div>
                <div className="node-children">
                    <div className="node-wrapper">
                        <div className="node-self">
                            <div className="node-bullet" />
                            <div className="node">Global show/hide hotkey</div>
                        </div>
                    </div>
                    <div className="node-wrapper">
                        <div className="node-self">
                            <div className="node-bullet" />
                            <div className="node">Dedicated tray icon</div>
                        </div>
                    </div>
                </div>
            </div>
            ,
        </>
    )
}

function ExportWidget() {
    var name = 'hypernote-export-' + new Date().toISOString().slice(0, 10) + '.txt'

    let text = quark.core.serialize()
    return (
        <div className="node-wrapper">
            <div className="node-self">
                <div
                    className="glyphicon glyphicon-download"
                    style={{
                        marginRight: 17,
                        marginLeft: -10,
                        marginTop: 4,
                    }}
                />
                <div className="node">
                    <b>Export Data: </b>
                    <a href={URL.createObjectURL(new Blob([text]))} download={name}>
                        {name}
                    </a>
                </div>
            </div>
        </div>
    )
}

function ImportWidget() {
    return (
        <div className="node-wrapper">
            <div className="node-self">
                <div
                    className="glyphicon glyphicon-upload"
                    style={{
                        marginRight: 17,
                        marginLeft: -10,
                        marginTop: 4,
                    }}
                />
                <div className="node">
                    <b>Import Data: </b>
                    <input
                        type="file"
                        onChange={(e) => {
                            var fr = new FileReader()
                            fr.onload = function() {
                                quark.core.restore(fr.result)
                            }
                            fr.readAsText(e.target.files[0])
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

function SimpleHighlight(props) {
    var tokens = [],
        text = props.text,
        m

    while (text.length > 0) {
        if ((m = /^.*?\=/.exec(text))) {
            tokens.push(<b key={m[0]}>{m[0]}</b>)
            text = text.slice(m[0].length)
        } else if ((m = /^http:\/\/.*/.exec(text))) {
            tokens.push(
                <a key={m[0]} target="_blank" href={m[0]}>
                    {m[0]}
                </a>
            )
            text = text.slice(m[0].length)
        } else {
            tokens.push(text[0])
            text = text.slice(1)
        }
    }

    return <span>{tokens}</span>
}
