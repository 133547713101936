import * as _ from 'lodash'
import moment from 'moment'

import * as core from './core'
import { enlist, get, set, tok, update, untok, textok, fid, store } from './core'

export {
    enlist,
    get,
    fid,
    find_name,
    root,
    store,
    fast_get,
    tok,
    textok,
    untok,
    dirty,
    checkpoint,
    stash,
    remove_refs,
    line_is_equal,
    string_reverse,
    remove_entity,
    detach_entity,
    insert_relative,
    check_edge,
    del_biedge,
    add_biedge,
    check_path,
} from './core'

export { milestone, hasCompletedMilestone, suppressMilestones } from './tutorial'

export { core }

import * as graph from './graph'
export { graph }
export { reachable, edges, type_graph } from './graph'

import * as search from './search'

import * as predicate from './predicates/predicate'
export { predicate }
export {
    invert_predicate,
    canonicalize_pair,
    canonicalize_predicate,
    clean_predicate,
} from './predicates/predicate'

import { globalUpdate } from '../Aphelion'

export const EDGE_AUTOCOLON = false

export const SpecialPages = {
    recent: 'Recently Added',
    settings: 'Settings',
    upgrade: 'Upgrade',
    login: 'Log In',
    graph: 'Graph View',
    help: 'Help',
    map: 'Map View',
    calendar: 'Calendar View',
    mac: 'Download Mac App',
}

function parse_edge(k) {
    // TODO: make a cleaner version of this function
    k = k.replace(/[,]/g, '')
    var rem = k.match(/^(.*)<ref-(.*?)>(.*)/)
    if (rem) {
        var ent = rem[2],
            pre = rem[1].replace(/[^\s]/g, ''),
            com = rem[3]
        if (com.trim() != '' && com.trim()[0] != '(') {
            if (com.trim() == ')') {
                com = ''
            } else {
                com = ' (' + com.replace(/\s*\(/, '').trim()
                if (!com.trim().endsWith(')')) {
                    com = com.replace(')', '') + ')'
                }
            }
        }
        return {
            ref: ent,
            pre: pre,
            type: 'edge',
            comment: com,
        }
    }
    if (k.indexOf('<ref-') != -1) {
        console.warn('ref tag found but not real ref!', k)
    }
    var ind = k.search(/\s*\(/)
    if (ind < 0) ind = k.search(/\s*$/)
    console.assert(ind >= 0)
    var ent = k.slice(0, ind),
        com = k.slice(ind),
        pre = ent.match(/^\s*/)[0],
        empty = ent.trim() == ''
    return {
        comment: empty ? '' : com,
        ref: fid(ent.trim()),
        pre: empty ? com : pre,
        type: 'edge',
    }
}

function tokenize_refs(text) {
    var tokens = []
    var re = /(.*?)<ref-(TEXT-)?(.*?)>/g,
        match,
        lastIndex = 0
    while ((match = re.exec(text))) {
        if (match[1].trim()) tokens.push(match[1].trim())
        tokens.push({ type: 'ref', ref: match[3] })
        lastIndex = re.lastIndex
    }
    if (text.slice(lastIndex).trim()) tokens.push(text.slice(lastIndex).trim())
    return tokens
}

function slugify(text) {
    return text
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w\-]/g, '')
}

var no_history: boolean = false

function set_root(obj) {
    global.$$root$$ = _.assign({ id: '__root__' }, obj)

    const base = '/' + store.path

    if (obj.type == 'root') {
        var thing = get(obj.ref)
        var title = thing ? thing.text : 'Not Found'
        document.title = title + ' - Hypernote'
        if (!no_history) {
            history.pushState({}, title, base + '/' + slugify(title) + '-' + obj.ref)
        }
    } else if (obj.type in SpecialPages) {
        // document.title = title
        if (!no_history) {
            history.pushState({}, title, base + '/' + obj.type)
        }
    } else if (location.pathname.match(/\/[^\/]+\/[^\/]+/)) {
        if (!no_history) {
            history.pushState({}, '', base)
        }

        document.title = 'Hypernote'
    }
}

export function popState() {
    let ref = location.pathname.match(/\/[^\/]+\/(.*\-)?(\w+)$/)
    if (ref) {
        no_history = true
        if (ref[2] in SpecialPages) {
            set_root_text(tok(SpecialPages[ref[2]]))
        } else {
            // quark.set_text(undefined, $$root$$, tok(ref[2]))
            set_root_text(tok(ref[2]))
        }

        no_history = false
    } else {
        set_root_text('')
    }
}

window.onpopstate = function(event) {
    popState()
    globalUpdate()
}

export function set_root_text(raw_text) {
    var tokens = tokenize_refs(raw_text),
        first = tokens[0]

    if (first && first.type == 'ref') {
        // inverse special pages lookup
        let specialPageType = _.findKey(SpecialPages, (k) => k === first.ref)
        // console.log(SpecialPages, specialPageType, first.ref)
        if (specialPageType) {
            set_root({ type: specialPageType })
        } else if (tokens[1]) {
            var path_match = tokens[1].match(/^(\-+)\>/)
            if (path_match) {
                set_root({
                    type: 'path',
                    text: raw_text,
                    indirection: path_match[1].length,
                    from: tokens[0].ref,
                    to: tokens[2] && tokens[2].ref,
                })
            } else if (tokens[1].match(/^\&/)) {
                set_root({
                    type: 'intersect',
                    text: raw_text,
                    A: tokens[0].ref,
                    B: tokens[2] && tokens[2].ref,
                })
            } else {
                set_root({ type: '404', text: raw_text })
            }
        } else {
            set_root({ type: 'root', text: raw_text, ref: first.ref })
        }
    } else {
        set_root({ type: 'search', text: raw_text })
    }
}

export function set_text(parent, line, raw_text) {
    if (parent === undefined) {
        return set_root_text(raw_text)
        // debugger
    } else if (!store.canEdit) {
        return
    }

    if (
        (parent.type == 'edge' ||
            parent.type == 'path-edge' ||
            parent.type == 'root' ||
            parent.type == 'text' ||
            parent.type == 'parent' ||
            (parent.type == 'cluster' && parent.list.length == 1 && parent.predicate == '')) &&
        line.type != 'parent'
    ) {
        var pid =
            parent.type == 'text'
                ? parent.id
                : parent.type == 'cluster'
                ? enlist(parent)[0].ref
                : parent.ref
        const remove_cluster = () => {
            if (line.type == 'cluster') {
                core.update_cluster(_.assign(_.clone(line), { predicate: '', parent: pid }), [])
            }
        }

        // TODO: the alias system isn't actually particularly
        // useful and we should just have syntax highlighting
        // for text nodes which accomplishes almost the same thing
        // so we should get rid of aliases, right?

        // var aliexp = raw_text.match(/(^[^:=]{0,25}=\s*)([\s\S]*)/);
        // if(aliexp){
        //     remove_cluster()
        //     update(line.id, {
        //         type: 'alias',
        //         prefix: aliexp[1],
        //         alias: aliexp[2]
        //     })
        // }else

        const has_predicate =
            !raw_text.match(/^(.*?)(\b\w+:\/\/[^\s]*[^\s,])/) &&
            raw_text.match(/^([^=:]{0,24}[^\\\d=])?:/)

        if (has_predicate) {
            if (line.type == 'text' && enlist(line).length > 0) {
                update(line.id, {
                    type: 'text',
                    text: raw_text.replace(/:/g, ''),
                    parent: pid,
                })
                return
                // var target = get(enlist(new_line)[0].ref)
                // console.assert(target)
                // enlist(line).forEach((k) => {
                //     core.insert_relative(target, null, k)
                //     // regenerate the cluster so that both sides see
                //     // the new actual parent
                //     if (k.type == 'cluster') {
                //         core.update_cluster(get(k.id), enlist(k))
                //     }
                // })
            }

            const label = has_predicate[0].slice(0, -1)

            var new_edges = [],
                injected = []

            raw_text
                .slice(label.length + 1)
                .split(',')
                .forEach((k) => {
                    var rem = k.match(/\s*<ref-(.*)/)
                    if (rem && rem.index > 0) {
                        if (
                            !rem[1].startsWith('TRUNC') && // cant auto-insert before truncation line
                            label.trim() != ''
                        ) {
                            // cant auto-insert if no predicate

                            injected.push(new_edges.length)
                            new_edges.push(parse_edge(k.slice(0, rem.index)))
                        }
                        k = k.slice(rem.index)
                    }
                    var trunc = k.match(/^\s*<ref-TRUNC-(.*?)>/)
                    if (trunc) {
                        // TODO: don't inject before truncation point
                        trunc[1].split('+').forEach((e) => new_edges.push(get(e)))
                    } else {
                        new_edges.push(parse_edge(k))
                    }
                })
            injected.forEach((k) => {
                if (new_edges[k + 1] && new_edges[k + 1].pre == '') {
                    new_edges[k + 1].pre = ' '
                }
            })
            // var new_edges = seq.join(':').split(',')
            //     .map((k, i) => parse_edge(k))

            // when transitioning from unlabeled edge
            // into a labeled edge, add a prefixing space
            // for aesthetics
            if (
                line.type == 'cluster' &&
                line.predicate == '' &&
                label.trim() != '' &&
                new_edges[0].pre == ''
            ) {
                new_edges[0].pre = ' '
            }

            core.update_cluster(
                _.assign(_.clone(line), {
                    // if the predicate appearance doesn't actually change, preserve
                    // the original representation
                    predicate:
                        predicate.clean_predicate(line) == label ? line.predicate || '' : label,
                    parent: pid,
                }),
                new_edges
            )

            // var new_line = get(line.id)

            // when transitioning from a text node with some children
            // to some sort of graph link connecting to just one node
            // transport the previous children so that it's not lost
            // in the aether
            // if (
            //     line.type == 'text' &&
            //     enlist(line).length > 0 &&
            //     new_line.type == 'cluster' &&
            //     enlist(new_line).length == 1
            // ) {
            //     var target = get(enlist(new_line)[0].ref)
            //     console.assert(target)
            //     enlist(line).forEach((k) => {
            //         core.insert_relative(target, null, k)
            //         // regenerate the cluster so that both sides see
            //         // the new actual parent
            //         if (k.type == 'cluster') {
            //             core.update_cluster(get(k.id), enlist(k))
            //         }
            //     })
            // }
        } else {
            remove_cluster()

            update(line.id, {
                type: 'text',
                text: raw_text,
                parent: pid,
            })

            const parse_hashtags = (text) => {
                var hashtags = []
                    // (text || '').replace(/\[([^\]\[]+?)\]/g,
                    //     (all, hashtag) => hashtags.push(hashtag))
                ;(text || '').replace(/#([\w-_]+)/g, (all, hashtag) =>
                    hashtags.push(hashtag.replace(/_/g, ' '))
                )
                ;(text || '').replace(/\[\[([^\]]+)\]\]/g, (all, hashtag) => hashtags.push(hashtag))
                return hashtags
            }
            // console.log(parse_hashtags(line.text), parse_hashtags(raw_text))
            _.difference(parse_hashtags(line.text), parse_hashtags(raw_text)).forEach((k) => {
                if (core.find_name(k)) {
                    core.del_biedge(line.id, '', fid(k))
                }
            })

            parse_hashtags(raw_text).forEach((k) => {
                if (!core.check_edge(line.id, null, fid(k))) {
                    core.add_biedge(line.id, '', fid(k))
                }
            })
        }
    } else if (parent.type == 'cluster') {
        if (raw_text[0] == ':' && EDGE_AUTOCOLON) {
            core.update_cluster(
                parent,
                enlist(parent).map((k) =>
                    k.id == line.id
                        ? _.assign(parse_edge(raw_text.slice(1)), {
                              id: k.id,
                              parent: parent.id,
                              pre: line.pre, // use the previous value for prefixed whitespace
                          })
                        : k
                )
            )
        } else if (!EDGE_AUTOCOLON) {
            core.update_cluster(
                parent,
                enlist(parent).map((k) =>
                    k.id == line.id
                        ? _.assign(parse_edge(raw_text), {
                              id: k.id,
                              parent: parent.id,
                              pre: line.pre, // use the previous value for prefixed whitespace
                          })
                        : k
                )
            )
        }
    } else if (!line.id.startsWith('fake-')) {
        console.warn('set_text handler not found', parent, line, raw_text)
    }
}

export function get_text(line) {
    if (line.type == 'root') {
        return line.text
    } else if (line.type == 'edge') {
        if (EDGE_AUTOCOLON) {
            return ':' + tok(line.ref) + (line.comment || '')
        } else {
            return tok(line.ref) + (line.comment || '')
        }
    } else if (line.type == 'text') {
        return line.text
    } else if (line.type == 'cluster') {
        var CUTOFF = 25
        line.list = line.list || []
        if (line.list.length < CUTOFF + 5) {
            return (
                predicate.clean_predicate(line) +
                ':' +
                enlist(line)
                    .map(
                        (k) => (line.predicate ? k.pre || '' : '') + tok(k.ref) + (k.comment || '')
                    )
                    .join(',')
            )
        } else {
            return (
                predicate.clean_predicate(line) +
                ':' +
                line.list
                    .slice(0, CUTOFF)
                    .map((e) => get(e))
                    .map(
                        (k) => (line.predicate ? k.pre || '' : '') + tok(k.ref) + (k.comment || '')
                    )
                    .concat([' <ref-TRUNC-' + line.list.slice(CUTOFF).join('+') + '>'])
                    .join(',')
            )
        }
    } else if (line.type == 'alias') {
        console.warn('ALIASES ARE DEPRECATED ', line.id)
        return line.prefix + line.alias
    } else if (line.type == 'url') {
        return line.url
    } else if (line.type == 'parent') {
        return 'Parent: ' + tok(line.ref)
    } else if (line.type == 'raw') {
        return line.text || ''
    } else if (line.type == 'search') {
        return line.text
    } else if (line.type == 'path') {
        return line.text
    } else if (line.type == 'path-edge') {
        return line.text
    } else if (line.type in SpecialPages) {
        return textok(SpecialPages[line.type])
    } else if (line.type == 'intersect') {
        return line.text
    } else if (line.type == '404') {
        return line.text
    } else {
        console.warn('get_text handler not found', line)
        throw new Error('no get_text handler for node type "' + line.type + '"')
    }
}

export function is_address(obj) {
    return (
        obj.type === 'text' &&
        /.{0,10}(Address|Location|Headquarters)\s*=/i.test(obj.text) &&
        obj.parent !== '__root__'
    )
}

export function get_children(parent: Entity): Entity[] {
    if (!parent) {
        return []
    } else if (parent.type == 'edge' || parent.type == 'path-edge') {
        console.assert(parent.ref != parent.id)
        if (parent.ref == parent.id) return []
        var node = get(parent.ref)
        if (node && node.type == 'text' && node.parent != '__root__') {
            return [{ type: 'parent', id: 'fake-parent', ref: node.parent }, ...get_children(node)]
        }
        return get_children(node)
    } else if (parent.type == 'root') {
        console.assert(parent.ref != parent.id)
        if (parent.ref == parent.id) return []
        return get_children(get(parent.ref))
    } else if (parent.type == 'text') {
        let children = enlist(parent)
        let address = parent.text
            .split('=')
            .slice(1)
            .join('=')

        if (is_address(parent) && parent.geocode && parent.geocode.address === address) {
            children.unshift({
                id: 'fake-streetview',
                type: 'inline_image',
                url: `https://maps.googleapis.com/maps/api/streetview?size=500x200&location=${parent.geocode.lat},${parent.geocode.lng}&key=${CONFIG.mapsKey}`,
            })
        }

        return children
    } else if (parent.type == 'cluster') {
        if (!parent.list || parent.list.length == 0) return []

        // console.assert(parent.list.length > 0)
        if (parent.list.length == 1 && parent.predicate == '') {
            return get_children(get(parent.list[0]))
        } else {
            return enlist(parent)
        }
    } else if (parent.type == 'url') {
        return [
            {
                id: 'fake-image',
                type: 'inline_image',
                url: parent.url,
            },
        ]
    } else if (parent.type == 'alias') {
        return []
    } else if (parent.type == 'parent') {
        return get_children(get(parent.ref))
    } else if (parent.type == 'search') {
        return search.search_auto(parent.text)
    } else if (parent.type == 'raw') {
        return []
    } else if (parent.type == 'path') {
        var path = graph.find_path(get(parent.from), get(parent.to), parent.indirection)
        if (!path) return [{ type: 'raw', text: 'no path found', id: 'fake' }]
        return [
            {
                type: 'path-edge',
                text: ':' + tok(parent.from),
                ref: parent.from,
                id: 'fake-start',
            },
        ].concat(
            path.map((k, i) => {
                var c = get(k.parent)
                var prefix = c.predicate ? predicate.clean_predicate(c) + ': ' : ':'
                return {
                    type: 'path-edge',
                    id: 'fake-' + i,
                    text: prefix + tok(k.ref),
                    ref: k.ref,
                }
            })
        )
    } else if (parent.type == 'mac') {
        return [
            {
                type: 'download-mac',
                id: 'download-mac',
            },
        ]
    } else if (parent.type == 'help') {
        return [
            in_academy() ? null : { id: 'fake-academy', type: 'academy' },
            {
                text: 'Keyboard Shortcuts',
                type: 'settings-text',
                id: 'key-commands',
                inlineChildren: [
                    'Cmd-Enter = jumps to the page for the thing under your text cursor',
                    "Cmd-Down = expands the bullet you're on",
                    "Cmd-Up = collapses the bullet you're on",
                    'Cmd-/ = mark line as completed',
                    'Tab = makes subbullets / indents',
                    'Shift-Tab = unindents',
                    'Cmd-E = Focus the title bar',
                    'Cmd-D = New scratch pad',
                ].map((t, i) => ({ text: t, type: 'settings-text', id: 'x' + i })),
            },
            {
                text: 'Editing Syntax',
                type: 'settings-text',
                id: 'key-syntax',
                inlineChildren: ['Create an [[Inline Link]]', 'This is a #hashtag'].map((t, i) => ({
                    text: t,
                    type: 'settings-text',
                    id: 'x' + i,
                })),
            },
        ].filter((k) => k)
    } else if (parent.type == 'recent') {
        return _.sortBy(
            _.values(global.entities).filter((k) => k.parent == '__root__' && k.type == 'text'),
            (k) => -k.created
        )
            .slice(0, 100)
            .map((k, i) => ({
                type: 'edge',
                id: 'fake-' + i,
                ref: k.id,
            }))
    } else if (parent.type === 'login') {
        return [{ id: 'fake-login', type: 'login-body' }]
    } else if (parent.type == 'settings') {
        var total_entities = 0,
            root_nodes = 0,
            text_subnodes = 0,
            oldest_entity = Infinity
        for (var i in global.entities) {
            var ent = global.entities[i]
            total_entities++
            if (ent.type == 'text') {
                if (ent.parent == '__root__') {
                    root_nodes++
                } else {
                    text_subnodes++
                }
            }
            if (ent.created && ent.created < oldest_entity) {
                oldest_entity = ent.created
            }
        }
        return [
            {
                text: 'Account =',
                type: 'settings-text',
                id: 'fake-account',
                forceExpand: true,
                inlineChildren: [
                    !store.userInfo && {
                        id: 'fake-thing1',
                        type: 'settings-text',
                        text: 'Not currently logged in.',
                    },
                    store.userInfo && {
                        id: 'fake-thing2',
                        type: 'settings-text',
                        text: 'Email = ' + store.userInfo.email,
                    },
                    store.userInfo && {
                        id: 'fake-thing3',
                        type: 'settings-text',
                        text: 'Registration Date = ' + store.userInfo.metadata.creationTime,
                    },
                    store.userProfile && {
                        id: 'fake-thing4',
                        type: 'settings-text',
                        text: 'Plan = ' + (store.userProfile.plan || 'Free'),
                    },
                ].filter((k) => k),
            },
            {
                text: 'Sharing =',
                type: 'settings-text',
                id: 'fake-acl',
                forceExpand: true,
                inlineChildren: [
                    ..._.sortBy(Object.entries(store.accessList), ([email, accessLevel]) => {
                        if (email === 'PUBLIC') return 0
                        if (store.userInfo && email === store.userInfo.email) return 1
                        return 2
                    }).map(([email, accessLevel]) => ({
                        accessLevel: accessLevel,
                        email: email,
                        id: 'fake-' + email,
                        type: 'acl-line',
                    })),
                    store.canUpdateACL && {
                        id: 'fake-acl-add',
                        type: 'acl-add',
                    },
                ].filter((k) => k),
            },

            {
                text: 'Statistics =',
                type: 'settings-text',
                id: 'fake-stats',
                forceExpand: true,
                inlineChildren: [
                    'Total Entities = ' + total_entities.toLocaleString('us'),
                    'Root Nodes = ' + root_nodes.toLocaleString('us'),
                    'Text Subnodes = ' + text_subnodes.toLocaleString('us'),
                    'Oldest Entity = ' + moment(oldest_entity).fromNow(),
                ].map((k, i) => ({ text: k, id: 'fake-' + i, type: 'settings-text' })),
            },

            { id: 'fake-export', text: 'Export Data', type: 'export' },
            store.canEdit && { id: 'fake-import', text: 'Import Data', type: 'import' },
            { id: 'fake-tugg', type: 'checkbox', text: 'Dark Theme' },
            // store.read_key &&
            //     'Read-Only Share Link = ' + location.origin + '/' + store.read_key,
            // store.edit_key &&
            //     'Full-Access Share Link = ' + location.origin + '/' + store.edit_key,
        ].filter((x) => x)
        // .map((k, i) => ({ text: k, id: 'fake-' + i, type: 'settings-text' }))
        // .concat([

        // ])
    } else if (parent.type == 'upgrade') {
        return [
            {
                id: 'upgrade',
                type: 'upgrade-body',
            },
        ]
    } else if (parent.type == '404') {
        return [{ type: 'raw', text: 'operator not known', id: 'fake-0' }]
    } else if (parent.type == 'intersect') {
        var result = _.intersection.apply(
            _,
            [parent.A, parent.B].map((k) => graph.edges(get(k)).map((e) => e.ref))
        )
        return result.map((k, i) => ({
            type: 'path-edge',
            id: 'fake-' + i,
            text: ':' + tok(k),
            ref: k,
        }))
    } else if (parent.type === 'calendar' || parent.type === 'graph') {
        return []
    } else {
        console.warn('get_children handler not found', parent)
        return [
            {
                type: 'error',
                text: 'no get_children handler for node type "' + parent.type + '"',
                id: 'fake-0',
            },
        ]
    }
}

export function append_text(pid, text) {
    if (text)
        append_child(pid, {
            type: 'text',
            text: text.replace(/\:/g, ''),
        })
}

export function append_child(pid, obj) {
    if (!store.canEdit) return

    var id = core.generate_id()
    var parent = get(pid)
    if (parent && obj) {
        core.insert_relative(parent, null, {
            id,
            ...obj,
        })
    }
    return id
}

export function create_child(parent: Entity, ref = null): string {
    if (!store.canEdit) return

    var id = core.generate_id()
    if (parent.type == 'edge' || parent.type == 'root' || parent.type == 'text') {
        core.insert_relative(parent, ref, {
            type: 'text',
            id,
            text: '',
        })
    } else if (parent.type == 'cluster') {
        if (get(parent.id).list.length == 1 && get(parent.id).predicate == '') {
            return create_child(get(get(parent.id).list[0]), ref)
        } else {
            core.update_cluster(
                parent,
                core.insert_after_ref(parent.list, ref, id).map((k) =>
                    k == id
                        ? {
                              type: 'edge',
                              pre: ' ',
                              ref: '',
                              id,
                              created: Date.now(),
                          }
                        : get(k)
                )
            )
        }
    } else if (parent.type == 'intersect') {
        console.log('creating new intersect')
    } else if (parent.type == 'calendar' || parent.type === 'graph') {
    } else {
        console.warn('create_child handler not found', parent)
    }
    return id
}

export function sort_children(parent, id, ref, append = false) {
    function idonno(p) {
        var plist = get(p).list
        var nlist = sort_array(plist, id, ref, append)
        if (nlist !== plist) update(p, { list: nlist })
    }

    if (parent.type == 'edge' || parent.type == 'root') {
        idonno(parent.ref)
    } else if (parent.type == 'cluster') {
        if (parent.list.length == 1 && parent.predicate == '') {
            sort_children(get(parent.list[0]), id, ref, append)
        } else {
            idonno(parent.id)
        }
    } else if (parent.type == 'text') {
        idonno(parent.id)
    }
}

function sort_array(array, id, ref, append = false) {
    var fro = array.indexOf(id),
        to = array.indexOf(ref)
    // make sure that you can find both id and ref
    if (fro == -1) return array
    if (to == -1) return array
    // twiddle with the appends
    if (append) to++
    if (fro < to) to--
    if (to == fro) return array // nothing changed
    var clone = array.slice(0) // clone the array
    clone.splice(to, 0, clone.splice(fro, 1)[0])
    // console.log(clone, array, id, ref, to, fro)
    return clone
}

export function is_empty(id, line) {
    // var id = core.find_name(name)
    if (!id) return true

    var ref = get(id)
    if (!ref) return true
    var search_list = []
    if (!line) {
    } else if (line.type == 'cluster') {
        search_list = enlist(line).map((k) => k.id)
    } else if (line.type == 'edge') {
        search_list = [line.id]
    }
    var found =
        ref &&
        enlist(ref).some(
            (k) =>
                !(
                    (k.type == 'cluster' &&
                        enlist(k).every(
                            (e) =>
                                search_list.includes(core.string_reverse(e.id)) || e.ref == line.id
                        )) ||
                    (k.type == 'text' && k.parent != '__root__' && k.text.trim() == '')
                )
        )

    return !found
}

export function open_or_create(name) {
    set_text(undefined, core.root(), tok(fid(name)))
    var root = core.root()
    var children = get_children(root)
    if (children.length == 0) {
        global.dofocus = create_child(root)
    } else {
        global.dofocus = children[children.length - 1].id
    }
    core.checkpoint()
    globalUpdate()
}

export function new_scratchpad(name = null) {
    if (!store.canEdit) return

    var SCRATCHPAD = 'Entry'
    // TODO: make a simpler way of generating a thing
    if (!name) {
        name = moment().format('D MMMM YYYY')
    }

    var id = fid(name)
    var scratchies = enlist(get(id)).filter(
        (k) => k.type == 'text' && k.text.startsWith(SCRATCHPAD)
    )
    var last_scratch = scratchies[scratchies.length - 1]
    if (
        last_scratch &&
        enlist(last_scratch).filter((k) => !(k.type == 'text' && k.text.trim() == '')).length == 0
    ) {
        var thing = last_scratch.id
        var root = get(thing)
    } else {
        var thing = create_child(get(id))
        var root = get(thing)
        var next_entry =
            Math.max(
                0,
                _.max(
                    scratchies
                        .map((k) => parseInt(k.text.slice(SCRATCHPAD.length).trim(), 10))
                        .filter((k) => !isNaN(k))
                ) || 0
            ) + 1

        var title = SCRATCHPAD + ' ' + next_entry
        set_text(get(id), root, title)
    }

    set_text(undefined, core.root(), tok(thing))
    core.checkpoint()
    return root
}

export function in_academy() {
    // return !!core.store.getProfile().finishedTutorial
    return core.store && core.store.userProfile && !core.store.getProfile().finishedTutorial
}

export function open_academy() {
    if (!in_academy()) {
        core.store.updateProfile({
            finishedTutorial: false,
            tutorialStep: 0,
        })
    }

    set_text(undefined, core.root(), '')
    globalUpdate()
}

export function send_host(obj) {
    if (window.webkit) {
        window.webkit.messageHandlers.jsHandler.postMessage(JSON.stringify(obj))
    } else {
        console.log('no host available', obj)
    }
}

var didMakeLarge
export function ensure_window_large() {
    if (innerWidth < 700) {
        send_host({
            action: 'setWindowSize',
            width: 1200,
            height: 800,
        })
        didMakeLarge = true
    }
}

export function restore_window_size() {
    if (didMakeLarge) {
        send_host({
            action: 'setWindowSize',
            width: 600,
            height: 400,
        })
        didMakeLarge = false
    }
}
