import _ from 'lodash'
import equivalence_classes from '../lib/equivalence'
import { enlist, get } from './core'
import * as core from './core'
import * as predicate from './predicates/predicate'

var ActiveEntityCounter = {}
// if (!global.ActiveEntityCounter) {
//     global.ActiveEntityCounter = {}
// }

// TODO: delete duplicated edges which share the same predicate

export function collectEntity(id) {
    var ent = get(id)
    // delete empty nodes
    if (
        // root text nodes which have no children
        ent.parent == '__root__' &&
        ent.type == 'text' &&
        enlist(ent).every((k) => k.type == 'text' && k.text.trim() == '')
        // ordinary text nodes which are blank and have no children
        // || (ent.type == 'text' &&
        //     ent.parent != '__root__' &&
        //     enlist(ent).length == 0 &&
        //     ent.text.trim() == '')
    ) {
        // console.log('merp', ent)
        // dispose of the body
        core.remove_entity(ent, true)
    } else if (ent.type == 'text') {
        // merge predicate clusters

        var clusters = enlist(ent).filter((k) => k.type == 'cluster' && k.predicate.trim() != '')

        equivalence_classes(clusters, function(a, b) {
            return predicate.predicate_is_equal(a.predicate, b.predicate)
        })
            .filter((k) => k.length > 1)
            .forEach((k) => {
                // console.log('merge clusters', k)
                var target = k[0]

                // take all the other clusters and merge them into the first one
                k.slice(1).forEach((c) => {
                    enlist(c).forEach((e) => {
                        core.append_line(target.id, e)
                    })
                    core.del(c.id)
                    core.detach_entity(c)
                })
            })
    }
    // TODO: deduplicate when multiple edges to same node in one predicate
}

function doCollection() {
    for (var id in ActiveEntityCounter) {
        if (ActiveEntityCounter[id] == 0) {
            if (get(id)) collectEntity(id)
            delete ActiveEntityCounter[id]
        }
    }
}

// var queuedThingy;
var lastQueuedTimer = 0,
    queuedThingy

export function collectActiveCount() {
    var time = Date.now()
    if (time - lastQueuedTimer > 5) {
        clearTimeout(queuedThingy)
        queuedThingy = setTimeout(doCollection, 10)
        lastQueuedTimer = time
    }
}

export function decrementActiveCount(line) {
    function decrement(id) {
        // console.log('dec', id)
        ActiveEntityCounter[id]--
    }
    decrement(line.id)
    if (line.ref) decrement(line.ref)
}

export function incrementActiveCount(line) {
    function increment(id) {
        // console.log('inc', id)
        // increment the reference counter doohickey
        if (id in ActiveEntityCounter) {
            ActiveEntityCounter[id]++
        } else {
            ActiveEntityCounter[id] = 1
        }
    }
    increment(line.id)
    if (line.ref) increment(line.ref)
}
