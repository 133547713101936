// import {save_to_localstorage} from "./store/tabularasa"
// import {save} from "./store/movies"

// import {save} from "./store/localstorage"
// export * as store from "./store/localstorage"

import { save } from './store/firebase'
import * as store from './store/firebase'
export { store }

// import "./store/import-movies"

// import {save_to_localstorage} from "./store/movies"
// import {save_to_localstorage} from "./dynamo"
import { predicate_is_equal, invert_predicate, clean_predicate } from './predicates/predicate'
import LRUCache from '../lib/lru'
export { save }
import _ from 'lodash'
import * as quark from '../quark'
import { globalUpdate } from '../Aphelion'

export function generate_id() {
    // TODO: add more entropy!
    // TODO: ensure that this id is asymmetric
    return (
        '0' +
        Math.random()
            .toString(36)
            .slice(2, 8)
            .toUpperCase() +
        Date.now()
            .toString(36)
            .toUpperCase()
            .slice(-4)
    )
}

function generate_edgeid() {
    return (
        Math.random()
            .toString(36)
            .slice(2, 8)
            .toUpperCase() +
        Date.now()
            .toString(36)
            .toUpperCase()
            .slice(-4) +
        Math.random()
            .toString(36)
            .slice(2, 8)
            .toUpperCase() +
        '@'
    )
}

var name_map = {}

export function find_name(name) {
    // empty string maps to empty string
    // this is kind of weird
    if (!name || name.trim() == '') return ''

    if (name in name_map && get(name_map[name])) {
        var ent = get(name_map[name])
        if (ent && ent.text == name) {
            return name_map[name]
        } else {
            delete name_map[name]
        }
    }

    // // first check in buffer
    // for(var i in buffer){
    //     var k = buffer[i]
    //     if (k &&
    //         k.text == name &&
    //         k.parent == '__root__'){
    //         return i;
    //     }
    // }
    // // then check in entities
    // for(var i in entities){
    //     var k = entities[i]
    //     if (k.text == name &&
    //         k.parent == '__root__'){
    //         return i;
    //     }
    // }
    return null
}

// find entity ID by name
export function fid(name, id = null) {
    var result = find_name(name)
    // return result if we found anything
    if (result !== null) return result
    // if in doubt create something
    if (!id) id = generate_id()

    set(id, {
        type: 'text',
        parent: '__root__',
        text: name,
        created: Date.now(),
    })
    return id
}

// global.fid = fid

export function get_path(k) {
    var path = [k],
        p = k
    while (p && p.parent != '__root__') {
        p = get(p.parent)
        path.unshift(p)
    }
    return path
}

export function root() {
    // return get('__root__')
    return global.$$root$$
}

var buffer = {}
var edge_map = {}
// global.edge_map = edge_map

export function set(id, obj) {
    if (id == '__root__') {
        debugger
        return
    }
    console.assert(!id.startsWith('fake-'))

    var new_obj = _.assign(_.clone(obj), { id: id })
    console.assert(new_obj.ref != new_obj.id)
    if (new_obj.type == 'edge') console.assert(!new_obj.list)
    buffer[id] = new_obj

    // increment the version of whatever we have in the buffer
    new_obj.v = (new_obj.v || 0) + 1

    postset(id, obj)
}

function postset(id, obj) {
    if (obj) {
        if (obj.type == 'edge') {
            edge_map[obj.parent + '::' + obj.ref] = id
        }
        if (obj.type == 'text' && obj.parent == '__root__') {
            name_map[obj.text] = id
        }
    } else {
    }
}

var listeners = []
export function watch(callback) {
    listeners.push(callback)
}

export function unwatch(callback) {
    listeners = listeners.filter((k) => k !== callback)
}

export function dirty() {
    for (var i in buffer) return true
    return false
}

export function trigger(buffer) {
    listeners.forEach((k) => k(buffer))
}

// TODO: use actual CRDTs or something, as soon
// as we can figure out some way to make them
// sufficiently efficient

// this approach probably should be robust against
// massive data loss, but it still leaves open
// the possibliity of clients diverging in state

export function apply(delta) {
    // console.log('applying', delta)
    for (var i in delta) {
        if (i == '__root__') {
        } else if (delta[i] === null) {
            delete global.entities[i]
        } else {
            var old = global.entities[i],
                next = delta[i]

            if (next._list) {
                next = Object.assign({}, next, {
                    list: list_apply((old && old.list) || [], next._list),
                })
                delete next['_list']
            }
            global.entities[i] = next

            postset(i, delta[i])
        }
    }
    trigger(delta)
}

// Operations: INSERT(ID, POS), DEL(ID)

function list_delta(old_list, new_list) {
    return array_levenshtein(old_list, new_list).map(([op, pos, value]) => {
        if (op == '-') {
            return ['-', value]
        } else if (op == '+') {
            return ['+', value, pos]
        }
    })
}

function list_apply(old_list, deltas) {
    var next_list = old_list.slice(0)
    deltas.forEach((op) => {
        if (op[0] == '-') {
            next_list = next_list.filter((k) => k != op[1])
        } else if (op[0] == '+') {
            next_list.splice(op[2], 0, op[1])
        }
    })
    return next_list
}

function encode_delta(buffer) {
    var delta = {}
    for (var i in buffer) {
        var next = buffer[i],
            old = next && global.entities[next.id]

        // this was a fancy list diffing algorithm which was implemented for our
        // websocket based implementation of real time editing
        if (next && old && old.list && next.list && !_.isEqual(old.list, next.list)) {
            next = Object.assign({}, next)
            next._list = list_delta(old.list, next.list)
            delete next['list']
        }
        delta[i] = next
    }
    return delta
}

function array_levenshtein(a, b, eq = null) {
    if (!eq)
        eq = function(a, b) {
            return a === b
        }

    for (var i = 0, cache = []; i < a.length + 1; i++) cache[i] = new Array(b.length + 1)

    function memo(s, t) {
        if (cache[s][t]) return cache[s][t]
        var result = helper(s, t)
        cache[s][t] = result
        return result
    }

    function helper(s, t) {
        if (s === a.length && t === b.length) return []
        if (s === a.length) return memo(s, t + 1).concat([['+', s, b[t]]])
        if (t === b.length) return memo(s + 1, t).concat([['-', s, a[s]]])
        if (eq(a[s], b[t])) return memo(s + 1, t + 1)
        var ins = memo(s, t + 1).concat([['+', s, b[t]]]),
            del = memo(s + 1, t).concat([['-', s, a[s]]])
        return del.length < ins.length ? del : ins
    }
    return helper(0, 0)
}

export function checkpoint() {
    save(buffer)
    apply(buffer)
    buffer = {}
}

export function stash() {
    // restore the previous checkpointed state
    buffer = {}
}

export function get(id, def = null): Entity {
    // if(id == '__root__'){
    //     debugger
    // }
    // if(def){
    //     console.warn('default get argument deprecated')
    //     debugger
    // }
    // console.assert(!id.startsWith('fake-'))
    // return buffer[id] || entities[id]
    if (id in buffer) {
        return buffer[id] || def
    } else {
        return global.entities[id] || def
    }
}

// global.getcalls = 0
// 22,421,088 ops/sec
// 8,165,008 calls
export function fast_get(id) {
    // global.getcalls++
    var thing = buffer[id]
    if (thing || thing === null) {
        return thing
    }
    return global.entities[id]

    // if(id in buffer){
    //     return buffer[id]
    // }else{
    //     if(id in global.entities){
    //         return global.entities[id]
    //     }
    // }
    // return null
}

export function exists(id) {
    return id in buffer || id in global.entities
}

export function del(id) {
    // delete entities[id]
    buffer[id] = null
}

export function update(id, obj) {
    // console.assert(get(id))
    set(id, _.assign(_.clone(get(id) || {}), obj))
}

// var cache = new LRUCache(100)

// export function enlist(line){
//     if(line && line.list){
//         var key = line.list.join("::");
//         var cached = cache.get(key)
//         if(cached){
//             return cached
//         }else{
//             var value = line.list.map(fast_get)
//             cache.put(key, value)
//             return value
//         }
//     }else{
//         return []
//     }
// }

export function enlist(line: Entity): Entity[] {
    if (line && line.list) {
        return line.list.map(fast_get).filter((k) => k)
    } else {
        return []
    }
}

export function append_line(id: string, el, def = null) {
    insert_after(id, null, el, def)
}

// rather weirdly "insert before" is done by sticking a
// little caret in front of the ref identifier string
// TODO: rethink whether this is a good idea and replace
// it with something more typical and sensical
export function insert_after_ref(list: string[], ref: string | null, el: string) {
    console.assert(ref === null || typeof ref == 'string')
    if (list) {
        console.assert(Array.isArray(list))
        console.assert(list.every((k) => typeof k == 'string'))
    }

    var source = (list || []).slice(0) // shallow array clone
    var before = ref && ref.startsWith('^')
    if (before) ref = ref.slice(1)
    var index = source.indexOf(ref)

    if (index < 0) {
        source.push(el)
    } else {
        if (before) {
            source.splice(index, 0, el)
        } else {
            source.splice(index + 1, 0, el)
        }
    }
    return source
}

function insert_after(id: string, ref, el, def = null) {
    var base = get(id)
    if (!base && def) {
        console.warn('assigning default value', id)
        base = _.assign(def, { created: Date.now() })
        debugger
    }
    console.assert(base)
    if (get(el.id)) console.warn('inserting to id which already exists')
    set(el.id, _.assign(_.clone(el), { parent: id, created: Date.now() }))

    set(
        id,
        _.assign(_.clone(base), {
            list: insert_after_ref(base.list, ref, el.id),
        })
    )
}

export function insert_relative(parent: Entity, ref, el) {
    var parent_id
    if (parent.type == 'edge' || parent.type == 'root') {
        parent_id = parent.ref
    } else if (parent.type == 'text') {
        parent_id = parent.id
    } else if (parent.type == 'cluster' && parent.predicate == '') {
        parent_id = enlist(parent)[0].ref
    } else {
        return console.log('can only insert relative to edge, root or text', parent)
    }
    insert_after(parent_id, ref, el)
}

export function line_is_equal(a, b) {
    return a === b || _line_is_equal(a, b) || _line_is_equal(b, a)
}

function _line_is_equal(a, b) {
    if (a.type == 'edge' || a.type == 'root') {
        if (b.type == 'edge' || b.type == 'root') {
            return a.ref == b.ref
        } else if (b.type == 'text') {
            return a.ref == b.id
        }
    }
    if (a.type == 'cluster' && a.list && a.list.length == 1 && a.predicate == '') {
        // TODO: this is something that can trivialy be optimized
        return line_is_equal(enlist(a)[0], b)
    }
}

// TODO: make a better thing for this
export function tok(entity) {
    if (entity.trim() == '') return ''
    return '<ref-' + entity + '>'
}

export function textok(text) {
    return '<ref-TEXT-' + text + '>'
}

// global.tok = tok

export function untok(refstr) {
    var m = refstr.match(/<ref-(.*?)>/)
    return m && m[1]
}

export function remove_refs(text) {
    // console.warn('remove_refs is deprecated')
    return (text + ' ')
        .replace(/<ref-(.*?)>([\s,\(]*)/g, (all, ref, pad) => {
            // ref + (pad || ' ')
            if (ref.startsWith('TEXT-')) {
                return ref.slice(5)
            }
            if (get(ref)) {
                return get(ref).text + (pad || ' ')
            } else {
                return '<null>'
            }
        })
        .slice(0, -1)
}

export function string_reverse(str: string): string {
    return str
        .split('')
        .reverse()
        .join('')
}

export function remove_entity(entity: Entity, recursive = false) {
    if (!entity) return
    // remove children
    if (entity.list && entity.list.length > 0) {
        enlist(entity).forEach((k) => remove_entity(k))
    }
    del(entity.id)
    return detach_entity(entity, recursive)
}

export function detach_entity(entity: Entity, recursive = false) {
    if (entity.parent == '__root__') return false

    var parent = get(entity.parent)

    if (parent && parent.list) {
        var nextlist = parent.list.filter((k) => k != entity.id)
        update(parent.id, { list: nextlist })
        if (
            nextlist.length == 0 &&
            !(
                // delete empty root nodes, but don't delete something that has text
                (parent.type == 'text' && parent.parent != '__root__')
            )
        ) {
            if (recursive) remove_entity(parent, recursive)
            return true
        }
    }
    return false
}

function update_edge(source, predicate, target, edgeid = null, comment = null) {
    if (!get(source)) {
        console.warn('source not found', source)
        // return
    }
    if (!get(target)) {
        console.warn('target not found', target)
        // return
    }
    if (!edgeid) edgeid = generate_edgeid()

    function attach(cid) {
        // console.log('attaching', line.parent, 'to',cid,'as',anti)
        var newedge = {
            id: edgeid,
            type: 'edge',
            pre: ' ',
            ref: target,
            comment: comment,
        }
        if ((get(cid).list || []).indexOf(edgeid) != -1) {
            // console.log('updating edge')
            update(edgeid, newedge)
        } else {
            // console.log('appending edge', get(cid), edgeid)
            append_line(cid, newedge)
        }
    }

    // if such a named edge already exists, attach
    // it to the existing cluster parent
    if (get(edgeid) && get(get(edgeid).parent)) {
        // console.log('reattaching to existing predicate thing')
        var cid = get(edgeid).parent
        attach(cid)

        // update the predicate
        if (!predicate_is_equal(get(cid).predicate, predicate)) {
            update(cid, { predicate: predicate })
        }
    } else {
        // check if there already exists some cluster with the same predicate
        if (
            !enlist(get(source)).some((c) => {
                if (
                    c.type == 'cluster' &&
                    c.predicate.trim() != '' &&
                    predicate_is_equal(c.predicate, predicate)
                ) {
                    attach(c.id)
                    return true
                }
            })
        ) {
            // nope, create a new one
            var cid = generate_id()
            append_line(source, {
                type: 'cluster',
                id: cid,
                predicate: predicate,
            })
            attach(cid)
        }
    }
}

// determines whether or not an edge exists in the graph
export function check_edge(source, predicate, target) {
    var found_edge
    if (
        enlist(get(source)).some((c) => {
            if (
                c.type == 'cluster' &&
                (predicate === null || predicate_is_equal(c.predicate, predicate))
            ) {
                found_edge = edge_map[c.id + '::' + target]
                return found_edge
            }
        })
    ) {
        return found_edge
    }

    // var found_edge;
    // if(enlist(get(source)).some(c => {
    //     if(c.type == 'cluster' &&
    //         (predicate === null ||
    //             predicate_is_equal(c.predicate, predicate)
    //         )){
    //         return edges[c.id + "::" + target]
    //         // return enlist(c).some(e => {
    //         //     if(e.type == 'edge' && e.ref == target){
    //         //         found_edge = e;
    //         //         return true
    //         //     }
    //         // })
    //     }
    // })){
    //     return found_edge.id;
    // }
}

export function check_path(path) {
    console.assert(path[0].id == '__root__')
    return path.slice(1).every((k) => k.id.startsWith('fake-') || quark.get(k.id))
}

// TODO: find a way to combine this with remove_entity(recursive)
// because this is essentially a version of that with limited numbers
// of iterations (i.e. don't remove textnode if no children found)
function remove_edge(edge) {
    if (!edge) return
    console.assert(edge.type == 'edge')
    remove_entity(edge, false)
    var parent = get(edge.parent)
    if (enlist(parent).length == 0) {
        console.assert(parent.type == 'cluster')
        remove_entity(parent, false)
    }
}

export function del_biedge(source, predicate, target) {
    // source = fid(source)
    // target = fid(target)
    var fwd = check_edge(source, predicate, target)
    if (fwd) {
        var anti = string_reverse(fwd)
        remove_entity(get(anti), true)
        remove_entity(get(fwd), true)
    }
}

export function add_biedge(source, predicate, target) {
    // source = fid(source)
    // target = fid(target)
    var fwd = generate_id()
    var anti = string_reverse(fwd)
    if (
        !check_edge(source, predicate, target) &&
        !check_edge(target, invert_predicate(predicate), source)
    ) {
        update_edge(source, predicate, target, fwd)
        update_edge(target, invert_predicate(predicate), source, anti)
    }
}

// TODO: this function has weird arguments
export function update_cluster(line, edges) {
    var removed = line.type == 'cluster' ? enlist(line).slice(0) : []
    edges.forEach((k) => {
        var ind = removed.findIndex((e) => e.ref == k.ref)
        if (ind != -1) {
            k.id = removed[ind].id
            removed.splice(ind, 1)
        }
    })

    var check_empty = []
    removed.forEach((k) => {
        var anti = get(string_reverse(k.id))
        if (anti && remove_entity(anti)) {
            check_empty.push(anti.parent)
        }
        del(k.id)
    })

    // TODO: abstract this into a function which
    // adds a particular edge somewhere it fits

    var list = edges.map((k, i) => {
        var id = k.id || generate_edgeid()
        var anti = string_reverse(id)

        if (k.ref.trim() != '' && k.ref != line.parent) {
            update_edge(
                k.ref, // source
                invert_predicate(line.predicate), // predicate
                line.parent, // target
                anti, // id for new edge
                k.comment
            ) // comment
        }
        if (k.parent) console.assert(line.id == k.parent)
        var basis: Partial<Entity> = get(id) || {}
        update(
            id,
            _.assign(_.clone(k), {
                parent: line.id,
                created: basis.created || Date.now(),
            })
        )
        // console.log('update', id, k)
        return id
    })

    update(
        line.id,
        _.assign(line, {
            type: 'cluster',
            list,
            predicate: line.predicate,
        })
    )

    check_empty.forEach((k) => {
        if (enlist(get(k)).length == 0) {
            // console.log('such recursive remove', get(get(k).parent))
            remove_entity(get(k), true)
            // remove_entity(get(k), false);
        }
    })
}

let HYPERNOTE_SERIALIZATION_DIVIDER = _.repeat('=', 25) + 'BEGIN HYPERNOTE DUMP' + _.repeat('=', 25)

export function serialize() {
    let for_humans = ''
    // '> Do not edit the text below and re-import the document. \n' +
    // '> The human-readable text is ignored during the import process.\n\n'

    // Object.entries(global.entities)
    //     .filter(([id, value]) => value.parent == "__root__")

    function recurse(obj, depth) {
        let indent = _.repeat('  ', depth - 1)
        if (!obj) {
            console.warn('encountered unexpected undefined object')
            // console.trace()
            return
        }
        if (depth === 0) {
            for_humans += `# ${obj.text}\n`
            for (let item of obj.list || []) {
                recurse(global.entities[item], depth + 1)
            }
            for_humans += '\n'
        } else if (obj.type === 'text') {
            for_humans += `${indent}- ${obj.text}\n`
            for (let item of obj.list || []) {
                recurse(global.entities[item], depth + 1)
            }
        } else if (obj.type === 'cluster') {
            for_humans += `${indent}- ${clean_predicate(obj)}: ${obj.list
                .map((k) => {
                    let edge = global.entities[k]
                    if (!edge || !global.entities[edge.ref] || edge.type !== 'edge')
                        return '[Not Found]'
                    let ent = global.entities[edge.ref]
                    return ent.text + (edge.comment ? ` (${edge.comment})` : '')
                })
                .join(', ')}\n`
        }
    }

    _.sortBy(
        Object.entries(global.entities).filter(([id, obj]) => obj.parent === '__root__'),
        ([id, obj]) => obj.text
    ).forEach(([id, obj]) => recurse(obj, 0))

    let text =
        for_humans +
        '\n\n' +
        HYPERNOTE_SERIALIZATION_DIVIDER +
        '\n\n' +
        JSON.stringify(global.entities) +
        '\n'

    return text
}

function new_restore(data) {
    let parent_ids = []
    for (let line of data.split('\n')) {
        // console.log(line)
        let match
        if (/^\s*$/.test(line)) {
            // skip
        } else if (line.startsWith('#')) {
            // if(title){
            //     // garbage collect the previous entity

            //     collectEntity(fid(title))
            // }
            const title = line.slice(1).trim()
            parent_ids = [quark.fid(title)]
        } else if ((match = line.match(/^((  )*)-(.+)/))) {
            const indent_level = match[1].length / 2
            const parent_id = parent_ids[indent_level]

            if (!parent_id) console.warn('No parent', line)
            let child_id = quark.create_child(get(parent_id))

            parent_ids = [...parent_ids.slice(0, indent_level + 1), child_id]

            quark.set_text(quark.get(parent_id), quark.get(child_id), match[3].trim())
        } else {
            console.warn('Unhandled line:', line)
        }
    }

    quark.checkpoint()
}

export function restore(text) {
    try {
        let parts = text.split(HYPERNOTE_SERIALIZATION_DIVIDER)
        var obj = JSON.parse(parts[parts.length - 1])
    } catch (err) {
        if (confirm('Do you want to use the experimental new import engine?')) {
            new_restore(text)
        } else {
            alert('Could not parse file contents')
        }
    }

    if (obj) {
        console.log(obj)
        for (let id in obj) {
            if (id != obj[id].id) continue
            quark.core.set(id, obj[id])
        }
        quark.checkpoint()
        globalUpdate()
        console.log('done adding')
    }
}
