import * as quark from './index'

let queued_milestones = {}
let completed_milestones = {}
let milestoneDelay

export function hasCompletedMilestone(name) {
    if (name in completed_milestones) return true
    let userProfile = quark.store.userProfile
    if (!userProfile) return false
    let profile = userProfile.profile || {}
    if (profile['MILESTONE_' + name]) {
        return true
    } else {
        return false
    }
}

export function milestone(name, suppressable = false) {
    if (!hasCompletedMilestone(name) && !queued_milestones[name]) {
        if (suppressable) {
            queued_milestones[name] = true
            if (!milestoneDelay) {
                milestoneDelay = setTimeout(update_queued_milestones, 100)
            }
        } else {
            completed_milestones[name] = true
            setTimeout(() => {
                quark.store.updateProfile({
                    ['MILESTONE_' + name]: true,
                })
            }, 10)
        }
    }
}

function update_queued_milestones() {
    let update = {}
    for (let key in queued_milestones) {
        update['MILESTONE_' + key] = true
        completed_milestones[key] = true
    }
    queued_milestones = {}
    quark.store.updateProfile(update)
    milestoneDelay = null
}

export function suppressMilestones() {
    if (milestoneDelay) {
        clearTimeout(milestoneDelay)
    }
    milestoneDelay = setTimeout(update_queued_milestones, 500)
}
