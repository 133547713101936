import React from 'react'
import PropTypes from 'prop-types'
import '../style/spinner.less'

export class BareSpinner extends React.Component<{
    size?: number
    borderColor?: string
    borderTopColor?: string
    state?: string
}> {
    static defaultProps = {
        size: 20,
        borderColor: '#f3f3f3',
        borderTopColor: '#3498db',
    }

    static propTypes = {
        size: PropTypes.number,
        borderColor: PropTypes.string,
        borderTopColor: PropTypes.string,
        state: PropTypes.string,
    }

    render() {
        let spinnerClass = 'mg-react-spinner__main' + ' ' + 'l-center'
        let size = this.props.size

        let margin = -1 * (size / 2)
        let borderWidth = (2 * size) / 15
        let minHeight = (borderWidth + size) * 1.41
        let borderColor = this.props.borderColor
        let borderTopColor = this.props.borderTopColor
        return (
            <div
                className={(this.props.state === 'hide' ? 'hide ' : ' ') + 'mg-react-spinner'}
                style={{
                    height: minHeight + 'px',
                }}
            >
                <div
                    className={spinnerClass}
                    style={{
                        width: size + 'px',
                        height: size + 'px',
                        marginTop: margin,
                        marginLeft: margin,
                        borderWidth: borderWidth,
                        borderColor: borderColor,
                        borderTopColor: borderTopColor,
                    }}
                />
            </div>
        )
    }
}

export default function Spinner(props) {
    return (
        <div
            style={{
                // position: 'absolute',
                // top: '50%',
                // left: '50%',
                // marginLeft: -100,
                // marginTop: -75,
                // width: 200,
                // height: 100,
                textAlign: 'center',
                color: 'gray',
                // border: '2px solid green',
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                // height: '100%'
                // width: '100%',
                // height: '100%'
                ...props.style,
            }}
        >
            <div>
                <div
                    style={{
                        // position: 'absolute',
                        // top: '50%',
                        // left: '50%',
                        // marginLeft: -100,
                        // marginTop: -75,
                        // width: 200,
                        // height: 100,
                        textAlign: 'center',
                        color: 'gray',
                        flexGrow: 1,
                        // width: '100%',
                        // height: '100%'
                    }}
                >
                    {props.message || 'Loading...'}
                </div>
                <BareSpinner borderTopColor="#CB5C5C" size={50} />
            </div>
        </div>
    )
}
