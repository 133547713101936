import _ from 'lodash'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Lombax from './Lombax'
import classNames from 'classnames'
import Emboldinator from './Emboldinator'
import Modal from 'react-modal'
import * as quark from '../quark'
import { collectEntity } from '../quark/collect'
import { globalUpdate } from '../Aphelion'

Modal.setAppElement('#root')

document.body.addEventListener('click', function() {
    setTimeout(function() {
        if (global.$$root$$.show_popover) {
            global.$$root$$.show_popover = false
            globalUpdate()
        }
    }, 10)
})

class EntityRenameDialog extends Component<{}, { text: string }> {
    constructor(props) {
        super(props)

        var line = quark.root()
        this.state = {
            text: quark.get(line.ref).text,
        }
    }
    componentDidMount() {
        setTimeout((e) => (ReactDOM.findDOMNode(this.refs.namefield) as HTMLElement).focus(), 100)
    }
    closeModal() {
        global.$$root$$.is_renaming = false
        globalUpdate()
    }
    updateText(e) {
        this.setState({
            text: e.target.value,
        })
    }
    doRename() {
        var text = this.state.text.trim()
        quark.core.update(quark.root().ref, { text })
        this.closeModal()
    }

    doMerge() {
        var text = this.state.text.trim()
        var newid = quark.root().ref,
            curent = quark.get(newid)

        var alterid = quark.find_name(text),
            alter = quark.get(alterid)
        quark.enlist(alter).forEach((k) => quark.core.update(k.id, { parent: newid }))
        quark.core.del(alterid)

        quark.core.update(newid, {
            text,
            list: alter.list.concat(curent.list),
        })

        _.values(global.entities)
            .filter((k) => k.type == 'edge' && k.ref == alterid)
            .forEach((k) =>
                quark.core.update(k.id, {
                    ref: newid,
                })
            )

        quark.checkpoint()

        collectEntity(newid)

        quark.checkpoint()
        this.closeModal()
    }

    render() {
        var line = quark.root()
        var text = this.state.text.trim()
        var button: JSX.Element
        if (text == quark.get(line.ref).text || !text) {
        } else if (quark.find_name(text)) {
            var alter = quark.get(quark.find_name(text))
            // console.log(quark.enlist(alter).length) // number of fields

            button = (
                <button className="rename" onClick={this.doMerge.bind(this)}>
                    Rename & Merge
                </button>
            )
        } else {
            button = (
                <button className="rename" onClick={this.doRename.bind(this)}>
                    Rename
                </button>
            )
        }

        return (
            <Modal isOpen={true} onRequestClose={this.closeModal.bind(this)}>
                <input
                    ref="namefield"
                    type="text"
                    defaultValue={this.state.text}
                    onChange={this.updateText.bind(this)}
                />
                <button className="cancel" onClick={this.closeModal.bind(this)}>
                    Cancel
                </button>{' '}
                {button}
            </Modal>
        )
    }
}

export default class Azimuth extends Lombax {
    keydownHandler: (e) => void

    constructor(props) {
        super(props)
        this.state = { index: 0, hover: false, expanded: false }
        // this.state.index = 0

        this.keydownHandler = (e) => {
            if ((e.metaKey || e.ctrlKey) && e.keyCode === 68) {
                // Cmd-D/Ctrl-D
                this.makeScratchpad()
                e.preventDefault()
            } else if ((e.metaKey || e.ctrlKey) && e.keyCode === 69) {
                // Cmd-E/Ctrl-E
                this.focusSearch()
                e.preventDefault()
            }
        }
    }

    makeScratchpad() {
        var root = quark.new_scratchpad()
        var children = quark.get_children(root)
        if (children.length == 0) {
            this.setFocus(quark.create_child(root))
            quark.checkpoint()
        }
        globalUpdate()
    }

    focusSearch() {
        quark.milestone('KEYBOARD_SELECT_SEARCH_BAR')

        this.setFocus('__root__')
        globalUpdate()
        this.root()
            .input()
            .cm.execCommand('selectAll')
    }

    onDragOver = (e) => e.preventDefault()
    onDrop = (e) => {
        e.preventDefault()
        if (quark.root().type !== 'root') {
            alert('Please drop a file into a specific note')
            return
        }

        for (let file of e.dataTransfer.files) {
            let id = quark.append_child(quark.root().ref, {
                type: 'file',
                name: file.name,
                uploading: true,
            })
            quark.store.storage
                .child(id)
                .put(file)
                .then((result) => {
                    console.log(result)
                    quark.core.update(id, {
                        uploading: false,
                    })
                    quark.checkpoint()
                })
                .catch((err) => {
                    quark.core.update(id, {
                        uploading: false,
                        error: err.toString(),
                    })
                    console.log('failed!', err)
                    quark.checkpoint()
                })

            quark.checkpoint()
        }
    }

    componentDidMount() {
        document.addEventListener('keydown', this.keydownHandler)

        document.addEventListener('dragover', this.onDragOver, true)
        document.addEventListener('drop', this.onDrop, true)
        super.componentDidMount()
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.keydownHandler)

        document.removeEventListener('dragover', this.onDragOver, true)
        document.removeEventListener('drop', this.onDrop, true)
        super.componentWillUnmount()
    }

    focusNext() {
        var { line, path } = this.props
        if (line.type == 'search') {
            var nchildren = quark.get_children(this.props.line).length
            this.setState({ index: Math.min(nchildren - 1, this.state.index + 1) })
        } else return super.focusNext()
    }
    focusPrev() {
        var { line, path } = this.props
        if (line.type == 'search') {
            this.setState({ index: Math.max(0, this.state.index - 1) })
        } else return super.focusPrev()
    }

    handleTab(shift) {
        var { line, path } = this.props
        if (line.type == 'search') {
            this.handleEnter(false, false)
            // this.setFocus('__root__')
            this.focus()
            this.input().cm.setCursor({ ch: 1e8, line: 1e8 })
            globalUpdate()
        }
    }
    handleEnter(shift, focus_child = true) {
        var { line, path } = this.props
        if (line.type == 'search') {
            var root: typeof global.$$root$$ | Entity

            var result = quark.get_children(line)[this.state.index]
            if (!result) {
                console.warn('Enter pressed without any selection...')
            } else if (result.type == 'lazy') {
                quark.milestone('CREATE_NOTE')
                quark.set_text(undefined, line, quark.tok(quark.fid(result.name)))
                quark.checkpoint()

                root = quark.root()
                this.input().updateText(quark.get_text(root))
            } else if (result.type == 'logout') {
                if (confirm('Are you sure you want to log out?')) {
                    quark.store.signOut()
                }

                quark.set_text(undefined, line, '')
                globalUpdate()
                return
            } else if (result.type == 'scratchpad') {
                this.makeScratchpad()

                return
            } else if (result.type === 'tutorial') {
                quark.open_academy()
                return
            } else if (result.type === 'noop') {
                return
            } else if (result.ref) {
                // console.log(result.ref)
                if (result.ref === quark.tok(quark.SpecialPages.graph)) {
                    quark.milestone('OPEN_NETWORK_VIEW')
                } else if (result.ref === quark.tok(quark.SpecialPages.calendar)) {
                    quark.milestone('OPEN_CALENDAR_VIEW')
                } else if (result.ref === quark.tok(quark.SpecialPages.map)) {
                    quark.milestone('OPEN_MAP_VIEW')
                } else if (result.ref === quark.tok(quark.SpecialPages.help)) {
                    quark.milestone('OPEN_HELP')
                }

                quark.set_text(undefined, line, result.ref)
                quark.checkpoint()
                root = quark.root()

                this.input().updateText(quark.get_text(root))
            } else {
                root = quark.new_scratchpad()
            }
            if (focus_child) {
                var children = quark.get_children(root)
                if (children.length == 0) {
                    this.setFocus(quark.create_child(root))
                    quark.checkpoint()
                } else {
                    if (children.some((k) => k.type === 'cluster' && k.predicate[0] === '~')) {
                        quark.milestone('OPEN_AUTOGENERATED_NOTE')
                    }

                    if (children.length > 6) {
                        this.setFocus(children[0].id)
                    } else {
                        this.setFocus(children[children.length - 1].id)
                    }
                }
            }

            globalUpdate()
        } else return super.handleEnter(shift)
    }
    onChange(text) {
        super.onChange(text)
        if (this.state.index) this.setState({ index: 0 })
    }
    selectIndex(index) {
        this.setState({ index: index })
    }
    clickIndex(index) {
        this.setState({ index: index })
        requestAnimationFrame(() => this.handleEnter(false))
        globalUpdate()
    }
    clickPrefix() {
        quark.milestone('CLICK_SEARCH')
        quark.set_text(undefined, quark.root(), '')
        quark.checkpoint()
        globalUpdate()
        this.focus()
    }
    clickRename() {
        global.$$root$$.is_renaming = true
        globalUpdate()
    }
    render_node() {
        var { line, path } = this.props
        var popover = null
        if (line.show_popover) {
            popover = (
                <div className="popover bottom">
                    <div className="arrow" />
                    <div>
                        <a onClick={this.clickRename.bind(this)}>Rename Entity</a>
                    </div>
                </div>
            )
        }
        return (
            <div>
                <div className="azimuth-prefix" onClick={this.clickPrefix.bind(this)}>
                    <span className={'glyphicon glyphicon-search'} />
                </div>
                {super.render_node()}
                {popover}
                {line.is_renaming && <EntityRenameDialog />}
            </div>
        )
    }
    render_children(children) {
        var { line, path } = this.props
        var { index } = this.state

        if (line.type == 'search') {
            // return "the meaning of life is whaaat"
            var query = line.text

            if (children.length === 0 && !quark.store.canEdit) {
                return (
                    <div className="result">
                        <span className="prefix">
                            <i>(no results found)</i>
                        </span>
                    </div>
                )
            }
            return children.map((k, i) => {
                return (
                    <div
                        onClick={(e) => this.clickIndex(i)}
                        onMouseEnter={(e) => this.selectIndex(i)}
                        className={classNames({
                            result: true,
                            selected: i == index,
                            // fresh: k.type == 'fresh',
                            // action: k.type == 'action'
                        })}
                        key={i}
                    >
                        {k.icon ? <span className={'glyphicon glyphicon-' + k.icon} /> : null}
                        <span className="prefix">
                            <Emboldinator str={k.prefix || ''} query={query.trim()} />
                        </span>
                        <span className="suffix">
                            <Emboldinator str={k.suffix || ''} query={query.trim()} />
                        </span>
                    </div>
                )
            })
        } else return super.render_children(children)
    }
}
