import _ from 'lodash'
import fuzzy_match from '../lib/fuzzy'
import { enlist, get, tok, remove_entity, watch, unwatch, get_path } from './core'
import { clean_predicate } from './predicates/predicate'
import * as graph from './graph'
import moment from 'moment'
import chrono from 'chrono-node'
import * as quark from './index'

type SearchResult = {
    prefix: string
    search: string
    icon?: string
    penalty: number
    type?: string
    name?: string
    suffix?: string
    ref?: string
}

// TODO: deprecate this
export function get_name(k) {
    var text
    if (!k) return '<null>'
    text = k.text
    var p = global.entities[k.parent]
    while (p && p.parent) {
        text = p.text + ' \u203A ' + text
        p = global.entities[p.parent]
    }
    return text
}

function ellipsis(str) {
    const MAXLEN = 35
    if (str.length > MAXLEN) {
        return str.slice(0, MAXLEN).trim() + '...'
    }
    return str
}

var searchpool: {
    [key: string]: SearchResult
} = {}
// global.searchpool = searchpool

function safeMap(array, fn) {
    let result = []
    for (let i = 0; i < array.length; i++) {
        try {
            result.push(fn(array[i], i))
        } catch (err) {
            console.warn('Error encountered in map', err)
        }
    }
    return result
}

function cache_entity_search_text(ent) {
    var tags = _.sortBy(
        safeMap(
            quark
                .enlist(ent)
                // .filter(k => k.list && k.list.length == 1 && k.predicate == '')
                .filter((k) => k.list && k.list.length >= 1)
                .filter((k) => {
                    try {
                        return (
                            get(get(k.list[0]).ref) && get(get(k.list[0]).ref).parent == '__root__'
                        )
                    } catch (err) {}
                }),
            (k) =>
                (k.predicate ? clean_predicate(k) + ': ' : '') +
                enlist(k)
                    .slice(0, 3)
                    .map((e) => get(e.ref) && get(e.ref).text)
                    .join(', ')
        ),
        (k) => -enlist(get(k)).length
    ).slice(0, 3)
    var texts = quark.enlist(ent).filter((k) => k.type == 'text')
    var tagtext =
        tags.length > 0 ? ' / ' + tags.join(' / ') : texts.length > 0 ? ' / ' + texts[0].text : ''

    var name = get_name(ent)

    // non-root text nodes
    if (ent.parent != '__root__' && ent.type == 'text') {
        if (
            enlist(ent).length > 0 &&
            ent.text.indexOf('#') == -1 // hashatagged things go to parent
        ) {
            // console.log(tagtext)
            searchpool[ent.id] = {
                prefix: name,
                search: ent.text,
                suffix: tagtext,
                ref: tok(ent.id),
                penalty: 1 - graph.degree(ent) / 10000,
            }
        } else {
            searchpool[ent.id] = {
                prefix: get_path(ent)
                    .slice(0, -1)
                    .map((k) => ellipsis(k ? k.text : ''))
                    .join(' \u203A '),
                search: ent.text,
                suffix: ' / ' + ent.text,
                ref: tok(ent.parent),
                penalty: 1 - graph.degree(ent) / 10000,
            }
        }
    } else {
        searchpool[ent.id] = {
            prefix: name,
            suffix: tagtext,
            search: name,
            ref: tok(ent.id),
            type: 'node',
            penalty: -graph.degree(ent) / 10000,
        }
        enlist(ent)
            .filter((k) => k.type == 'cluster')
            .forEach((e) => {
                if (!e.predicate) return

                try {
                    var text =
                        (e.predicate ? clean_predicate(e).trim() + ': ' : ':') +
                        enlist(e)
                            .map((z) => get_name(get(z.ref)) + (z.comment ? z.comment : ''))
                            .join(', ')

                    searchpool[e.id] = {
                        suffix: ' / ' + text,
                        prefix: ellipsis(name),
                        penalty: 1,
                        search: text,
                        ref: tok(ent.id),
                    }
                } catch (err) {
                    console.warn('Error adding to search pool', err)
                }
            })
    }
}

function cache_entity_search(ent) {
    if (!ent) return
    if (ent.type == 'text') {
        var aliexp = ent.text.match(/(^[^:=]{0,25}=\s*)([\s\S]*)/)
        if (aliexp) {
            var pref = aliexp[1].replace('=', '').trim()

            searchpool[ent.id] = {
                // prefix: ent.alias,
                prefix: get_name(get(ent.parent)),
                // suffix: tagtext,
                suffix: (pref.length > 0 ? ' / ' + pref : '') + ' = ' + aliexp[2],
                search: aliexp[2],
                ref: tok(ent.parent),
                // type: 'node',
                penalty: 0,
            }
        } else {
            cache_entity_search_text(ent)
        }
    }
}

function receive_buffer(buffer) {
    var log = Object.keys(buffer).length > 100
    if (log) console.time('updating search cache')
    for (var key in buffer) {
        var ent = buffer[key]
        if (ent === null) {
            delete searchpool[key]
        } else {
            cache_entity_search(ent)
            if (ent.parent !== '__root__') {
                cache_entity_search(get(ent.parent))
            }
        }
    }
    if (log) console.timeEnd('updating search cache')
}

requestAnimationFrame(() => watch(receive_buffer))

if (module.hot) {
    module.hot.dispose(function() {
        unwatch(receive_buffer)
    })
}

function generate_pool() {
    console.time('generate pool')
    var pool = []
    _.values(global.entities).forEach((ent, i) => {
        cache_entity_search(ent)
    })
    console.timeEnd('generate pool')
    return pool
}

var last_pool = 0,
    update_timeout

export function search_auto(query) {
    if (last_pool == 0) {
        generate_pool()
        last_pool = Date.now()
    }
    clearTimeout(update_timeout)
    update_timeout = setTimeout(cleanup_entities, 1000)

    return run_search(query)
}

// TODO: replace this with something which collects this
// whenever an entity falls out of view
function cleanup_entities() {
    // remove blank lines
    var is_empty_line = (k) =>
        k.type == 'text' &&
        k.parent != '__root__' &&
        k.created < Date.now() - 5000 &&
        (!k.text || k.text.trim() == '')

    _.values(global.entities)
        .filter((k) => is_empty_line(k))
        .forEach((k) => remove_entity(k, true))
}

export function run_search(query) {
    var pool: SearchResult[] = [
        {
            prefix: 'Today',
            search: 'today',
            icon: 'calendar',
            suffix: ' / ' + moment().format('D MMMM YYYY'),
            name: moment().format('D MMMM YYYY'),
            penalty: -0.01,
            type: 'lazy',
        },

        // { prefix: 'New Scratchpad', icon: 'pencil',
        //   search: 'new scratchpad',
        //   penalty: -0.01, type: 'action'},
        {
            prefix: 'Recently Added',
            search: 'Recently Added',
            icon: 'time',
            penalty: -0.01,
            type: 'action',
            ref: tok(quark.SpecialPages.recent),
        },

        {
            prefix: 'Network View',
            search: 'Network View',
            penalty: -0.01,
            type: 'action',
            ref: tok(quark.SpecialPages.graph),
            icon: 'globe',
        },

        {
            prefix: 'Settings',
            search: 'Settings',
            penalty: -0.01,
            type: 'action',
            icon: 'cog',
            ref: tok(quark.SpecialPages.settings),
        },
        {
            prefix: 'Help',
            search: 'Help',
            penalty: -0.01,
            type: 'action',
            icon: 'question-sign',
            ref: tok(quark.SpecialPages.help),
        },

        // { prefix: 'yesterday', search: 'yesterday',
        //   suffix: ' / ' + moment().subtract(1, 'day').format('D MMMM YYYY'),
        //   ref: tok(moment().subtract(1, 'day').format('D MMMM YYYY')),
        //   penalty: -0.01, type: 'action'},
    ]

    // if (window.injectedState) {
    //     pool.push({
    //         prefix: 'Global Hotkey: ' + window.injectedState.keyboardShortcut,
    //         search: 'Hotkey',
    //         penalty: -0.01,
    //         type: 'noop',
    //         icon: 'fire',
    //     })
    // }

    // if (
    //     !quark.in_academy() &&
    //     window.navigator &&
    //     window.navigator.platform === 'MacIntel' &&
    //     !window.injectedState
    // )
    //     pool.push({
    //         prefix: 'Download Mac App',
    //         search: 'Download Mac App',
    //         penalty: -0.01,
    //         type: 'action',
    //         icon: 'download',
    //         ref: tok(quark.SpecialPages.mac),
    //     })

    if (!quark.store.userInfo) {
        pool.push({
            prefix: 'Log In',
            search: 'Log In',
            penalty: -0.01,
            type: 'action',
            ref: tok(quark.SpecialPages.login),
            icon: 'log-in',
        })
    }

    // if (innerWidth < 600) {
    //     pool.push({
    //         prefix: 'Upgrade',
    //         search: 'Upgrade',
    //         ref: tok(quark.SpecialPages.upgrade),
    //         penalty: -0.01,
    //         type: 'action',
    //         icon: 'flash',
    //     })

    var simple_query = query.trim().toLowerCase()
    var MAX_RESULTS = 15
    if (query.trim().length > 0) {
        pool = pool.concat(_.values(searchpool).filter((k) => k.search))
        if (quark.store.canEdit)
            pool.push({
                prefix: 'New Diary Entry',
                search: 'New Diary Entry',
                penalty: -0.001,
                type: 'scratchpad',
                icon: 'file',
            })

        pool.push({
            prefix: 'Keyboard Shortcuts',
            search: 'keyboard shortcuts',
            icon: 'question-sign',
            type: 'action',
            ref: tok(quark.SpecialPages.help),
            penalty: -0.0001,
        })

        if (!quark.in_academy()) {
            pool.push({
                prefix: 'Open Hypernote Academy',
                search: 'Open Hypernote Academy',
                penalty: -0.01,
                type: 'tutorial',
                icon: 'question-sign',
                // ref: tok(quark.SpecialPages.help),
            })
        }

        pool = pool.concat([
            {
                prefix: 'Map View',
                search: 'Map View',
                penalty: -0.01,
                type: 'action',
                ref: tok(quark.SpecialPages.map),
                icon: 'map-marker',
            },

            {
                prefix: 'Calendar View',
                search: 'Calendar View',
                penalty: -0.01,
                type: 'action',
                ref: tok(quark.SpecialPages.calendar),
                icon: 'calendar',
            },

            {
                prefix: 'Tomorrow',
                search: 'tomorrow',
                icon: 'calendar',
                suffix:
                    ' / ' +
                    moment()
                        .add(1, 'day')
                        .format('D MMMM YYYY'),
                name: moment()
                    .add(1, 'day')
                    .format('D MMMM YYYY'),
                penalty: -0.0001,
                type: 'lazy',
            },

            {
                prefix: 'Yesterday',
                search: 'yesterday',
                icon: 'calendar',
                suffix:
                    ' / ' +
                    moment()
                        .subtract(1, 'day')
                        .format('D MMMM YYYY'),
                name: moment()
                    .subtract(1, 'day')
                    .format('D MMMM YYYY'),
                penalty: -0.0001,
                type: 'lazy',
            },
        ])

        var days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
        days.forEach((day) => {
            pool.push({
                prefix: day,
                search: day.toLowerCase(),
                icon: 'calendar',
                suffix:
                    ' / ' +
                    moment()
                        .day(day)
                        .format('D MMMM YYYY'),
                name: moment()
                    .day(day)
                    .format('D MMMM YYYY'),
                penalty: -0.0001,
                type: 'lazy',
            })
        })

        if (
            !pool.some(
                (k) => k.type == 'node' && k.search.toLowerCase().startsWith(simple_query)
            ) &&
            quark.store.canEdit
        ) {
            pool.push({
                prefix: 'Add "' + query.trim() + '"',
                search: query.trim(),
                penalty: 1,
                // ref: tok(query.trim()),
                // suffix: ' (new)',
                name: query.trim(),
                icon: 'plus',
                type: 'lazy',
            })
        }

        if (quark.store.userInfo) {
            pool.push({
                prefix: 'Log Out',
                search: 'Log Out',
                penalty: -0.01,
                type: 'logout',
                icon: 'log-out',
            })
        }

        pool = pool.filter((k) => check(simple_query, k.search.toLowerCase()))

        if (pool.length === 1) {
            let parsedDate = chrono.parseDate(simple_query)
            if (parsedDate) {
                pool.unshift({
                    prefix: simple_query,
                    search: simple_query,
                    icon: 'calendar',
                    suffix: ' / ' + moment(parsedDate).format('D MMMM YYYY'),
                    name: moment(parsedDate).format('D MMMM YYYY'),
                    penalty: -0.0001,
                    type: 'lazy',
                })
            }
        }

        var startsWith = pool
            .map((k) => [k.search.toLowerCase().indexOf(simple_query), k] as [number, SearchResult])
            .filter((k) => k[0] != -1)

        if (startsWith.length > MAX_RESULTS) {
            pool = _.sortBy(
                startsWith,
                ([e, k]) => (k.penalty || 0) + k.search.length / 10000 + e / 100
            )
                .slice(0, MAX_RESULTS)
                .map(([e, k]) => k)
        } else {
            // console.log('full fuzz', pool.length)
            const fuzzy_grade = (needle, haystack) => {
                if (haystack.length < 100) {
                    return fuzzy_match(needle, haystack)
                } else {
                    return { error: haystack.length / 2, index: haystack.length }
                }
            }
            pool = _.sortBy(
                pool
                    // .filter(k => k.search.length < 100)
                    .map(
                        (k) =>
                            [fuzzy_grade(simple_query, k.search.toLowerCase()), k] as [
                                { error: number; positions: any[]; index?: number },
                                SearchResult
                            ]
                    )
                    .filter((k) => k[0] && k[0].error < 15),
                ([e, k]) => (k.penalty || 0) + e.error + e.index / 100
            )
                .slice(0, MAX_RESULTS)
                .map(([e, k]) => k)
        }
    }

    if (!quark.store.canEdit) {
        pool = pool.filter((k) => k.type != 'lazy')
    }

    return pool.map((k, i) =>
        _.assign(k, {
            id: 'result-' + i,
            type: k.type || 'raw',
        })
    )
}

// https://github.com/bevacqua/fuzzysearch
// apparently this one is fast
function fuzzysearch(needle, haystack) {
    var hlen = haystack.length
    var nlen = needle.length
    if (nlen > hlen) {
        return false
    }
    if (nlen === hlen) {
        return needle === haystack
    }
    outer: for (var i = 0, j = 0; i < nlen; i++) {
        var nch = needle.charCodeAt(i)
        while (j < hlen) {
            if (haystack.charCodeAt(j++) === nch) {
                continue outer
            }
        }
        return false
    }
    return true
}

function check(needle, haystack) {
    var lastIndex = -1
    for (var i = 0; i < needle.length; i++) {
        lastIndex = haystack.indexOf(needle[i], lastIndex + 1)
        if (lastIndex < 0) return false
    }
    return true
}
