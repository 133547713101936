// this is a port of something from libccv which
// is a port of something from opencv which is
// a port of an algorithm in some textbook from
// somewhere

// it has rough functional parity with ccv_array_group
// and cvSeqPartition and the union-find algorithm
// except rather than returning a list of list
// indicies in case one is so inclined to construct
// a list, it actually just returns the list

// this is a quadratic algorithm as far as I'm aware
// which means that the is_equal function will be called
// n(n - 1) times where n is the length of your elements
// array. For things with large numbers of elements,
// this can become very slow.

// it might be wise because of this to inform the
// algorithm with some notion of geometry. i.e.
// "these elements are really really far apart
// so they probably don't have anything to do with
// each other so lets just kind of let them do
// their thing and have incestuous relations with
// people closer to them"

export default function equivalence_classes(elements, is_equal) {
    var node = []
    for (var i = 0; i < elements.length; i++) {
        node.push({
            parent: 0,
            element: elements[i],
            rank: 0,
        })
    }
    for (var i = 0; i < node.length; i++) {
        var root = node[i]
        while (root.parent) {
            root = root.parent
        }
        for (var j = 0; j < node.length; j++) {
            if (i == j) continue
            if (!is_equal(node[i].element, node[j].element)) continue
            var root2 = node[j]
            while (root2.parent) {
                root2 = root2.parent
            }
            if (root2 != root) {
                if (root.rank > root2.rank) {
                    root2.parent = root
                } else {
                    root.parent = root2
                    if (root.rank == root2.rank) {
                        root2.rank++
                    }
                    root = root2
                }
                var node2 = node[j]
                while (node2.parent) {
                    var temp = node2
                    node2 = node2.parent
                    temp.parent = root
                }
                var node2 = node[i]
                while (node2.parent) {
                    var temp = node2
                    node2 = node2.parent
                    temp.parent = root
                }
            }
        }
    }
    var index = 0
    var clusters = []
    for (var i = 0; i < node.length; i++) {
        var j = -1
        var node1 = node[i]
        while (node1.parent) {
            node1 = node1.parent
        }
        if (node1.rank >= 0) {
            node1.rank = ~index++
        }
        j = ~node1.rank

        if (clusters[j]) {
            clusters[j].push(elements[i])
        } else {
            clusters[j] = [elements[i]]
        }
    }
    return clusters
}
