import React, { Component } from 'react'
import * as quark from '../quark'

export default class Navigable<Props, State> extends Component<
    {
        line: Entity
        parent: Navigable<any, any>
        index: number
    } & Props,
    State
> {
    nthChild(n) {
        return this.refs['child-' + n]
    }
    childCount() {
        return quark.get_children(this.props.line).length
    }
    lastChild() {
        return this.nthChild(this.childCount() - 1)
    }
    parent() {
        return this.props.parent
    }
    root() {
        var cursor: this = this
        while (cursor.parent()) cursor = cursor.parent() as any
        return cursor
    }
    nextSibling() {
        return this.parent().nthChild(this.getIndex() + 1)
    }
    prevSibling() {
        return this.parent().nthChild(this.getIndex() - 1)
    }
    getIndex() {
        return this.props.index
    }
    canFocus() {
        return true
    }
    next() {
        try {
            var next
            if (!this.nthChild(0)) {
                var current: this = this
                while (!current.nextSibling()) current = current.parent() as any
                next = current.nextSibling()
            } else next = this.nthChild(0)
        } catch (err) {}

        if (next && !next.canFocus()) {
            return next.next()
        }
        return next
    }
    prev() {
        try {
            var prev
            if ((prev = this.prevSibling())) {
                while (prev.nthChild(0)) prev = prev.lastChild()
            } else prev = this.parent()
        } catch (err) {}

        if (prev && !prev.canFocus()) {
            return prev.prev()
        }
        return prev
    }
    focusNext(offset) {
        var next = this.next()
        if (next) {
            next.focus(true, offset)
            return next
        }
    }
    focusPrev(offset) {
        var prev = this.prev()
        if (prev) {
            prev.focus(false, offset)
            return prev
        }
    }
    focus() {
        console.warn('focus method not implemented')
    }
}
