import CodeMirror from 'codemirror'
import 'codemirror/addon/mode/simple'

CodeMirror.defineSimpleMode('leafmode', {
    start: [
        { regex: /\-\-.*/, token: 'comment' },
        { regex: /[^=:]*=/, token: 'prefix', next: 'text' },
        { regex: /\w+:\/\/[^\s]*[^\s,]/, token: 'url', next: 'text' },
        { regex: /([^=:]{0,24}[^\\\d=:])?:/, token: 'predicate', next: 'entities' },
        { next: 'text' },

        // { regex: /(.*?)(\[\[[^\]]+\]\])/, token: ['text', 'hashlink'], next: 'text' },
        // { regex: /(.*?)(\b\w+:\/\/[^\s]*[^\s,])/, token: ['text', 'url'] },

        // { regex: /#[\w_-]*/, token: 'hashtag', next: 'start' },
        // { regex: /[^#]*/, token: 'text' },
    ],

    entities: [
        { regex: /([^,\(]+)(\([^,]*)/, token: ['entity', 'description'] },
        { regex: /([^,\(]+)/, token: 'entity' },
    ],

    text: [
        { regex: /\s+/, token: 'text', next: 'text' },
        { regex: /\w+:\/\/[^\s]*[^\s,]/, token: 'url', next: 'text' },
        { regex: /#[\w_-]*/, token: 'hashtag', next: 'text' },
        { regex: /(\[\[[^\]]+\]\])/, token: ['hashlink'], next: 'text' },
        { regex: /(\b\w+:\/\/[^\s]*[^\s,])/, token: ['url'] },
        // {regex: /\[.+?\]/, token: 'hashtag'},
        // { regex: /[\w\s]*/, token: 'text' },
        { regex: /[\w\s]+\s/, token: 'text', next: 'text' },
        { regex: /[\w\s]+$/, token: 'text', next: 'text' },
        { regex: /./, token: 'text', next: 'text' },
    ],
    // description: [
    //     { regex: /.*?\*\//, token: 'description', next: 'start' },
    //     { regex: /.*/, token: 'description' },
    // ],
    meta: {
        lineComment: '--',
    },
})

CodeMirror.defineSimpleMode('rootmode', {
    entity: [
        { regex: /([^,\(]+)/, token: 'entity' },
        // {regex: /<ref-.*?>/, token: "entity" },
    ],
    edge: [
        { regex: /([^,\(]+)/, token: 'edge' },
        // {regex: /<ref-.*?>/, token: "entity" },
    ],
    operation: [
        { regex: /([^,\(]+)/, token: 'text' },
        // {regex: /<ref-.*?>/, token: "entity" },
    ],
    post: [
        { regex: /\-+\>/, token: 'arrow', next: 'entity' },
        { regex: /\&/, token: 'arrow', next: 'entity' },
        { regex: /\/\//, token: 'arrow', next: 'operation' },
        // {regex: /\./, token: "arrow", next: "edge"}
    ],
    start: [
        // {regex: /.*?/, token: "entity", next: "post" },
        // {regex: /(.*?)(\-+\>)/, token: ["text", "arrow"], next: "entity" },
        // {regex: /.*/, token: "entity"}

        { regex: /.*?/, token: 'entity', next: 'post' },
    ],
})

CodeMirror.defineSimpleMode('edgemode', {
    // entity: [
    //     {regex: /([^,\(]+)/, token: "entity"},
    // ],
    start: [
        // {regex: /(.*?)(\-+\>)/, token: ["text", "arrow"], next: "entity" },
        // {regex: /Recently Added|Statistics/, token: "builtin"},
        // {regex: /.*/, token: "entity"}

        { regex: /([^,\(]+)(\([^,]*)/, token: ['entity', 'description'] },
        { regex: /([^,\(]+)/, token: 'entity' },
    ],
    // description: [
    //     { regex: /.*?\*\//, token: 'description', next: 'start' },
    //     { regex: /.*/, token: 'description' },
    // ],
})
