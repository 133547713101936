import _ from 'lodash'
import { enlist, get, tok, fast_get } from './core'
import * as quark from './index'
import * as predicate from './predicates/predicate'

export function nodes() {
    return _.values(global.entities).filter((k) => k.type == 'text' && k.parent == '__root__')
}

export function edges(node): Entity[] {
    return _.flatten(
        enlist(node)
            .filter((k) => k && k.type == 'cluster')
            .map((k) => enlist(k))
    )
}
export function degree(node): number {
    return edges(node).length
}

// simple BFS implementation to find the shortest path with
// an indirection parameter which makes it find longer paths
// by excluding the fattest node (node with highest degree)
// in the found shortest path

export function find_path(source: Entity, target: Entity, indirection = 1) {
    var queue: [Entity, any[]][] = [[source, []]],
        done = {},
        ignore = []
    if (edges(target).length == 0) return
    while (queue.length > 0) {
        var [el, path] = queue.shift()
        if (!el || !el.id) continue
        var list = edges(el)
        for (var i = 0; i < list.length; i++) {
            var edge = list[i]
            if (edge.ref == target.id) {
                if (indirection == 1) {
                    if (ignore.length > 0) {
                        console.log('excluding', ignore)
                    }
                    return path.concat([edge])
                } else {
                    indirection--
                    var peak = _.maxBy(path, (k) => degree(quark.get(k.ref))).ref
                    queue = queue.filter((k) => !k[1].some((e) => e.ref == peak))
                    ignore.push(peak)
                    break
                }
            }
            if (edge.ref in done) continue
            var ent = get(edge.ref)
            if (!ent) continue
            done[ent.id] = 1
            queue.push([ent, path.concat([edge])])
        }
    }
}

export function connected_components() {
    var queue = nodes(),
        done = {}
    var components = []
    while (queue.length > 0) {
        var node = queue.pop()
        if (node.id in done) continue

        var buffer = [node],
            things = []
        while (buffer.length > 0) {
            var el = buffer.shift()
            if (!el) continue
            things.push(el)
            done[el.id] = 1
            var list = edges(el)
            for (var i = 0; i < list.length; i++) {
                var k = list[i]
                if (k.ref in done) continue
                done[k.ref] = 1
                buffer.push(get(k.ref))
            }
        }

        components.push(things)
    }
    return components
}

import LRUCache from '../lib/lru'

var cache = new LRUCache(10)

export function type_graph(seed) {
    var key = JSON.stringify(seed)
    var cached = cache.get(key)
    if (cached) {
        return cached
    } else {
        var value = internal_type_graph(seed)
        cache.put(key, value)
        return value
    }
}

export function predicates() {
    // list of predicates grouped by name
    return _.groupBy(
        _.values(global.entities).filter((k) => k.type == 'cluster' && k.predicate.trim() != ''),
        (k) => predicate.canonicalize_predicate(k.predicate)
    )
}

function internal_type_graph(seed) {
    // console.log(seed)
    // TODO: make this have some more reasonable outputs?
    var allpred = predicates()
    var total = _.sum(_.values(seed))
    var explored = _.mapValues(seed, (k) => (k / total) * 100)
    var queue = _.keys(explored)
    var score = {}
    while (queue.length > 0) {
        var pred = queue.shift()
        var weight = explored[pred]
        var next = {}
            // low friction refactoring?
        ;(allpred[pred] || [])
            .map((k) => k.parent)
            .forEach((vc) => {
                var exp = _.mapValues(
                    _.groupBy(
                        edges(get(vc))
                            .map((k) => get(k.parent))
                            .filter((k) => k.predicate),
                        (k) => predicate.canonicalize_predicate(k.predicate)
                    ),
                    (k) => k.length
                )
                if (!score[vc]) score[vc] = 0
                score[vc] += weight
                _.keys(exp)
                    .filter((n) => !(n in explored))
                    .forEach((n) => {
                        if (n in next) {
                            next[n]++
                        } else {
                            next[n] = 1
                        }
                    })
            })
        var total = _.sum(_.values(next))
        _.each(next, (nw, np) => {
            explored[np] = (nw * weight) / total / 2
            queue.push(np)
        })
    }
    return { preds: explored, ents: score }
}

export function reachable(start, max_distance = 10) {
    var explored = {}
    var queue = []

    function helper(obj, dist) {
        if (!obj) return
        if (obj.id in explored) return
        queue.push(obj)
        explored[obj.id] = dist
    }
    helper(start, 0)
    while (queue.length > 0) {
        var obj = queue.shift()
        if (!obj) continue
        var depth = explored[obj.id]
        if (depth > max_distance) break
        helper(fast_get(obj.parent), depth + 1)
        helper(fast_get(obj.ref), depth + 1)
        enlist(obj).forEach((k) => helper(k, depth + 1))
    }
    return explored
}
