import React from 'react'
import fuzzy_match from '../lib/fuzzy'

export default class Emboldinator extends React.Component<{
    query: any
    str: any
}> {
    render() {
        let { query, str } = this.props
        var start = 0
        var chunks = []
        var result = fuzzy_match(query.toLowerCase(), str.toLowerCase())
        if (result) {
            var pos = result.positions.sort((a, b) => a - b)

            for (var i = 0; i < pos.length; i++) {
                for (var j = i; j < pos.length && pos[j + 1] - pos[j] == 1; j++) {}
                chunks.push(str.slice(start, pos[i]))
                chunks.push(<u key={i}>{str.slice(pos[i], pos[j] + 1)}</u>)
                i = j
                start = pos[i] + 1
            }
        }
        chunks.push(str.slice(start))
        return <span>{chunks}</span>
    }
}
