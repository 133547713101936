import _ from 'lodash'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'

import Azimuth from './react/Azimuth'
import { LoginButton, ConfirmEmail } from './react/Login'
import Logo from './react/Logo'

import './style/polaris.less'
import './style/sunitrope.less'

import RawSpinner from './react/Spinner'
const Spinner = (props) => RawSpinner({ style: { height: '100vh' }, ...props })

import * as quark from './quark'
import isMobile from './lib/isMobile'

const Graph = React.lazy(() => import('./react/Graph'))
const MapView = React.lazy(() => import('./react/MapView'))
const Calendar = React.lazy(() => import('./react/Calendar'))
const Instructions = React.lazy(() => import('./react/Instructions'))
const InstructionsFooter = React.lazy(() =>
    import('./react/Instructions').then((mod) => ({ default: mod.InstructionFooter }))
)

global.dofocus = '__root__'

const INIT_MAX_ENTITIES = 300
window.MAX_ENTITIES = 300

const num_entities = _.throttle(
    (e) => Object.values(entities).filter((e) => Date.now() - e.created < 1000 * 60 * 60 * 24 * 30),
    500
)

if (!global.$$root$$) {
    global.$$root$$ = { id: '__root__', type: 'search', text: '' }
}

function ErrorDisplay() {
    if (quark.store.authError) {
        return (
            <div
                style={{
                    padding: '20px 35px',
                    background: '#ffe4e9',
                }}
            >
                It looks like we encountered an unexpected error while trying to log you in. Please
                email <a href="mailto:support@hypernote.io">support@hypernote.io</a> or try again
                later.
                <pre>{quark.store.authError.toString()}</pre>
            </div>
        )
    }
    return <div />
}

function join(list, separator = ', ') {
    let newList = []
    for (let i = 0; i < list.length; i++) {
        if (i > 0) newList.push(separator)
        newList.push(list[i])
    }
    return newList
}

class Aphelion extends Component {
    constructor() {
        super()
        quark.store.init()
    }

    render() {
        document.body.classList.toggle('dark-theme', !!localStorage.dark)
        document.body.classList.toggle('read-only', !quark.store.canEdit)

        let isNative = window.webkit && window.webkit.messageHandlers && !quark.in_academy()
        document.body.classList.toggle('native', !!isNative)

        // We're loading stuff from a link or signed in
        // we might not have permission - denial could be on the way

        if (quark.store.confirmEmailAddress) {
            return (
                <div style={{ flexGrow: 1 }} className="tachyon-parent">
                    <div
                        className="tachyon nag hidden"
                        ref={(e) => setTimeout(() => (e ? (e.className += ' fadein') : null), 0)}
                    >
                        <div className="colorbar" />
                        <div className="message">
                            <ConfirmEmail />
                        </div>
                    </div>
                </div>
            )
        }

        if (!quark.store.authStatusAvailable) {
            return <Spinner message="Logging in..." />
        }

        if (!quark.store.path || quark.store.path == 'home') {
            if (!quark.store.userInfo) {
                return (
                    <div style={{ flexGrow: 1 }} className="tachyon-parent">
                        <div
                            className="tachyon nag hidden"
                            ref={(e) =>
                                setTimeout(() => (e ? (e.className += ' fadein') : null), 0)
                            }
                        >
                            <div className="colorbar" />
                            <ErrorDisplay />
                            <div className="message">
                                <h1>
                                    <Logo />
                                </h1>
                                <LoginButton />
                            </div>
                        </div>
                    </div>
                )
            } else {
                if (quark.store.userProfile && quark.store.userProfile.home) {
                    location.href = '/' + quark.store.userProfile.home
                }

                return <Spinner message="Redirecting to home..." />
            }
        }

        // check if notebook exists by checking to see if anyone has access
        let notebookExists = Object.keys(quark.store.accessList || []).length > 1

        if (quark.store.dataLoadingError || (!notebookExists && quark.store.dataLoaded)) {
            // if there's a data loading error and we aren't logged in?
            // if (!quark.store.userInfo) {
            //     return (
            //         <div
            //             className="tachyon nag hidden"
            //             ref={(e) => setTimeout(() => (e ? (e.className += ' fadein') : null), 0)}
            //         >
            //             <div className="colorbar" />
            //             <div className="message">
            //                 <h1>Welcome to HyperNote 🙌</h1>
            //                 Please log in so we can save your notes!

            //             </div>
            //         </div>
            //     )
            // }

            let message
            let returnHome = quark.store.userProfile && (
                <div>
                    <br />
                    <p>
                        <a href={'/' + quark.store.userProfile.home}>
                            &larr; Return to your home notebook
                        </a>{' '}
                    </p>
                </div>
            )
            if (notebookExists) {
                message = (
                    <div>
                        <h1>Access Denied</h1>
                        {quark.store.userInfo ? (
                            <p>
                                <b>{quark.store.userInfo.email}</b> does not have access to this
                                document.
                            </p>
                        ) : (
                            <p>This document is not available for public viewing.</p>
                        )}
                        <p>
                            Consider logging in as an authorized user, or contacting one of the
                            owners{' '}
                            {join(
                                quark.store.getOwners().map((email) => (
                                    <a key={email} href={'mailto:' + email}>
                                        {email}
                                    </a>
                                ))
                            )}{' '}
                            for access.
                        </p>

                        <LoginButton />

                        {returnHome}
                    </div>
                )
            } else {
                message = (
                    <div>
                        <h1>Notebook not found</h1>
                        <p>Sorry, but it doesn't look like this notebook exists.</p>

                        {quark.store.userProfile && quark.store.userProfile.plan === 'Pro' && (
                            <div>
                                <p>
                                    You can claim this address and create a notebook here with your
                                    Hypernote Pro subscription.{' '}
                                </p>
                                <div
                                    className="google-button"
                                    onClick={(e) => {
                                        quark.store
                                            .createNotebook({ nbid: quark.store.path })
                                            .then((result) => {
                                                console.log('called create notebook', result)
                                                location.reload(true)
                                            })
                                            .catch((err) => {
                                                console.warn('unable to create notebook', err)
                                            })
                                    }}
                                >
                                    <div>
                                        <b>Create</b> Notebook
                                    </div>
                                </div>
                            </div>
                        )}

                        {returnHome}
                    </div>
                )
            }

            return (
                <div style={{ flexGrow: 1 }} className="tachyon-parent">
                    <div
                        className="tachyon nag hidden"
                        ref={(e) => setTimeout(() => (e ? (e.className += ' fadein') : null), 0)}
                    >
                        <div className="colorbar" />
                        <ErrorDisplay />
                        <div className="message">{message}</div>
                    </div>
                </div>
            )
        }

        if (!quark.store.dataLoaded) {
            return <Spinner message="Fetching data..." />
        }

        if (!quark.store.userProfile) {
            return <Spinner message="Loading account..." />
        }

        // Loaded and have permission

        if ($$root$$.type === 'graph')
            return (
                <React.Suspense fallback={<Spinner message="Loading graph view..." />}>
                    <Graph
                        exitGraphView={(e) => {
                            quark.set_root_text('')
                            globalUpdate()
                        }}
                    />
                </React.Suspense>
            )

        if ($$root$$.type === 'map') {
            return (
                <React.Suspense fallback={<Spinner message="Loading map view..." />}>
                    <MapView />
                </React.Suspense>
            )
        }

        if ($$root$$.type === 'calendar') {
            return (
                <React.Suspense fallback={<Spinner message="Loading calendar view..." />}>
                    <Calendar />
                </React.Suspense>
            )
        }

        return (
            <div style={{ flexGrow: 1 }} className="tachyon-parent">
                <div className="tachyon">
                    {/*<button
                        className="toggleGraphView grey"
                        onClick={(e) => {
                            global.graphView = true
                            globalUpdate()
                        }}
                    >
                        Enter Network View
                    </button>*/}

                    <div className="colorbar" />

                    <Azimuth path={[]} line={global.$$root$$} />
                </div>
            </div>
        )
    }
}

class NoMatch extends Component {
    render() {
        return (
            <div>
                <h1>404</h1>
                <p>
                    i can't believe this thing is bloated enough to have its own internal 404
                    handler
                </p>
            </div>
        )
    }
}

function HypernoteAcademyDegreeRequired() {
    // React.useEffect(() => {
    //     window.addEventListener('resize', globalUpdate)
    //     return () => {
    //         window.removeEventListener('resize', globalUpdate)
    //     }
    // }, [])

    return (
        <div
            className="tachyon nag hidden"
            ref={(e) => setTimeout(() => (e ? (e.className += ' fadein') : null), 0)}
        >
            <div className="colorbar" />
            <div className="message">
                <h1>
                    <Logo />
                </h1>
                <p>
                    It looks like you haven't yet completed the Hypernote Academy tutorial.
                    Hypernote supports mobile devices, but the interactive tutorial must be done
                    with a keyboard.
                </p>
                <p>
                    When you have completed Hypernote Academy, you will be able to have full access
                    to the mobile view.
                </p>
                <p>Sorry for the inconvenience.</p>
            </div>
        </div>
    )
}

export function globalUpdate() {
    global.globalUpdate()
}

class MetaAphelion extends Component {
    constructor() {
        super()

        global.globalUpdate = () => {
            this.setState({})
        }
    }
    render() {
        if (quark.in_academy()) {
            document.body.classList.add('academy')
            if (isMobile()) {
                return <HypernoteAcademyDegreeRequired />
            }

            quark.ensure_window_large()

            return (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100vh',
                    }}
                >
                    <div style={{ display: 'flex', flexShrink: 1, flexGrow: 1, minHeight: 0 }}>
                        <React.Suspense
                            fallback={
                                <div
                                    style={{
                                        width: 450,
                                        display: 'flex',
                                        background: 'white',
                                        borderRight: '1px solid #ddd',
                                    }}
                                >
                                    <Spinner message="Loading tutorial..." />
                                </div>
                            }
                        >
                            <Instructions />
                        </React.Suspense>
                        <div style={{ flex: 1, overflow: 'auto', display: 'flex' }}>
                            <Aphelion />
                        </div>
                    </div>
                    <div style={{ flexShrink: 0 }}>
                        <React.Suspense fallback={<div />}>
                            <InstructionsFooter />
                        </React.Suspense>
                    </div>
                </div>
            )
        } else {
            document.body.classList.remove('academy')

            return (
                <div style={{ height: '100%' }}>
                    <Aphelion />
                </div>
            )
        }
    }
}

ReactDOM.render(<MetaAphelion />, document.getElementById('root'))
