import _ from 'lodash'
import CodeMirror from 'codemirror'
import 'codemirror/addon/hint/show-hint'
import chrono from 'chrono-node'
import moment from 'moment'
import * as quark from '../quark'

function tachyonHint(editor, options) {
    // if(Object.keys(entities).length > 30000) return;

    var line = editor.line
    var distances = quark.reachable(line)
    var cur = editor.getCursor(),
        curLine = editor.getLine(cur.line)
    var token = editor.getTokenAt({ ch: cur.ch + 1, line: cur.line })
    var start = token.start + token.string.match(/^\s*/)[0].length,
        end = token.end
    var curWord = quark.remove_refs(curLine.slice(start, end))
    var predicateEnd = token.type == 'predicate' && cur.ch == token.end
    var seed = {}

    if (predicateEnd) {
        curWord = ''
    }

    function pickDefault(cm, data, completion) {
        quark.milestone('ACCEPT_SUGGESTION')

        cm.replaceRange(completion.text, data.from, data.to, 'complete')
    }

    function pick(cm, data, completion) {
        quark.milestone('ACCEPT_SUGGESTION')
        // var rep = quark.tok(completion.entity)
        if (completion.entity) {
            var rep = cm.react.lookup_text(completion.entity)
            cm.replaceRange(rep, data.from, data.to)
            cm.react.hyperlinkRange(cm.indexFromPos(data.from), completion.entity)
        } else {
            let id = quark.fid(completion.text)
            var rep = cm.react.lookup_text(id)
            cm.replaceRange(rep, data.from, data.to)
            cm.react.hyperlinkRange(cm.indexFromPos(data.from), id)
        }

        // cm.react.hyperlinkRange(data.from,
        //     cm.posFromIndex(
        //         cm.indexFromPos(data.from) + rep.length
        //     ), completion.entity)
    }
    // console.log(_.sortBy(_.values(entities)
    //         .filter(k => k.type == 'text' && k.parent == '__root__')
    //         .map(k => _.assign({distance: distances[k.id]}, k))
    //         .filter(k => k.distance < 10)
    //     , 'distance'))
    var line_edges = quark.enlist(line).map((k) => quark.string_reverse(k.id))

    // parents shouldnt be shown with high priority
    delete distances[line.parent]

    var list = []
    if (line.type == 'text' && token.type == 'hashtag') {
        // start++
        // list = [
        //     // {text: '#' + 'yolo', displayText: '#yolo', entity: 'yolo'}
        // ]
        let hashtag = token.string.slice(1).replace(/_/g, ' ')

        list = _.sortBy(
            _.values(global.entities).filter(
                (k) =>
                    k.type == 'text' &&
                    k.parent == '__root__' &&
                    k.text.toLowerCase().startsWith(hashtag.trim().toLowerCase()) &&
                    quark
                        .enlist(k)
                        .some(
                            (e) =>
                                !(
                                    e.type == 'cluster' &&
                                    quark.enlist(e).every((f) => f.ref == line.id)
                                )
                        )
            )
        )
            .slice(0, 20)
            .map((k) => ({
                text: '#' + k.text.replace(/ /g, '_'),
                displayText: '#' + k.text.replace(/ /g, '_'),
                entity: k.id,
                className: 'hashtag',
                hint: pickDefault,
            }))

        if (list.length > 0 && list[0].text == token.string) list = []
    } else if (line.type == 'text' && token.type == 'hashlink') {
        // start++
        // list = [
        //     // {text: '#' + 'yolo', displayText: '#yolo', entity: 'yolo'}
        // ]
        let hashtag = token.string.slice(2, -2)

        list = _.sortBy(
            _.values(global.entities).filter(
                (k) =>
                    k.type == 'text' &&
                    k.parent == '__root__' &&
                    k.text.toLowerCase().startsWith(hashtag.trim().toLowerCase()) &&
                    quark
                        .enlist(k)
                        .some(
                            (e) =>
                                !(
                                    e.type == 'cluster' &&
                                    quark.enlist(e).every((f) => f.ref == line.id)
                                )
                        )
            )
        )
            .slice(0, 20)
            .map((k) => ({
                text: '[[' + k.text + ']]',
                displayText: '[[' + k.text + ']]',
                entity: k.id,
                className: 'hashtag',
                hint: pickDefault,
            }))

        if (list.length > 0 && list[0].text == token.string) list = []
    } else if ((line.type == 'cluster' || line.type == 'edge') && !line.id.startsWith('fake-')) {
        var cluster = line.type == 'cluster' ? line : quark.get(line.parent)
        var blacklist = quark.enlist(cluster).map((k) => k.ref)

        if (cluster.predicate == '') {
            // seed this based on the other unlabeled edges
            seed = _.mapValues(
                _.groupBy(
                    _.flatten(
                        quark
                            .edges(quark.get(cluster.parent))
                            .filter((k) => quark.get(k.parent).predicate == '')
                            .map((k) => quark.edges(quark.get(k.ref)))
                    )
                        .map((k) => quark.get(k.parent))
                        .filter((k) => k.predicate),
                    (k) => quark.canonicalize_predicate(k.predicate)
                ),
                (k) => k.length
            )
        } else {
            // seed this based on the current cluster's predicate
            seed[quark.canonicalize_predicate(quark.invert_predicate(cluster.predicate))] = 1
        }
        var { ents, preds } = quark.type_graph(seed)
        var aliases = _.values(global.entities).filter((k) => k.type == 'alias')
        var cw = curWord.trim().toLowerCase()

        if (cw.length < 2) return

        list = _.sortBy(
            _.values(global.entities).filter(
                (k) =>
                    !blacklist.includes(k.id) &&
                    k.type == 'text' &&
                    k.parent == '__root__' &&
                    (k.text.toLowerCase().startsWith(cw) ||
                        aliases
                            .filter((e) => e.parent == k.id)
                            .some((e) => e.alias.toLowerCase().startsWith(cw))) &&
                    // check that this edge wasn't created by this line
                    _.difference(
                        quark.edges(k).map((e) => e.id),
                        line_edges
                    ).length > 0
                // && (distances[k.id] || 100) > 5
            ),
            (k) => -(ents[k.id] || 0) + (distances[k.id] || 100)
            // k => Math.abs((distances[k.id] || 100) - 8)
            //     - quark.degree(k) / 200
            //     + k.id.length / 100
        )
            .slice(0, 20)
            .map((k) => ({
                text: quark.tok(k.id),
                entity: k.id,
                displayText: k.text,
                className: 'entity',
                hint: pick,
            }))

        let knownDates = [
            'today',
            'tomorrow',
            'yesterday',
            'monday',
            'tuesday',
            'wednesday',
            'thursday',
            'friday',
            'saturday',
            'sunday',
            // 'next monday', 'next tuesday', 'next wednesday', 'next thursday', 'next friday', 'next saturday', 'next sunday',
            // 'last monday', 'last tuesday', 'last wednesday', 'last thursday', 'last friday', 'last saturday', 'last sunday',
            'next week',
        ]

        knownDates
            .filter((k) => k.toLowerCase().startsWith(cw))
            .forEach((date) => {
                let parsedDate = chrono.parseDate(date)
                list.push({
                    text: moment(parsedDate).format('D MMMM YYYY'),
                    displayText: moment(parsedDate).format('D MMMM YYYY') + ' (' + date + ')',
                    entity: null,
                    className: 'entity',
                    hint: pickDefault,
                })
            })

        let parsedDate = chrono.parseDate(cw)

        if (
            parsedDate &&
            moment(parsedDate).format('D MMMM YYYY') !== curWord.trim() &&
            cw.length < 18
        ) {
            list.unshift({
                text: moment(parsedDate).format('D MMMM YYYY'),
                displayText: moment(parsedDate).format('D MMMM YYYY'),
                entity: null,
                className: 'entity',
                hint: pickDefault,
            })
        }
    } else if (
        (line.type == 'text' || (token.type == 'predicate' && !predicateEnd)) &&
        curWord.trim().length > 2 &&
        start === 0
    ) {
        seed = _.mapValues(
            _.groupBy(
                quark
                    .edges(quark.get(line.parent))
                    .map((k) => quark.get(k.parent))
                    .filter((k) => k.predicate != '')
                    .map((k) => quark.canonicalize_predicate(k.predicate))
            ),
            (k) => k.length
        )
        var cw = curWord.trim().toLowerCase()
        var { ents, preds } = quark.type_graph(seed)
        var allpred = quark.graph.predicates()

        const defaultRelationships = [
            'Works at',
            'Employer',
            'Founder of',
            'Friends with',
            'Former Employer',
            'Alma mater',
            'Creator of',
            'Author of',
            'Author',
            'Creator',
        ]

        list = _.uniq(
            _.uniqBy(
                [
                    ..._.sortBy(_.keys(allpred), preds).map((k) =>
                        quark.clean_predicate(allpred[k][0])
                    ),
                    ...defaultRelationships,
                ],
                (k) => k.toLowerCase()
            ).filter((k) => k.toLowerCase().startsWith(cw))
        )
            .slice(0, 20)
            .map((k) => ({
                text: k + ': ',
                className: 'edgelabel',
                hint: pickDefault,
            }))

        const defaultAttributes = [
            'Address',
            'Title',
            'Phone',
            'Email',
            'URL',
            'Twitter',
            'Instagram',
            'Age',
        ]
        let attributes = [...defaultAttributes]

        Object.values(global.entities).forEach((k) => {
            if (k.type == 'text' && k.text.indexOf('=') != -1) {
                let parts = k.text.split('='),
                    attr = parts[0].trim()
                if (
                    parts[0].length < 25 &&
                    !attributes.some((k) => k.toLowerCase() == attr.toLowerCase())
                ) {
                    attributes.push(attr)
                }
            }
        })

        list.push(
            ...attributes
                .filter((k) => k.toLowerCase().startsWith(cw))
                .slice(0, 20)
                .map((k) => ({
                    text: quark.clean_predicate(k) + '= ',
                    className: 'edgelabel',
                    hint: pickDefault,
                }))
        )

        // let attributes = ['Address', 'Title', 'Phone', 'Email', 'URL', 'Twitter', 'Instagram', 'Age']
        // list.push(...attributes.filter(k => k.toLowerCase().startsWith(cw))
        //     .slice(0, 20)
        //     .map(k => ({
        //         text: k + '= ',
        //         className: 'edgelabel',
        //     })))
    } else if (line.type == 'path' || line.type == 'intersect') {
        list = _.sortBy(
            _.values(global.entities).filter(
                (k) =>
                    k.type == 'text' &&
                    k.parent == '__root__' &&
                    k.text.toLowerCase().startsWith(curWord.trim().toLowerCase())
            )
        )
            .slice(0, 20)
            .map((k) => ({
                text: quark.tok(k.id),
                entity: k.id,
                displayText: k.text,
                className: 'entity',
                hint: pick,
            }))
    }

    // dont popup suggestions if exact match found
    // if(list.some(k =>
    //     k.text.toLowerCase() == curWord.trim().toLowerCase()
    // )) list = [];

    if (!quark.is_empty(quark.find_name(curWord.trim()), line)) list = []

    // dont show list if we've already got a tokenized thing
    if (token.string.indexOf('<ref-') != -1) list = []

    if (line.id == '__root__' && line.type == 'search') list = []

    return {
        list: list,
        from: CodeMirror.Pos(cur.line, start + (predicateEnd ? 1 : 0)),
        to: CodeMirror.Pos(cur.line, end),
    }
}

CodeMirror.registerHelper('hint', 'rootmode', tachyonHint)
CodeMirror.registerHelper('hint', 'leafmode', tachyonHint)
CodeMirror.registerHelper('hint', 'edgemode', tachyonHint)
