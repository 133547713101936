function toUpperCase(text) {
    return text.toUpperCase()
}

function toLowerCase(text) {
    return text.toLowerCase()
}

function capitalizeFirstWord(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

function capitalizeBigWords(str) {
    // based on http://individed.com/code/to-title-case/
    var smallWords = /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|vs?\.?|via)$/i
    return str.replace(/\w\S*/g, function(txt, index) {
        if (index > 0 && smallWords.test(txt)) return txt.toLowerCase()
        return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase()
    })
}

function capitalizeAllWords(str) {
    // I Print All News That's Fit To Print
    // plagiarized from http://stackoverflow.com/a/196991/205784
    return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase()
    })
}
// global.capitalizeBigWords = capitalizeBigWords

function match_padding(template, other) {
    var pre = template.match(/^\s*/)[0],
        post = template.slice(pre.length).match(/\s*$/)[0]
    if (template.trim() == '') {
        return other.trim() + template
    } else {
        return pre + other.trim() + post
    }
}

function RegExpEscape(s) {
    return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
}

export default function match_capitalization(template, other) {
    var transformations = [
        // toUpperCase,
        toLowerCase,
        capitalizeBigWords,
        // toTitleCase,
        capitalizeFirstWord,
        capitalizeAllWords,
    ]
    for (var i = 0; i < transformations.length; i++) {
        if (transformations[i](template) == template) {
            // console.log('matching transform', i)
            return match_padding(template, transformations[i](other))
        }
    }

    // what about things like COOs or something
    // in this case we try to preserve all the words that are in the original
    // perhaps consider using levenshtein?
    var input_words = template.split(' ')
    // var wordmap = {}
    // input_words.forEach(k => wordmap[k.toLowerCase()] = k);
    var output = other
    input_words.forEach((k) => {
        output = output.replace(new RegExp(RegExpEscape(k), 'ig'), k)
    })
    return match_padding(template, output)

    // return match_padding(template, other.split(' ').map(k => {
    //     if(k.toLowerCase() in wordmap){
    //         return wordmap[k.toLowerCase()]
    //     }
    //     return k
    // }).join(' '));

    // console.warn('no pattern recognized')
    // return other;
}

// global.match_capitalization = match_capitalization
