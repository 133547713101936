export default function fuzzy_match(needle, haystack) {
    if (!haystack.length) return
    if (!needle.length) return { error: 0, positions: [] }

    var table = []
    // initialize the table to zeroes
    for (let i = 0; i < haystack.length; i++) table[i] = 0
    var last = -1
    // this is the core of the algorithm
    for (let k = 0; k < needle.length; k++) {
        var counter = Infinity
        last = haystack.indexOf(needle[k], last + 1)
        if (last < 0) return
        for (let i = last; i < haystack.length; i++) {
            if (haystack[i] == needle[k]) counter = table[i]
            table[i] = counter++
        }
    }
    // find the minimum value in the table
    var min_index = -1,
        min_value = Infinity
    for (let i = last; i < haystack.length; i++) {
        if (table[i] < min_value) {
            min_value = table[i]
            min_index = i
        }
    }
    // walk through the haystack backwards starting at min
    var end = min_index + 1
    var error = 0
    var positions = []
    for (let k = needle.length; k--; ) {
        // TODO: figure out something faster than this
        var next = haystack.slice(0, end).lastIndexOf(needle[k])
        positions.push(next)
        error += end - next - 1
        end = next
    }
    return {
        error: error,
        positions: positions,
        index: positions[positions.length - 1],
    }
}

// This is still not ideal:
// matching "user design" with
// "User Interface Design Group"
//  __      __    _______
// when it should do
//  ____          _______

// that is, it should favor big gaps over small gaps
