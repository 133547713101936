import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import CodeMirror from 'codemirror'
import 'codemirror/lib/codemirror.css'
import './mode'
import './hint'
import 'codemirror/addon/runmode/runmode'
import 'codemirror/addon/comment/comment'
import * as textutils from './textutils'
import TextEditor from './TextEditor'
import * as quark from '../quark'
import { globalUpdate } from '../Aphelion'

export default class LazyTextEditor extends TextEditor<{
    forceLazy?: boolean
    lazy?: boolean
}> {
    lastRenderedText: string

    constructor(props) {
        super(props)
    }

    blur() {
        if (this.cm) super.blur()
    }

    componentDidUpdate() {
        if (this.cm) {
            super.componentDidUpdate()
        } else if (this.lastRenderedText != this.props.text) {
            this.mountLazy()
        }
    }

    mountLazy() {
        try {
            var el = ReactDOM.findDOMNode(this) as HTMLElement
        } catch (err) {
            return
        }

        el.innerHTML = '' // reset the stage
        var target = document.createElement('div')
        target.className = 'CodeMirror-lines'
        el.appendChild(target)
        var { text } = this.props
        text = text || ''
        this.lastRenderedText = text

        var mode = this.props.line.id == '__root__' ? 'rootmode' : 'leafmode'
        if (!quark.EDGE_AUTOCOLON && this.props.line.type == 'edge') {
            mode = 'edgemode'
        }

        CodeMirror.runMode(text, mode, target)

        Array.from(target.querySelectorAll('.cm-entity')).forEach((ent) => {
            var match = /^(.*)<ref-(.*?)>(.*)$/.exec(ent.textContent)
            if (match) {
                var newspan = document.createElement('span')
                newspan.className = 'cm-entity'
                newspan.appendChild(document.createTextNode(match[1]))
                newspan.appendChild(this.createToken(match[2]))
                newspan.appendChild(document.createTextNode(match[3]))
                ent.parentNode.replaceChild(newspan, ent)
            }
        })

        this.highlightNode(target)

        var span = document.createElement('span')
        span.className = 'zoom-in'
        span.title = 'Hit Cmd-Enter/Ctrl-Enter to navigate'
        var line = this.props.line
        span.onclick = function() {
            quark.set_root_text(quark.tok(line.id))
            globalUpdate()
        }
        target.appendChild(span)

        var isRoot = line.id == '__root__'
        target.classList.toggle(
            'has-children',
            !isRoot && line.type == 'text' && quark.enlist(line).length > 0
        )
        // console.log('derp', span, target)

        this.highlightEntities()
    }

    getWrapperElement() {
        if (this.cm) {
            return super.getWrapperElement()
        } else {
            return ReactDOM.findDOMNode(this)
        }
    }

    onMouseEnter() {
        this.getCM()
    }

    componentDidMount() {
        if (this.props.lazy || this.props.forceLazy) {
            this.mountLazy()
        } else {
            this.mountCodeMirror()
        }
    }

    componentWillUnmount() {
        if (this.cm) super.componentWillUnmount()
    }

    render() {
        return (
            <div
                className="tachyon-input"
                onMouseEnter={this.props.forceLazy ? null : this.onMouseEnter.bind(this)}
            />
        )
    }
}
