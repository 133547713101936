import React, { Component } from 'react'
export default () => (
    <svg
        width={114 * 0.75}
        height={103 * 0.75}
        viewBox="0 0 114 103"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M0.881958 7.46484C0.881958 3.59884 4.01596 0.464844 7.88196 0.464844H106.191C110.057 0.464844 113.191 3.59885 113.191 7.46484V95.5351C113.191 99.4011 110.057 102.535 106.191 102.535H7.88196C4.01597 102.535 0.881958 99.4011 0.881958 95.5351V7.46484Z"
            fill="#FFECEC"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.8466 66.9104C41.2895 67.4674 40.9766 68.223 40.9766 69.0109V81.8426C40.9766 86.0535 44.3902 89.4671 48.6011 89.4671H89.0816C93.2925 89.4671 96.7061 86.0535 96.7061 81.8426V69.0109C96.7061 68.223 96.3931 67.4674 95.836 66.9104L79.1678 50.2421C78.6107 49.685 77.8552 49.3721 77.0673 49.3721H60.6154C59.8275 49.3721 59.0719 49.685 58.5148 50.2421L41.8466 66.9104ZM60.0691 67.7804C60.7337 67.7804 61.3174 67.339 61.4983 66.6996L63.4556 59.7833C63.6366 59.1438 64.2202 58.7024 64.8848 58.7024H72.5136C73.1398 58.7024 73.6988 59.0952 73.911 59.6844L76.4733 66.7984C76.6855 67.3876 77.2445 67.7804 77.8707 67.7804H81.234C82.2927 67.7804 82.8228 69.0603 82.0743 69.8089L71.9972 79.8859C70.2571 81.6261 67.4357 81.6261 65.6956 79.8859L55.6185 69.8089C54.87 69.0603 55.4001 67.7804 56.4588 67.7804H60.0691Z"
            fill="#8D0000"
            fillOpacity="0.42"
        />
        <g filter="url(#filter0_d)">
            <rect
                x="15.6772"
                y="10.6641"
                width="46.5835"
                height="46.5835"
                rx="6.23413"
                fill="white"
            />
        </g>
        <circle cx="21.547" cy="40.4015" r="1.69442" fill="#C4C4C4" />
        <circle cx="21.547" cy="49.8204" r="1.69442" fill="#C4C4C4" />
        <rect
            x="27.2626"
            y="37.5674"
            width="7.98733"
            height="5.66997"
            rx="2.00576"
            fill="#C4C4C4"
        />
        <rect x="49.528" y="46.9863" width="7.45324" height="5.66997" rx="2.00576" fill="#C4C4C4" />
        <rect
            x="37.5557"
            y="37.5674"
            width="19.4256"
            height="5.66997"
            rx="2.00576"
            fill="#C4C4C4"
        />
        <rect
            x="27.2626"
            y="46.9863"
            width="19.4256"
            height="5.66997"
            rx="2.00576"
            fill="#C4C4C4"
        />
        <rect
            x="11.2136"
            y="15.1982"
            width="55.5108"
            height="18.7682"
            rx="3.36101"
            fill="#FF5353"
        />
        <rect x="19.8525" y="21.8018" width="9.31163" height="5.66997" rx="2.00576" fill="white" />
        <rect x="31.8179" y="21.8018" width="25.1631" height="5.66997" rx="2.00576" fill="white" />
        <defs>
            <filter
                id="filter0_d"
                x="14.5931"
                y="9.79671"
                width="48.7518"
                height="48.7518"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset dy="0.216839" />
                <feGaussianBlur stdDeviation="0.542098" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
            </filter>
        </defs>
    </svg>
)
