// this is one of those files that i don't like touching
// because i continually feel dissatisfied and suffer
// horrible writer's block whenever i try to modify this

// it's particularly traumatizing because it seems so
// trivial and obvious how to make this, and somehow
// i just can't get it done

// something something postel's law, be conservative
// in what you say and liberal in what you accept
// i.e. predicate_is_equal should be
// defined very liberally based on equivalence
// classes and stuff like that, while
// invert_string_predicate should be pretty predictable
// in its general behavior

// that's the ideal anyway, or at least one that might
// possibly make sense. another one that makes sense
// is to kick ass with nlp and do something that does
// the magical cool thing when it can because yolo
// why the fuck not

import * as nlp from 'nlp_compromise'
// global.nlp = nlp

import _ from 'lodash'
import match_capitalization from './match-caps'
import createVertex from 'dynamic-forest'
import LRUCache from '../../lib/lru'

var clean_predicate_cache = new LRUCache(200)
var canonicalize_predicate_cache = new LRUCache(200)
var predicate_equivalence_cache = {}

export function clear_caches() {
    clean_predicate_cache.removeAll()
    canonicalize_predicate_cache.removeAll()
    predicate_equivalence_cache = {}
}

var predicate_patterns = {}

function pr(text) {
    if (typeof text == 'object') return text
    var id = canonicalize_predicate(text)
    if (id in predicate_patterns) {
        return predicate_patterns[id]
    } else {
        var vertex = createVertex(id)
        predicate_patterns[id] = vertex
        return vertex
    }
}

function linkpr(a, ...others) {
    others.forEach((b) => pr(a).link(pr(b)))
}

var known_inverses = {}

function add_known_inverse(a, b) {
    var id = canonicalize_predicate(a)
    if (id in known_inverses) {
        known_inverses[id].push(b)
    } else {
        known_inverses[id] = [b]
    }
}

require('./rules.txt')
    .default.split('\n')
    .forEach((line) => {
        if (line.trim() == '') {
        } else if (line.indexOf('<->') != -1) {
            var parts = line.split('<->').map((k) => k.trim())
            add_known_inverse(parts[0], parts[1])
            add_known_inverse(parts[1], parts[0])
        } else if (line.indexOf('=') != -1) {
            var parts = line.split('=').map((k) => k.trim())
            parts.slice(1).forEach((b) => pr(parts[0]).link(pr(b)))
        }
    })

function invert_string_predicate(text) {
    var pattern = canonicalize_predicate(text)

    if (!pattern) return text

    // check for explicit inverses
    if (pattern in known_inverses) {
        return known_inverses[pattern][0]
    }

    // generate the inverse procedurally
    var parse = nlp.pos(text).sentences[0]
    var tokens = parse.tokens
    var last = tokens[tokens.length - 1]
    var has_prepos = last.pos.name == 'preposition'

    if (has_prepos && last.text.trim().toLowerCase() == 'with') {
        // console.log('omg what with', last)
        return text
    }

    // check for adjacent explicit inverses
    var adj = pr(text).adjacent
    for (var i = 0; i < adj.length; i++) {
        var k = adj[i].s.value
        if (k in known_inverses) {
            return known_inverses[k][0]
        }
    }

    if (parse.nouns().length > 0) {
        if (has_prepos) {
            return tokens
                .slice(0, -1)
                .map((k) => k.text)
                .join(' ')
        } else {
            return parse.text() + ' of'
        }
    } else if (parse.verbs().length > 0) {
        if (has_prepos) {
            return tokens
                .slice(0, -1)
                .map((k) => k.text)
                .join(' ')
        } else {
            return (
                tokens
                    .map((k) => {
                        if (k.pos.parent == 'verb') {
                            var conj = k.analysis.conjugate()
                            return conj.past
                        }
                        return k.text
                    })
                    .join(' ') + ' by'
            )
        }
    } else if (has_prepos) {
        return tokens
            .slice(0, -1)
            .map((k) => k.text)
            .join(' ')
    }

    return text + ' of'
}

// global.invert_string_predicate = invert_string_predicate

function pluralize_string_predicate(text, count = 1) {
    if (text.trim() == '') return ''

    var parse = nlp.pos(text).sentences[0]
    if (!parse) {
        console.warn('failed to parse', text)
        return text
    }
    var tokens = parse.tokens
    var last = tokens[tokens.length - 1]
    var has_prepos = last.pos.name == 'preposition'
    if (has_prepos && last.text.trim().toLowerCase() == 'with') {
        return text
    }
    if (parse.nouns().length > 0) {
        return tokens
            .map((k) => {
                if (k.pos.parent == 'noun') {
                    var conj = k.analysis.conjugate()
                    return count == 1 || has_prepos ? conj.singular : conj.plural
                }
                return k.text
            })
            .join(' ')
    } else if (parse.verbs().length > 0) {
        // if(has_prepos){
        //     return tokens.map(k => {
        //         if(k.pos.parent == 'verb'){
        //             var conj = k.analysis.conjugate()
        //             return conj.past
        //         }
        //         return k.text
        //     }).join(' ')
        // }
    }

    return text
}

function clean_string_predicate(pred, count = 1) {
    count = Math.round(Math.max(1, count))

    if (pred.startsWith('~')) {
        return match_capitalization(
            pred.slice(1),
            pluralize_string_predicate(invert_string_predicate(pred.slice(1)), count)
        )
    } else if (pred.startsWith('*')) {
        return match_capitalization(pred.slice(1), pluralize_string_predicate(pred.slice(1), count))
    } else {
        // its a typical string
        if ((pred || '').trim() != '') {
            var changed = match_capitalization(pred, pluralize_string_predicate(pred, count))
            if (changed.length > pred.length) {
                return changed
            } else {
                return pred
            }
            // return pred;
        } else {
            return ''
        }
    }
}

function clean_string_predicate_memo(text, count = 1) {
    var key = count + '::' + text
    var cached = clean_predicate_cache.get(key)
    if (cached) return cached
    var value = clean_string_predicate(text, count)
    clean_predicate_cache.put(key, value)
    return value
}

export function clean_predicate(input) {
    if (typeof input == 'object') {
        if (input.type != 'cluster') return ''
        var pred = input.predicate || ''
        return clean_string_predicate_memo(pred, input.list ? input.list.length : 0)
    } else if (typeof input == 'string') {
        return clean_string_predicate_memo(input)
    } else {
        return ''
    }
}

export function invert_predicate(pred) {
    if (!pred) {
        // empty predicate has empty inverse
        return pred
    } else if (pred.startsWith('~')) {
        // the inverse of an inverse is the original
        return '*' + pred.slice(1)
    } else if (pred.startsWith('*')) {
        // the tilda means inverted
        return '~' + pred.slice(1)
    } else {
        return '~' + pred
    }
}

function predicate_is_equal_core(a, b) {
    return (
        canonicalize_predicate(a) == canonicalize_predicate(b) ||
        clean_predicate(a) == clean_predicate(b) || // or a bunch of other conditions
        pr(clean_predicate(a)).connected(pr(clean_predicate(b))) ||
        pr(invert_string_predicate(clean_predicate(a))).connected(
            pr(invert_string_predicate(clean_predicate(b)))
        )
    )
}

function predicate_is_equal_memo(a, b) {
    var key = a + '::' + b
    if (key in predicate_equivalence_cache) {
        return predicate_equivalence_cache[key]
    }
    var value = predicate_is_equal_core(a, b)
    predicate_equivalence_cache[key] = value
    return value
}

export function predicate_is_equal(a, b) {
    return (
        a == b || predicate_is_equal_memo(a, b) // trivial equality
    )
}

function canonicalize_predicate_internal(text) {
    if (!text) return text

    var parse = nlp.pos(text).sentences[0]
    var tokens = parse.tokens
    var last = tokens[tokens.length - 1]
    var has_prepos = last.pos.name == 'preposition'
    if (parse.nouns().length > 0) {
        return [
            'N',
            parse
                .adjectives()
                .map((k) => k.text.toLowerCase())
                .join('-'),
            parse
                .nouns()
                .map((k) => k.analysis.conjugate().singular)
                .join('-'),
            has_prepos ? 'P' : 'X',
        ].join(':')
    } else if (parse.verbs().length > 0) {
        return [
            'V',
            parse
                .adverbs()
                .map((k) => k.text.toLowerCase())
                .join('-'),
            parse
                .verbs()
                .map((k) => k.analysis.conjugate().past)
                .join('-'),
            has_prepos ? 'P' : 'X',
        ].join(':')
    }
    return ['F', text.toLowerCase()].join(':')
}

export function canonicalize_pair(text) {
    return canonicalize_predicate(text)
        .split(':')
        .slice(0, -1)
        .join(':')
}

export function canonicalize_predicate(text) {
    var cached = canonicalize_predicate_cache.get(text)
    if (cached) return cached
    var value = canonicalize_predicate_internal(text)
    canonicalize_predicate_cache.put(text, value)
    return value
}
