import Levenshtein from 'levenshtein-steps'

export function derange(text) {
    var re = /<ref-(.*?)>/g,
        match
    var ranges = []
    var pos = 0
    while ((match = re.exec(text))) {
        ranges.push([match.index, re.lastIndex, match[1]])
    }
    return ranges
}

export function rangesub(ranges, replaceWith) {
    var offset = 0
    ranges.sort((a, b) => a[0] - b[0])
    for (var i = 0; i < ranges.length; i++) {
        var [start, end, sub] = ranges[i]
        offset += replaceWith(start + offset, end + offset, sub) - (end - start)
    }
}

export function rangesubtext(ranges, text, replacer) {
    rangesub(ranges, (start, end, sub) => {
        var flag = replacer(sub)
        text = text.slice(0, start) + flag + text.slice(end)
        return flag.length
    })
    return text
}

// converts two strings into a sequence of substitution operations
export function lsd(s, sp) {
    var ops = new Levenshtein(s, sp).getSteps()
    ops.reverse()
    var actions = []
    ops.forEach(([op, a, b]) => {
        if (op == 'delete') {
            actions.push([b, b + 1, ''])
        } else if (op == 'substitute') {
            actions.push([b - 1, b, sp[b - 1]])
        } else if (op == 'insert') {
            actions.push([b - 1, b - 1, sp[b - 1]])
        }
    })
    return actions
}
