import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import CodeMirror from 'codemirror'
import 'codemirror/lib/codemirror.css'
import './mode'
import './hint'
import 'codemirror/addon/runmode/runmode'
import 'codemirror/addon/edit/closebrackets'
import 'codemirror/addon/display/placeholder'
import create_token from './createToken'
import * as textutils from './textutils'
import * as quark from '../quark'
import { globalUpdate } from '../Aphelion'
// global.CodeMirror = CodeMirror

export default class TextEditor<Props> extends Component<
    Props & {
        text: string
        line: Entity
        toggleCollapse: (e) => void
        onChange: (text: string) => void
        keyMap: { [key: string]: any }
        path: Entity[]
    }
> {
    cm: any

    constructor(props) {
        super(props)
    }
    focus(front = false, offset) {
        var cm = this.getCM()
        // global.cme = cm
        if (!cm.hasFocus()) {
            cm.focus()
            if (typeof offset == 'number') {
                // console.log('got offset', front, offset)
                // offset = Math.min(cm.getScrollInfo().width, offset)
                var { top } = cm.charCoords(
                    front ? { ch: 0, line: 0 } : { ch: 1e8, line: 1e8 },
                    'page'
                )
                cm.setCursor(
                    cm.coordsChar(
                        {
                            left: offset + 3,
                            top: top,
                        },
                        'page'
                    )
                )
            } else if (offset && 'ch' in offset) {
                cm.setCursor({ ch: offset.ch, line: 0 })
            } else {
                cm.setCursor({ ch: 1e8, line: 1e8 })
            }
        }
    }
    cursorOffset() {
        var cm = this.getCM()
        // pixels from the left of the start of the codemirror instance
        // of the current end of the selection
        var curPos = cm.getCursor('to'),
            nextLeft = cm.cursorCoords(cm.findPosH(curPos, 1, 'char', true), 'page').left,
            curLeft = cm.cursorCoords(curPos, 'page').left
        if (curLeft >= nextLeft) {
            return 1e8
        } else if (curPos.ch == 0) {
            return 0
        } else {
            return curLeft
        }
    }
    blur() {
        var cm = this.getCM()
        cm.setCursor(0)
        this.updateText(this.props.text)
    }
    getText() {
        var cm = this.getCM()
        var raw = cm.getValue()
        var text = raw

        var ranges = cm.getAllMarks().map((marker) => {
            var { from, to } = marker.find()
            var fi = cm.indexFromPos(from),
                ti = cm.indexFromPos(to)
            return [fi, ti, marker]
        })
        return textutils.rangesubtext(ranges, raw, (marker) => {
            // this is kind of a hack
            // the problem is that if you remove some token
            // and then undo that change, the garbage collector
            // may have deleted the original node reference
            // and so the reference might not mean anything
            // so in the mean time, if that sub doesn't exist
            // we'll instantiate a new one
            if (quark.get(marker.sub) || /^[A-Z]+\-/.test(marker.sub)) {
                return quark.tok(marker.sub)
            } else {
                console.warn('regenerating fid', marker.flag)
                return quark.tok(quark.fid(marker.flag, marker.sub))
            }
        })
    }

    lookup_text(id) {
        if (id.startsWith('TEXT-')) {
            return id.slice(5)
        } else if (id.startsWith('TRUNC-')) {
            return '+' + id.split('+').length.toLocaleString('en') + ' more'
        }
        var ent = quark.get(id)
        if (ent) {
            return ent.text || ''
        } else {
            return '<NOT FOUND>'
        }
    }
    updateText(text) {
        var isRoot = this.props.line.id == '__root__'
        var mode = isRoot ? 'rootmode' : 'leafmode'
        if (!quark.EDGE_AUTOCOLON && this.props.line.type == 'edge') {
            mode = 'edgemode'
        }

        var cm = this.getCM()

        if (cm.getOption('mode') != mode) {
            cm.setOption('mode', mode)
        }

        var ranges = textutils.derange(text)
        var unref = textutils.rangesubtext(ranges, text, this.lookup_text)
        cm.operation((k) => {
            // var cp = cm.indexFromPos(cm.getCursor());
            cm.setValue(unref)
            // cm.setCursor(cm.posFromIndex(cp))

            textutils.rangesub(ranges, (start, end, sub) => {
                return this.hyperlinkRange(start, sub)
            })
        })
        // console.assert(text == this.getText())
        // requestAnimationFrame(() => this.highlightEntities())
    }

    hyperlinkRange(start, sub) {
        var cm = this.getCM()

        var pFrom = cm.posFromIndex(start),
            flag = this.lookup_text(sub),
            pTo = cm.posFromIndex(start + flag.length)

        var wrapper = document.createElement('span')
        wrapper.appendChild(this.createToken(sub))

        var marker = cm.markText(pFrom, pTo, {
            replacedWith: wrapper,
            handleMouseEvents: true,
        })
        marker.type = 'token'
        marker.sub = sub
        marker.flag = flag
        return flag.length
    }

    changeText(raw) {
        var cm = this.getCM()
        var cur_text = cm.getValue(),
            new_text = textutils.rangesubtext(textutils.derange(raw), raw, this.lookup_text)

        if (cur_text == new_text) return

        // don't try the clever stuff if we aren't focused
        if (cm.hasFocus() && cur_text != '' && new_text != '') {
            var ops = textutils.lsd(cur_text, new_text)

            // there's a weird issue where if you delete a token
            // which causes a path invariant violation, this code then turns
            // it into text (which is still undeletable)
            // it's not technically an issue, but it is weird
            if (
                ops.every(([s, e, t]) => {
                    // console.log(s, e, t)
                    // its valid if none of the operations intersect ranges
                    return !cm.findMarksAt(cm.posFromIndex(s)).some((k) => {
                        var { from, to } = k.find()
                        var fi = cm.indexFromPos(from),
                            ti = cm.indexFromPos(to)
                        // console.log(fi, ti, s < ti, fi < e)
                        return s < ti && fi < e // ranges intersect
                    })
                    // return cm.findMarksAt(cm.posFromIndex(s + 1)).length == 0
                })
            ) {
                // console.log('injecting without replace')
                cm.operation((k) => {
                    ops.forEach(([s, e, t]) => {
                        var cp = cm.indexFromPos(cm.getCursor())
                        cm.replaceRange(t, cm.posFromIndex(s), cm.posFromIndex(e), '*update')
                        // if inserting at the cursor, retain old position
                        if (s == e && s == cp) {
                            cm.setCursor(cm.posFromIndex(cp))
                        }
                    })
                })
                return
            }
            this.updateText(raw)
        } else {
            this.updateText(raw)
        }
        // console.log('replacing contents')
    }

    componentDidUpdate() {
        var cm = this.getCM()
        cm.line = this.props.line

        function findColon(str) {
            var result = str.match(/:\s*/)
            if (!result) return -1
            return result.index + result[0].length
        }
        var cur_text = this.getText(),
            new_text = this.props.text,
            cur_colo = findColon(cur_text),
            new_colo = findColon(new_text)

        if (
            cur_text != new_text &&
            this.props.line.type == 'cluster' &&
            cm.line.type == 'cluster' &&
            cur_colo > 0 &&
            new_colo > 0 &&
            // TODO: check predicates are equivalent?
            cm.indexFromPos(cm.getCursor()) < cur_colo &&
            cur_text.slice(cur_colo) == new_text.slice(new_colo)
        ) {
            console.log('skipping predicate update')
            return
        }
        this.changeText(this.props.text)
        this.refreshTokens()
        this.highlightEntities()

        //
    }

    highlightEntities() {
        // this.highlightNode(ReactDOM.findDOMNode(this))

        var line = this.props.line
        var wrap = this.getWrapperElement()
        var isRoot = line.id == '__root__'
        wrap.classList.toggle(
            'has-children',
            !isRoot && line.type == 'text' && quark.enlist(line).length > 0
        )
    }
    getWrapperElement() {
        var cm = this.getCM()
        var wrap = cm.getWrapperElement()
        return wrap
    }
    createToken(id) {
        var token = create_token(id, this, this.props.toggleCollapse)
        this.highlightNode(token)
        return token
    }
    highlightNode(node) {
        var entities = node.querySelectorAll('.cm-entity,.cm-hashlink,.cm-hashtag')
        var { path, line } = this.props
        for (var i = 0; i < entities.length; i++) {
            var ent = entities[i]
            var text = ent.textContent
                .trim()
                .replace(/^\[\[(.*)\]\]$/, '$1')
                .replace(/^#(.*)$/, '$1')
            if (text != '') {
                var found = !quark.is_empty(quark.find_name(text), line)

                // TODO: have something which works in browsers that don't support classList
                ent.classList.toggle('found', found)

                var loop = path.some(
                    (k) => k.ref && quark.get(k.ref) && quark.get(k.ref).text == text
                )
                ent.classList.toggle('loop', loop)
            }
        }
    }

    refreshTokens() {
        var cm = this.getCM()
        // update the token markers in case their contents changed
        // which happens when things are text nodes sometimes
        cm.getAllMarks().forEach((k) => {
            var replacement = this.createToken(k.sub)
            // first check that we need to replace it so there arent flashign artifcats
            if (replacement.innerHTML != k.replacedWith.firstChild.innerHTML) {
                k.replacedWith.replaceChild(replacement, k.replacedWith.firstChild)
            }
        })
    }

    componentWillUnmount() {
        var cm = this.getCM()
        var completion = cm.state.completionActive
        if (completion) completion.close()
    }
    mountCodeMirror() {
        var el = ReactDOM.findDOMNode(this) as HTMLElement
        el.innerHTML = '' // ensure a clean slate
        var text: string = this.props.text || ''

        var isRoot = this.props.line.id == '__root__'

        var cm = CodeMirror(el, {
            // inputStyle: 'textarea',
            value: text,
            lineNumbers: false,
            mode: isRoot ? 'rootmode' : 'leafmode',
            placeholder: isRoot && (quark.store.canEdit ? 'Search or Create' : 'Search'),
            lineWrapping: true,
            viewportMargin: Infinity,
            scrollbarStyle: null,
            autoCloseBrackets: true,
            dragDrop: false,
            readOnly: !isRoot && !quark.store.canEdit,
            // cursorBlinkRate: 0
        })

        cm.on('renderLine', (cm, line, el) => {
            var span = document.createElement('span')
            span.className = 'zoom-in'
            span.title = 'Hit Cmd-Enter/Ctrl-Enter to navigate'
            span.onclick = function() {
                quark.set_root_text(quark.tok(cm.line.id))
                globalUpdate()
            }
            el.appendChild(span)

            this.highlightNode(el)
        })

        this.cm = cm
        cm.react = this
        cm.line = this.props.line

        // cm.setValue(text)
        this.updateText(text)

        cm.on('changes', (cm, changes) => {
            if (changes.some((k) => !k.origin || k.origin[0] != '*')) {
                this.props.onChange(this.getText())
            }
        })
        cm.on('blur', () => {
            if (isRoot) {
                this.updateText(this.props.text)
            } else {
                // console.log(document.activeElement, 'blurred')
            }
        })
        cm.on('keyup', (cm, evt) => {
            var pos = cm.getCursor()
            var token = cm.getTokenAt(pos)
            var completion = this.cm.state.completionActive
            // console.log(evt.keyCode)
            if (
                (evt.keyCode > 31 || [8, 16, 13].includes(evt.keyCode)) &&
                ![40, 38].includes(evt.keyCode)
            ) {
                if (
                    (token.type == 'entity' ||
                        (token.type == 'predicate' && token.end == pos.ch)) &&
                    !completion
                ) {
                    CodeMirror.showHint(cm, null, {})
                } else if (token.type == 'hashtag') {
                    CodeMirror.showHint(cm, null, {})
                } else if (token.type === 'text') {
                    // console.log(token)
                    CodeMirror.showHint(cm, null, {})
                }

                // console.log(token)
            } else {
                // console.log(evt.keyCode)
            }
        })
        cm.setOption('extraKeys', this.props.keyMap)
        cm.setOption('hintOptions', {
            // hint: CodeMirror.hint.auto,
            completeSingle: false,
            // alignWithWord: true,
            // closeCharacters: /[\s()\[\]{};:>,]/,
            closeOnUnfocus: false,
            autoCloseBrackets: '()[]{}\'\'""',
            completeOnSingleClick: true,
            // container: null,
            extraKeys: {
                Enter: this.props.keyMap.Enter,
            },
            // extraKeys: null
        })

        this.highlightEntities()
    }
    getCM() {
        if (this.cm) {
            return this.cm
        } else {
            this.mountCodeMirror()
            return this.cm
        }
    }

    componentDidMount() {
        this.mountCodeMirror()
    }
    render() {
        return <div className="tachyon-input" />
    }
}
